import React, { useEffect, useRef } from "react";
import "./Idea.scss";

import { apiCall } from "../../../api";
import { useAppContext } from "../../../context/store";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// Components

import Button from "../../common/button/Button";
import Spinner from "../../common/spinner/Spinner";
import Select from "../../common/select/Select";
import { HtmlEditor } from "../..";
import TextArea from "../../common/textArea/TextArea";
import ReactTooltip from "react-tooltip";

import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";
import { trimSpaces } from "../../../utils/helperFunctions";

const Idea = ({ ideaData, onSave }) => {
  const { isLoading, appDispatch, strings, config } = useAppContext();
  const { idea, common } = strings;
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
    control,
    register,
  } = useForm({ defaultValues: ideaData });

  const introTitle = useRef();

  const watchOverview = watch("overview");
  const watchRisksAndDisclosure = watch("risksAndDisclosure");
  const watchIntro = watch("intro")

  const onSubmit = handleSubmit(
    async (values) => {
      const { intro, overview, risksAndDisclosure } = values;

      for (const [key] of Object.entries(overview)) {
        if (overview[key] === " ") overview[key] = "";
        if (!!overview[key]){
          const strippedString = overview[key].replace(/(<([^>]+)>)/gi, "");
          if (!strippedString) overview[key] = ""
        }
      }

      for (const [key] of Object.entries(risksAndDisclosure)) {
        if (risksAndDisclosure[key] === " ") risksAndDisclosure[key] = "";
        if (!!risksAndDisclosure[key]){
          const strippedString = risksAndDisclosure[key].replace(/(<([^>]+)>)/gi, "");
          if (!strippedString) risksAndDisclosure[key] = ""
        }
      }

      const formData = { intro: trimSpaces(intro), overview: trimSpaces(overview), risksAndDisclosure: trimSpaces(risksAndDisclosure) };

      const config = {
        url: "fundraising/idea",
        method: "put",
        data: formData,
      };

      try {
        const { data } = await apiCall(config, appDispatch);
        onSave(2, data);
        toast.success(common.changedData);
      } catch (error) {

      }
    },
    (errors) => {
      // if (errors?.intro) {
      //   introTitle.current?.scrollIntoView();
      // }
    }
  );

  const mapTextAreaLabel = (value) => {
    // switch (value) {
    //   case "marketLandscape":
    //     return "market landscape";
    //   case "pitchDeck":
    //     return "Pitch deck";
    //   case "general":
    //     return "General risk and disclosures";
    //   case "companyRelated":
    //     return "Company related";
    //   default:
    //     return value;
    // }
    return strings.ideaLabels[value]
  };

  useEffect(() => {
    reset(ideaData);
  }, [ideaData]);

  if (isLoading) {
    return (
      <Spinner
        wrapperStyle={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <form onSubmit={onSubmit} className="idea__form">
      <ReactTooltip
        place="right"
        effect="solid"
        backgroundColor="var(--color-primary)"
        textColor="var(--color-white)"
        id="fundraise-idea-tooltip"
      />
      <div className="idea__intro">
        <h2 className="idea__section-title font-XL-700" ref={introTitle}>
          {idea.intro}
          <div className="fundraise-intro-icon" data-for="fundraise-idea-tooltip" data-tip={idea.introTooltip}>
            <InfoIcon />
          </div>
        </h2>
        <TextArea
          autoFocus
          maxLength={config.fundraise.idea.introMaxLength}
          currentLength={watchIntro?.length ?? 0}
          {...register("intro", {
            required: idea.introError,
            // min: { value: 0, message: general.minCapError },
          })}
          aria-invalid={!!errors.intro}
          error={errors?.intro}
          placeholder={strings.formatString(idea.introPlaceholder, {
            totalLength: config.fundraise.idea.introMaxLength
          })}
        />

        {/* <HtmlEditor
          name="intro"
          control={control}
          placeholder={idea.introPlaceholder}
          required
          style={{ width: "100%" }}
          error={errors?.intro}
        /> */}
      </div>
      <div className="idea__overview">
        <h2 className="idea__section-title font-XL-700">{idea.overview}</h2>
        {
          config.overviews.filter((overview) => !overview.required).map((overview) => overview.key).filter((key) => !watchOverview[key]).length > 0 &&
          <Select
            dynamicList
            label=""
            options={config.overviews.filter((overview) => !overview.required).map((overview) => overview.key).filter((key) => !watchOverview[key])}
            // options={Object.keys(watchOverview)?.filter(
            //   (overviewKey) => !watchOverview[overviewKey]
            // )}
            optionsLabelHandler={mapTextAreaLabel}
            placeholder={idea.addSections}
            onChange={(e) => {
              if (e !== undefined) {
                setValue(`overview.${e}`, " ");
              }
            }}
          />
        }
        <div className="idea__fields">
          {
            config.overviews.map((overview) => {
              if (!!watchOverview[overview.key] || overview.required) {
                return (
                  <HtmlEditor
                    tooltipText={idea.tooltipInfo}
                    label={mapTextAreaLabel(overview.key)}
                    key={overview.key}
                    onDelete={!overview.required ? () => {
                      setValue(`overview.${overview.key}`, "");
                    } : undefined}
                    placeholder={strings.formatString(strings.editorPlaceholder, {
                      section: overview.key,
                    })}
                    required={overview.required}
                    name={`overview.${overview.key}`}
                    error={errors?.overview?.[overview.key]}
                    control={control}
                  />
                );
              }
              return null
            })
          }
          {/* {Object.keys(watchOverview)?.map((overviewKey, index) => {
            if (!!watchOverview[overviewKey])
              return (
                <HtmlEditor
                  tooltipText={idea.tooltipInfo}
                  label={mapTextAreaLabel(overviewKey)}
                  key={overviewKey}
                  onDelete={() => {
                    setValue(`overview.${overviewKey}`, "");
                  }}
                  placeholder={strings.formatString(strings.editorPlaceholder, {
                    section: overviewKey,
                  })}
                  name={`overview.${overviewKey}`}
                  control={control}
                />
              );
            return null;
          })} */}
        </div>
      </div>

      <div className="idea__overview">
        <h2 className="idea__section-title font-XL-700">
          {idea.risksAndDisclosures}
        </h2>
        {
          Object.keys(watchRisksAndDisclosure)?.filter(
            (risksAndDisclosureKey) =>
              !watchRisksAndDisclosure[risksAndDisclosureKey] &&
              risksAndDisclosureKey !== "general"
          ).length > 0 &&
          <Select
            dynamicList
            label=""
            options={Object.keys(watchRisksAndDisclosure)?.filter(
              (risksAndDisclosureKey) =>
                !watchRisksAndDisclosure[risksAndDisclosureKey] &&
                risksAndDisclosureKey !== "general"
            )}
            placeholder={idea.addSections}
            onChange={(e) => {
              setValue(`risksAndDisclosure.${e}`, " ");
            }}
            optionsLabelHandler={mapTextAreaLabel}
          />
        }
        <div className="idea__fields">
          {Object.keys(watchRisksAndDisclosure)?.map(
            (risksAndDisclosureKey, index) => {
              if (
                !!watchRisksAndDisclosure[risksAndDisclosureKey] ||
                risksAndDisclosureKey === "general"
              )
                return (
                  <HtmlEditor
                    placeholder={strings.formatString(
                      strings.editorPlaceholder,
                      {
                        section: risksAndDisclosureKey,
                      }
                    )}
                    label={mapTextAreaLabel(risksAndDisclosureKey)}
                    tooltipText={idea.tooltipInfo}
                    key={risksAndDisclosureKey}
                    value={watchRisksAndDisclosure[risksAndDisclosureKey]}
                    onDelete={
                      risksAndDisclosureKey !== "general"
                        ? () => {
                            setValue(
                              `risksAndDisclosure.${risksAndDisclosureKey}`,
                              ""
                            );
                          }
                        : undefined
                    }
                    required={risksAndDisclosureKey === "general"}
                    name={`risksAndDisclosure.${risksAndDisclosureKey}`}
                    error={risksAndDisclosureKey === "general" ? errors?.risksAndDisclosure?.general : undefined}
                    control={control}
                  />
                );
              return null;
            }
          )}
        </div>
      </div>
      <Button type="submit">{idea.save}</Button>
    </form>
  );
};

export default Idea;
