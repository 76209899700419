import {useEffect, useState} from "react";

import LocalizedStrings from "react-localization";
import {en} from "../utils/localization";

import {
  AiOutlineFacebook as Facebook,
  AiOutlineInstagram as Instagram,
  AiOutlineLinkedin as Linkedin,
  AiOutlineTwitter as Twitter,
  AiOutlineWhatsApp as Whatsapp,
  AiOutlineYoutube as Youtube,
} from "react-icons/ai";
import {FaDiscord as Discord, FaTelegram as Telegram, FaTiktok as Tiktok,} from "react-icons/fa";
import axios from "axios";
import {isString} from "url/util";

let strings = new LocalizedStrings({
  en,
});

const axiosInstance = axios.create({});

// ----- General functions -----

export const handleundefined = (value) => value || "";

export const getFileExtension = (filename) => {
  return filename
    .substring(filename.lastIndexOf(".") + 1, filename.length)
    .toLowerCase();
};

export const compareTwoObjs = (obj1, obj2) => {
  return Object.entries(obj1).toString() === Object.entries(obj2).toString();
};

export const downloadURI = async (uri, name) => {
  let link = document.createElement("a");
  const requestOptions = {
    method: "GET",
    // mode: "no-cors",
    "Access-Control-Allow-Origin": "*",
    responseType: "blob",
  };

  await fetch(`${uri}`, requestOptions)
    .then((res) => res.blob())
    .then((blob) => {
      const href = window.URL.createObjectURL(blob);
      link.href = href;
    })
    .catch((err) => {
      link.href = uri;
      return Promise.reject({ Error: "Something Went Wrong", err });
    });

  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const urlToObject = async (url, filename) => {
  const response = await axiosInstance.get(url, {
    responseType: "blob",
  });
  const blob = new Blob([response.data], { type: "application/pdf" });
  const file = new File([blob], filename, { type: "application/pdf" });
  return file;
};

export const urlToBuffer = async (docUrl) => {
  try {
    const response = await fetch(docUrl);
    const content = await response.arrayBuffer();

    return Buffer.from(content, "utf-8");
  } catch (error) {
    throw error;
  }
};

export const handleTextLength = (text, maxLength) => {
  if (text && text.length > maxLength) return text.slice(0, maxLength) + "...";
  return text;
};

export const getIconBySocial = (social, props = {}) => {
  switch (social) {
    case "twitter":
      return <Twitter {...props} />;
    case "instagram":
      return <Instagram {...props} />;
    case "facebook":
      return <Facebook {...props} />;
    case "youtube":
      return <Youtube {...props} />;
    case "tiktok":
      return <Tiktok {...props} />;
    case "telegram":
      return <Telegram {...props} />;
    case "linkedin":
      return <Linkedin {...props} />;
    case "whatsapp":
      return <Whatsapp {...props} />;
    case "discord":
      return <Discord {...props} />;
    default:
      return null;
  }
};

export const getEtherscanAddressURL = (contract) => {
  return `https://etherscan.io/address/${contract}`;
};

// ----- Custom hooks -----

export const useResize = (element = null, breakpoint) => {
  let [
    { screenWidth, screenHeight, ratiowh, ratiohw, rect, isMinor },
    setState,
  ] = useState({
    screenWidth: 0,
    screenHeight: 0,
    ratiowh: 0,
    ratiohw: 0,
    rect: undefined,
    isMinor: undefined,
  });

  const onResize = (event) => {
    screenWidth = window.innerWidth;
    screenHeight = window.innerHeight;
    ratiowh = screenWidth / screenHeight;
    ratiohw = screenHeight / screenWidth;

    const isMinor = breakpoint ? screenWidth < breakpoint : undefined;

    if (element && element.current) {
      //rect = element.current.getBoundingClientRect();
      const clientRect = element.current.getBoundingClientRect();
      // DOM API does not allow for a shallow copy, so we have to manually set them
      rect = {
        width: clientRect.width,
        height: clientRect.height,
        left: clientRect.left,
        right: clientRect.right,
        top: clientRect.top,
        bottom: clientRect.bottom,
      };
    }

    setState({
      screenWidth,
      screenHeight,
      ratiowh,
      ratiohw,
      rect,
      isMinor,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize, false);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize, false);
    };
    // [] ==> let only resize:event handle state update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { screenWidth, screenHeight, ratiowh, ratiohw, rect, isMinor };
};

export function trimSpaces(value) {
  if (isString(value)) return value.trim()
  return value
}

export const checkTokenIntegersAndDecimals = (number, maxIntegers, maxDecimals, strings) => {
  const [integers, decimals] = number.toString().split(".")
  if ((integers && integers.length > maxIntegers) && (decimals && decimals.length > maxDecimals)) return strings.formatString(strings.maxIntegersAndDecimalsAllowed, { integers: maxIntegers, decimals: maxDecimals })
  if (integers && integers.length > maxIntegers) return strings.formatString(strings.maxIntegersAllowed, { integers: maxIntegers })
  if (decimals && decimals.length > maxDecimals) return strings.formatString(strings.maxDecimalsAllowed, { decimals: maxDecimals })
  return true
}

export const autoIncludeHttp = (link) => {
  if (link?.toLowerCase()?.startsWith("www") || link?.toLowerCase()?.startsWith("http")) return link
  return `http://${link}`
}
