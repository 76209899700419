import React from "react";
import LoginModal from "../../components/login/loginModal/LoginModal";
import "./LoginPage.scss";

const LoginPage = () => {
  return (
    <LoginModal></LoginModal>
  );
};

export default LoginPage;
