export const GET_CONFIG = "GET_CONFIG";
export const SET_LOADING = "SET_LOADING";
export const CANCEL_LOADING = "CANCEL_LOADING";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SHOW_ALERT = "SHOW_ALERT";
export const CANCEL_ALERT = "CANCEL_ALERT";
export const DEPLOY_CAMPAIGN = "DEPLOY_CAMPAIGN";
export const SET_STATUS = "SET_STATUS";
export const BLOCK_CAMPAIGN = "BLOCK_CAMPAIGN";
export const UNBLOCK_CAMPAIGN = "UNBLOCK_CAMPAIGN";

export const reducer = (prevState, action) => {
  switch (action.type) {
    case GET_CONFIG:
      return {
        ...prevState,
        config: action.payload,
      };
    case SET_LOADING:
      return {
        ...prevState,
        loadingCount: prevState.loadingCount + 1,
        isLoading: !!(prevState.loadingCount + 1),
      };
    case CANCEL_LOADING:
      return {
        ...prevState,
        loadingCount: prevState.loadingCount - 1,
        isLoading: !!(prevState.loadingCount - 1),
      };
    case SHOW_ALERT:
      return { ...prevState, alert: action.payload, isLoading: false };
    case CANCEL_ALERT:
      return { ...prevState, alert: null };

    case LOGIN:
      const {
        token: authToken,
        username,
        authorities,
        applicationData: { requirePasswordChange },
        roles,
      } = action.payload;

      return {
        ...prevState,
        isLogged: true,
        authToken,
        username,
        authorities,
        requirePasswordChange: requirePasswordChange === "true",
        roles,
      };
    case LOGOUT:
      return {
        ...prevState,
        isLogged: false,
        authToken: null,
        username: null,
        authorities: [],
        roles: [],
        isCampaignOutOfDate: false,
        alert: null,
        requirePasswordChange: false,
        isDeployed: false,
        status: null
      };
    case DEPLOY_CAMPAIGN: {
      return {
        ...prevState,
        isDeployed: true,
      };
    }
    case BLOCK_CAMPAIGN: {
      return {
        ...prevState,
        isCampaignOutOfDate: true,
      };
    }
    case UNBLOCK_CAMPAIGN: {
      return {
        ...prevState,
        isCampaignOutOfDate: false,
      };
    }
    case SET_STATUS: {
      return {
        ...prevState,
        status: action.payload,
        isDeployed: action.payload.status === "DEPLOYED" || false,
      };
    }

    default:
      return prevState;
  }
};
