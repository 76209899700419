import { apiCall } from "../api";
import { resetStorage, setTokensStorage, setUserLoginData } from "../storage";

import { LOGIN } from "../context/reducer";
import { toast } from "react-toastify";

export const setAuthTokenByLoginRequest = async (
  username,
  password,
  appDispatch,
  history
) => {
  const config = {
    method: "post",
    url: "/login",
    data: {
      username,
      password,
    },
  };

  try {
    const { data } = await apiCall(config, appDispatch);

    // Set user data in the localStorage
    setUserLoginData(data);

    // Set auth and access token in the localStorage
    setTokensStorage(data?.token, data?.refreshToken);

    appDispatch({ type: LOGIN, payload: data });
    
    history.push("/")
  } catch (error) {
    resetStorage();
  }
};
