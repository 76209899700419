import React from "react";
import "./WizardNav.scss";

// Components
import Select from "../common/select/Select";

// Helpers
import { useResize } from "../../utils/helperFunctions";

const WizardNav = ({ className, stepWizard, setStepWizard, stepNames = [] }) => {
  const { isMinor } = useResize(null, 1080);

  if (isMinor) {
    return (
      <Select
        options={stepNames.map((step) => step.step)}
        value={stepWizard}
        optionsLabelHandler={(option) => {
          return stepNames.find((step) => step.step === option).name;
        }}
        onChange={(e) => setStepWizard(e)}
        className={`wizard__mobile${className ? ` ${className}` : ""}`}
      />
    );
  }

  return (
    <div className={`wizard${className ? ` ${className}` : ""}`}>
      {stepNames.map((step, index) => {
        return (
          <button
            onClick={(e) => {
              setStepWizard(step.step);
            }}
            id={step}
            key={index}
            className={`wizard__button ${
              index === 0 ? "wizard__button--left" : ""
            } ${
              index === stepNames.length - 1 ? "wizard__button--right" : ""
            } ${
              stepWizard === step.step ? "wizard__button--current" : ""
            } font-S-600`}
          >
            {stepNames.find((stepArr) => stepArr.step === step.step).name}
          </button>
        );
      })}
    </div>
  );
};

export default WizardNav;
