import React, {useEffect, useMemo, useState} from 'react';
import './DashboardCampaignEnded.scss';
import {useAppContext} from '../../../context/store';
import Button from '../../common/button/Button';
import Input from '../../common/Input/Input';

// methods
import {getCashbackContract, getFspContract} from '../../../web3Wrapper/web3Helpers';
import {fsp_data, fsp_withdrawPaymentTokens} from '../../../web3Wrapper/fsp_methods';
import {cashback_returnSeedsToLeadInvestor,} from '../../../web3Wrapper/cashbackMethods';
import {useWeb3Context} from '../../../context/web3Store';
import {setCampaignDeployed} from '../../../services/dashboardServices';
import {DateTime} from "luxon"
import {getDecimalsByToken} from '../../../web3Wrapper/helpers';

const DashboardCampaignEnded = ({
  campaignSuccess,
  fspContractAddress,
  fspNumber,
  blockchain,
  details,
  getStatus,
  seedAmount
}) => {

  const { strings, config, status, appDispatch } = useAppContext();
  const { deployedSeedCashbackEnabled, applications, walletAddress } = useWeb3Context();
  const { dashboard } = strings;

  const [walletAddressToUse, setWalletAddressToUse] = useState(walletAddress);
  const [contractData, setContractData] = useState();
  const [claimLoading, setClaimLoading] = useState(false);

  const sent = useMemo(() => !!details, [details])

  const handleClaimReward = async () => {
    setClaimLoading(true)
    if (campaignSuccess) {
      const fspContract = await getFspContract(fspContractAddress);
      await fsp_withdrawPaymentTokens(fspContract, walletAddressToUse, contractData?.amountRaised, campaignSuccess, applications?.paymentToken, setCampaignDeployed, status?.deploy?.id, appDispatch, dashboard.campaignEnded.fundsClaimedSuccessfully, setClaimLoading);
    } else if (deployedSeedCashbackEnabled) {
      const cashbackContract = await getCashbackContract(blockchain, config)
      await cashback_returnSeedsToLeadInvestor(cashbackContract, walletAddressToUse, fspNumber, seedAmount, campaignSuccess, "SEED", setCampaignDeployed, status?.deploy?.id, appDispatch, dashboard.campaignEnded.seedsReturnedSuccessfully, setClaimLoading);
    }
    getStatus()
  };

  useEffect(() => {
    setWalletAddressToUse(walletAddress ?? "")
  }, [walletAddress])

  useEffect(() => {
    async function getFspData() {
      // Get token
      const token = applications?.paymentToken;
      const decimals = await getDecimalsByToken(token, config);
      // Get contract
      const fspContract = await getFspContract(fspContractAddress);
      const data = await fsp_data(fspContract, decimals)
      setContractData(data)
    }

    getFspData().catch(console.error)
  }, [fspContractAddress])

  return (
    <div className="campaignEndedWrapper">
      {
        campaignSuccess &&
        <>
          <div className="campaignEndedWrapper__title">
            {sent ? dashboard.campaignEnded.fundsClaimed : dashboard.campaignEnded.titleSuccess}
            {
              !sent &&
              <div className="campaignEndedWrapper__amount-preview">
                {dashboard.campaignEnded.totalRaised}:
                &nbsp;{contractData?.amountRaised}/{contractData?.maxCap}
                &nbsp;{applications?.paymentToken}
              </div>
            }
          </div>
          <>
            {
              !sent &&
              <div className="campaignEndedWrapper__input">
                <Input
                  type="text"
                  value={walletAddressToUse}
                  disabled={claimLoading}
                  placeholder={dashboard.campaignEnded.inputPlaceholder}
                  onChange={(e) => setWalletAddressToUse(e.target.value)}
                />
                <Button
                  onClick={handleClaimReward}
                  loading={claimLoading}
                  disabled={claimLoading || !walletAddressToUse || walletAddressToUse === ''}
                >
                  {dashboard.campaignEnded.buttonSuccess}
                </Button>
              </div>
            }
            {
              sent &&
              <div className="campaignEndedWrapper__data">
                <div className="campaignEndedWrapper__row">
                  <div className="campaignEndedWrapper__info half">
                    <div className="campaignEndedWrapper__label">{dashboard.campaignEnded.claimDate}:</div>
                    <div className="campaignEndedWrapper__value">{details?.claimTimestamp ? DateTime.fromISO(details?.claimTimestamp).toFormat("dd/MM/yyyy hh:mm") : ""}</div>
                  </div>
                  <div className="campaignEndedWrapper__info half">
                    <div className="campaignEndedWrapper__label">{dashboard.campaignEnded.claimAmount}:</div>
                    <div className="campaignEndedWrapper__value">{details?.amount} {details?.symbol ?? "???"}</div>
                  </div>
                </div>
                <div className="campaignEndedWrapper__row">
                  <div className="campaignEndedWrapper__info full">
                    <div className="campaignEndedWrapper__label">{dashboard.campaignEnded.claimWallet}:</div>
                    <div className="campaignEndedWrapper__value">
                      <a
                        href={config.blockchains.find((blockchain) => blockchain.name === applications?.blockchain)?.scanUrl + details?.walletAddress}
                        target="_blank"
                        rel="noreferrer">{details?.walletAddress}</a>
                    </div>
                  </div>
                </div>
                <div className="campaignEndedWrapper__row">
                  <div className="campaignEndedWrapper__info full">
                    <div className="campaignEndedWrapper__label">{dashboard.campaignEnded.claimTxHash}:</div>
                    <div className="campaignEndedWrapper__value">
                      <a
                        href={config.blockchains.find((blockchain) => blockchain.name === applications?.blockchain)?.scanUrlTransaction + details?.transactionHash}
                        target="_blank"
                        rel="noreferrer">{details?.transactionHash}</a>
                    </div>
                  </div>
                </div>
              </div>
            }
          </>
        </>
      }
      {
        !campaignSuccess &&
        <>
          <div className="campaignEndedWrapper__title">
            {deployedSeedCashbackEnabled ? (sent ? dashboard.campaignEnded.seedsRefunded : dashboard.campaignEnded.titleFail) : dashboard.campaignEnded.campaignFailed}
          </div>
          <>
            {
              deployedSeedCashbackEnabled && !sent &&
              <div className="campaignEndedWrapper__input">
                <Input
                  type="text"
                  value={walletAddressToUse}
                  disabled={claimLoading}
                  placeholder={dashboard.campaignEnded.inputPlaceholder}
                  onChange={(e) => setWalletAddressToUse(e.target.value)}
                />
                <Button
                  onClick={handleClaimReward}
                  loading={claimLoading}
                  disabled={claimLoading || !walletAddressToUse || walletAddressToUse === ''}
                >
                  {dashboard.campaignEnded.buttonFailed}
                </Button>
              </div>
            }
            {
              deployedSeedCashbackEnabled && sent &&
              <div className="campaignEndedWrapper__data">
                <div className="campaignEndedWrapper__row">
                  <div className="campaignEndedWrapper__info half">
                    <div className="campaignEndedWrapper__label">{dashboard.campaignEnded.refundDate}:</div>
                    <div className="campaignEndedWrapper__value">{details?.claimTimestamp ? DateTime.fromISO(details.claimTimestamp).toFormat("dd/MM/yyyy hh:mm") : ""}</div>
                  </div>
                  <div className="campaignEndedWrapper__info half">
                    <div className="campaignEndedWrapper__label">{dashboard.campaignEnded.refundAmount}:</div>
                    <div className="campaignEndedWrapper__value">{details?.amount} {details?.symbol ?? "???"}</div>
                  </div>
                </div>
                <div className="campaignEndedWrapper__row">
                  <div className="campaignEndedWrapper__info full">
                    <div className="campaignEndedWrapper__label">{dashboard.campaignEnded.refundWallet}:</div>
                    <div className="campaignEndedWrapper__value">
                      <a
                        href={config.blockchains.find((blockchain) => blockchain.name === applications?.blockchain)?.scanUrl + details?.walletAddress}
                        target="_blank"
                        rel="noreferrer">{details?.walletAddress}</a>
                    </div>
                  </div>
                </div>
                <div className="campaignEndedWrapper__row">
                  <div className="campaignEndedWrapper__info full">
                    <div className="campaignEndedWrapper__label">{dashboard.campaignEnded.refundTxHash}:</div>
                    <div className="campaignEndedWrapper__value">
                      <a
                        href={config.blockchains.find((blockchain) => blockchain.name === applications?.blockchain)?.scanUrlTransaction + details?.transactionHash}
                        target="_blank"
                        rel="noreferrer">{details?.transactionHash}</a>
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              !deployedSeedCashbackEnabled &&
              <div>
                {strings.formatString(dashboard.campaignEnded.campaignFailedDescription, {
                  target: <b>{contractData?.target ?? "???"} {details?.symbol ?? applications?.paymentToken}</b>,
                  investedAmount: <b>{contractData?.amountRaised ?? "???"} {details?.symbol ?? applications?.paymentToken}</b>
                })}
              </div>
            }
          </>
        </>
      }
    </div>
  );
};

export default DashboardCampaignEnded;
