import React, { useState } from 'react'
import { useAppContext } from '../../../context/store';
import "./Documentation.scss"

// Components
import Spinner from "../../common/spinner/Spinner";
import DropArea from '../../common/dropArea/DropArea';

const Documentation = ({ data }) => {
    const { isLoading, strings, config } = useAppContext();

    const { detail: { documentation } } = strings

    const {
        fileUploadConstants: { PDF }
    } = config;

    const [informativeAgree] = useState(data.documents?.find((doc) => doc?.type === "informativeAgreement"));
    const [otherDocsBusiness] = useState(data.documents?.filter((doc) => doc?.type === "businessDocumentGeneric"));
    const [investorDeck] = useState(data.documents?.find((doc) => doc?.type === "investorDeck"));
    const [otherDocsLegal] = useState(data.documents?.filter((doc) => doc?.type === "legalDocumentGeneric"));

    if (isLoading) {
        return (
            <Spinner
                wrapperStyle={{
                    height: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            />
        );
    }

    return (
        <div className="detail-documentation">
            <div className="detail-documentation__block">
                <div className="detail-documentation__title">{documentation?.title}</div>
                <div className="detail-documentation__description">{documentation?.description}</div>
            </div>
            <div className="detail-documentation__content">
                <div className="detail-documentation__column">
                    <h2 className="detail-documentation__subtitle">{documentation?.businessDocuments}</h2>
                    {
                        investorDeck &&
                        <div className="detail-documentation__main-docs">
                            <DropArea
                                wrapperClassName="detail-file"
                                maxSize={PDF?.sizeInByte}
                                accept={PDF?.allowedExtensions}
                                fileName="Investor-Deck.pdf"
                                lastFile={investorDeck}
                                deletable={false}
                                hasGap={true}
                                viewOnly
                            />
                        </div>
                    }
                    {/*{*/}
                    {/*    elevatorPitch &&*/}
                    {/*    <DropArea*/}
                    {/*        wrapperClassName="detail-file"*/}
                    {/*        maxSize={PDF?.sizeInByte}*/}
                    {/*        accept={PDF?.allowedExtensions}*/}
                    {/*        fileName="Elevator-Pitch.pdf"*/}
                    {/*        lastFile={{ id: undefined, name: "Elevator-Pitch.pdf", type: "elevatorPitch", link: elevatorPitch }}*/}
                    {/*        deletable={false}*/}
                    {/*        viewOnly*/}
                    {/*    />*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    businessPlan &&*/}
                    {/*    <DropArea*/}
                    {/*        wrapperClassName="detail-file"*/}
                    {/*        maxSize={PDF?.sizeInByte}*/}
                    {/*        accept={PDF?.allowedExtensions}*/}
                    {/*        fileName="Business-Plan.pdf"*/}
                    {/*        lastFile={{ id: undefined, name: "Business-Plan.pdf", type: "businessPlan", link: businessPlan }}*/}
                    {/*        deletable={false}*/}
                    {/*        viewOnly*/}
                    {/*    />*/}
                    {/*}*/}
                    {
                        otherDocsBusiness.length > 0 &&
                        <div className="detail-documentation__other-docs">
                            {otherDocsBusiness.map((doc) => (
                                <DropArea
                                    wrapperClassName="detail-file"
                                    key={doc?.id}
                                    accept={PDF?.allowedExtensions}
                                    lastFile={doc}
                                    withRecycleBinView
                                    deletable={false}
                                    hasGap={true}
                                    viewOnly
                                />
                            ))}
                        </div>
                    }
                </div>
                <div className="detail-documentation__column">
                    <h2 className="detail-documentation__subtitle" style={{ marginTop: "24px" }}>{documentation?.legalDocuments}</h2>
                    {
                        informativeAgree &&
                        <div className="detail-documentation__main-docs">
                            <DropArea
                                wrapperClassName="detail-file"
                                maxSize={PDF?.sizeInByte}
                                accept={PDF?.allowedExtensions}
                                fileName="Investment-Agreement.pdf"
                                lastFile={informativeAgree}
                                deletable={false}
                                hasGap={true}
                                viewOnly
                            />
                        </div>
                    }
                    {
                        otherDocsLegal.length > 0 &&
                        <div className="detail-documentation__other-docs">
                            {otherDocsLegal.map((doc) => (
                                <DropArea
                                    wrapperClassName="detail-file"
                                    key={doc?.id}
                                    accept={PDF?.allowedExtensions}
                                    lastFile={doc}
                                    withRecycleBinView
                                    deletable={false}
                                    hasGap={true}
                                    viewOnly
                                />
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Documentation
