import React,{ useEffect,useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarouselDetail = ({ images }) => {
    const [configBool,setConfigBool] = useState(false)
    useEffect(() => {
        document.getElementsByClassName(
            "thumbs-wrapper axis-vertical"
        );
        setConfigBool(true)    
    }, []);
    return (
        <Carousel
            className="container"
            // style={{ overflowX: "hidden" }}
            autoPlay={configBool}
            infiniteLoop={configBool}
            interval="5000"
            showArrows={true}
            showStatus={!configBool}
            showIndicators={!configBool}
            centerSlidePercentage={configBool}
            swipeable={true}
            // thumbWidth={"100px"}
        >
            {images.map((image, index) => (
                <div key={index}>
                    <img src={image.link} alt="img" />
                </div>
            ))}
        </Carousel>
    );
}

export default CarouselDetail;
