import React, {useEffect, useMemo, useState} from "react";
import "./General.scss";

import {toast} from "react-toastify";
import {apiCall} from "../../../api";

import {useForm} from "react-hook-form";
import {useAppContext} from "../../../context/store";

// Components
import Input from "../../common/Input/Input";
import DropArea from "../../common/dropArea/DropArea";
import Button from "../../common/button/Button";
// import Select from "../../common/select/Select";
import Spinner from "../../common/spinner/Spinner";
// Services
import {deleteGeneralLogoImage} from "../../../services/fundraiseServices";
import {checkTokenIntegersAndDecimals, trimSpaces} from "../../../utils/helperFunctions";

import {useWeb3Context} from "../../../context/web3Store";
import {SET_GENERAL} from "../../../context/web3Reducer";
import {useBlocksDuration} from '../../../v2/DurationSpan';

export const valueToLabel = (value, config, netType) => {
  return config.blockchains.find((configBc) => {
    return configBc.name === value && (netType ? configBc.netType === netType : true);
  })?.placeholder;
};

export const labelToValue = (label, config, netType) => {
  return config.blockchains.find((configBc) => {
    return configBc.placeholder === label && (netType ? configBc.netType === netType : true);
  })?.name;
};

const General = ({ applicationsData, generalData, onSave }) => {
  const {
    appDispatch,
    isLoading,
    config,
    strings
  } = useAppContext();
  const { web3Dispatch } = useWeb3Context();

  const { general, dropArea, common } = strings

  const [logo, setLogo] = useState(generalData?.logoImage);
  // const [blockchain, setBlockchain] = useState(generalData?.blockchain);

  const [isFullIncentive, setIsFullIncentive] = useState(generalData?.seedCashbackEnabled ?? false); // check if not null
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { ...generalData, seedCashbackEnabled: true, seedCashbackDuration: generalData?.seedCashbackDuration ?? 0 }, reValidateMode: "onChange" });

  const watchGeneral = watch();

  const currentToken = useMemo(() => config.tokens.find((token) => token.symbol === applicationsData?.paymentToken || token.name === applicationsData?.paymentToken), [config, applicationsData?.paymentToken])

  // Send new data
  const onSubmit = handleSubmit(async (values, e) => {
    delete values.paymentToken
    //localStorage.setItem("minimumInvestorSeedBalance", values.minimumInvestorSeedBalance)

    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, trimSpaces(values[key]))
    })

    formData.set("seedCashbackEnabled", isFullIncentive);

    // Check if logo doesn't exist, or if it is a file
    if (logo && logo instanceof File) {
      formData.append("logoImage", logo);
    } else {
      formData.delete("logoImage");
    }

    const generalConfig = {
      url: "fundraising/general",
      method: "put",
      data: formData,
    };

    try {
      if (generalData?.logoImage && !logo) {
        await deleteGeneralLogoImage(appDispatch);
      }

      const { data } = await apiCall(generalConfig, appDispatch);
      web3Dispatch({ type: SET_GENERAL, payload: data });

      onSave(1, data);

      toast.success(common.changedData);
    } catch (error) {

    }
  });

  // Reset default values when update form data
  useEffect(() => {
    reset({ ...generalData, seedCashbackDuration: generalData?.seedCashbackDuration ?? 0 });
  }, [generalData]);

  useEffect(() => {
    setLogo(generalData?.logoImage ?? null)
  }, [generalData?.logoImage])

  const watchGeneralDurationBlockText = useBlocksDuration(watchGeneral?.durationBlock)

  if (isLoading)
    return (
      <Spinner
        wrapperStyle={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );

  return (
    <form className="general__form" onSubmit={onSubmit}>
      <Input
        type="number"
        {...register("maxCap", {
          required: general.required,
          min: { value: applicationsData?.target ?? 0, message: general.minCapError },
          validate: (value) => checkTokenIntegersAndDecimals(value ?? "0", currentToken?.integerMax ?? 10, currentToken?.decimalMax ?? 6, strings)
        })}
        label={general.maxCapLabel}
        aria-invalid={!!errors.maxCap}
        error={errors?.maxCap}
        tooltipText={general.maxCapTooltip}
        placeholder={general.maxCapPlaceholder}
        autoComplete="off"
        min={0}
        tokenProps={{
          options: config?.blockchains.find(
            (bc) => bc.name === applicationsData?.blockchain
          )?.availablesToken ?? ["USDT"],
          readOnly: true,
          name: "paymentToken",
          control,
          value: applicationsData?.paymentToken
        }}
      />

      <Input
        type="number"
        label={
          // TODO - INPUT LABEL SHOULD BE OBTAINED BY USING A FORMATTED MESSAGE THAT REQUIRES A PARAMETER
          general.durationBlock[applicationsData?.blockchain ?? "ethereum"]?.label ?? ""
        }
        {...register("durationBlock", {
          required: general.required,
          min: {
            value: 1,
            message: general.minDurationBlockError,
          },
        })}
        aria-invalid={!!errors.durationBlock}
        error={errors?.durationBlock}
        customText={watchGeneralDurationBlockText}
        min={0}
        tooltipText={
          // TODO - INPUT LABEL SHOULD BE OBTAINED BY USING A FORMATTED MESSAGE THAT REQUIRES A PARAMETER
          general.durationBlock[applicationsData.blockchain ?? "ethereum"]?.tooltip ?? ""
        }
        placeholder={
          // TODO - INPUT LABEL SHOULD BE OBTAINED BY USING A FORMATTED MESSAGE THAT REQUIRES A PARAMETER
          general.durationBlock[applicationsData.blockchain ?? "ethereum"]?.placeholder ?? ""
        }
        autoComplete="off"
      />

      {/*<Toggle*/}
      {/*  label={general.fullIncentive}*/}
      {/*  icons={false}*/}
      {/*  tooltipText={general.fullIncentiveTooltip}*/}
      {/*  // checked={isFullIncentive}*/}
      {/*  defaultChecked={isFullIncentive}*/}
      {/*  onChange={(e) => {*/}
      {/*    if (e.target.checked) {*/}
      {/*      setIsFullIncentive(true);*/}
      {/*    } else {*/}
      {/*      setIsFullIncentive(false);*/}
      {/*    }*/}

      {/*    setValue("seedCashbackDuration", 0);*/}
      {/*  }}*/}
      {/*/>*/}
      {/*{ isFullIncentive ? ( */}
      {/*<Input*/}
      {/*  type="number"*/}
      {/*  label={general.seedCashbackDurationLabel}*/}
      {/*  readOnly={!isFullIncentive}*/}
      {/*  {...register("seedCashbackDuration", {*/}
      {/*    required: general.required,*/}
      {/*    min:{ value: 0, message: general.minSeedCashbackDurationError },*/}
      {/*  })}*/}
      {/*  aria-invalid={!!errors.seedCashbackDuration}*/}
      {/*  error={errors?.seedCashbackDuration}*/}
      {/*  min={0}*/}
      {/*  customText={*/}
      {/*    isFullIncentive &&*/}
      {/*    getBlocksPerDayStr(*/}
      {/*      watchGeneral?.seedCashbackDuration,*/}
      {/*      getSecondsPerBlock(applicationsData?.blockchain, config)*/}
      {/*    )*/}
      {/*  }*/}
      {/*  tooltipText={general.seedCashbackDurationTooltip}*/}
      {/*  placeholder={general.seedCashbackDurationPlaceholder}*/}
      {/*  autoComplete="off"*/}
      {/*  */}
      {/*/>*/}
      {/*) : (<div className = 'input-container placeholderBlock' ></div>)}*/}

      <div className ='general__drop-area' >
      <Input
        type="number"
        label={general.minimumInvestorSeedBalanceLabel}
        {...register("minimumInvestorSeedBalance", {
          required: general.required,
  // TODO: when backend setted  remove line below
  // value: localStorage.getItem('minimumInvestorSeedBalance'),
          min:  {value:1, message: general.minimumInvestorSeedBalanceError },
        })}
        min={0}
        aria-invalid={!!errors.minimumInvestorSeedBalance}
        error={errors?.minimumInvestorSeedBalance}
        tooltipText={general.minimumInvestorSeedBalanceTooltip}
        placeholder={general.minimumInvestorSeedBalancePlaceholder}
        autoComplete="off"

      />
      </div>


      <Input
        label={general.sponsorNameLabel}
        {...register("sponsorFullName", {
          required: general.required,
        })}
        aria-invalid={!!errors.sponsorFullName}
        error={errors.sponsorFullName}
        tooltipText={general.sponsorNameTooltip}
        placeholder={general.sponsorNamePlaceholder}
        autoComplete="off"
      />
      <Input
        placeholder={general.sponsorUrlPlaceholder}
        label={general.sponsorUrlLabel}
        {...register("sponsorUrl", {
           required: general.required,
          pattern: {
            value: new RegExp(config.regex.validURL),
            message: common.invalidURL,
          },
        })}
        aria-invalid={!!errors.sponsorUrl}
        error={errors?.sponsorUrl}
        tooltipText={general.sponsorUrlTooltip}
        // autoComplete="off"
      />

      <Input
        placeholder={general.fundTokenSymbPlaceholder}
        label={general.fundTokenSymbLabel}
        {...register("tokenSymbol", {
          required: general.required,
          pattern: {
            value:
              new RegExp(config.regex
                .onlyUppercaseEnglishAlphabetAndNumberMax12LengthNoSpace),
            message: general.tokenSymbolFormatError,
          },
        })}
        maxLength={12}
        aria-invalid={!!errors.tokenSymbol}
        error={errors?.tokenSymbol}
        onChange={(e) => {
          e.target.value = e.target.value.toUpperCase();
        }}
        tooltipText={general.fundTokenSymbTooltip}
        autoComplete="off"
      />
      <Input
        label={general.fundTokenNameLabel}
        {...register("tokenName", {
          required: general.required,
          pattern: {
            value: new RegExp(config.regex.onlyEnglishNumberSpacesAllowedMax20Chars),
            message: general.tokenNameError,
          },
        })}
        aria-invalid={!!errors.tokenName}
        error={errors?.tokenName}
        tooltipText={general.fundTokenNameTooltip}
        placeholder={general.fundTokenNamePlaceholder}
        autoComplete="off"
      />
      <Input
        placeholder={general.exchangeRatePlaceholder}
        label={general.exchangeRateLabel}
        {...register("exchangeRate", {
          required: general.required,
          min: {
            value: 1,
            message: general.minExchangeRateError,
          },
        })}
        min={1}
        type="number"
        aria-invalid={!!errors.exchangeRate}
        error={errors?.exchangeRate}
        tooltipText={general.exchangeRateTooltip}
        autoComplete="off"
      />

      <div className="general__drop-area">
        <DropArea
          label={general.tokenLogo}
          areaMessage={general.tokenLogoFileAndFormat}
          fileName="Token logo"
          accept={config?.fileUploadConstants?.IMAGE?.allowedExtensions}
          lastFile={logo}
          fileSetter={setLogo}
          leftIconOR={logo}
          deleteFileFunction={() => {
            setLogo(null);
          }}
          withRecycleBinView
          maxFiles={1}
          onError={(errors) => {
            errors.forEach((error) => {
              if (error === "too-many-files") {
                toast.error(dropArea.errors.onlyOne);
              } else if (error === "file-too-large") {
                toast.error(dropArea.errors.tooBig);
              }
            });
          }}
          tooltipText={general.tokenLogoTooltip}
        />
      </div>
      <Button type="submit">{general.save}</Button>
    </form>
  );
};

export default General;
