import React from "react";
import { useHistory } from "react-router-dom";

import { useAppContext } from "../../context/store";

// Components
import Button from "../common/button/Button";
import Modal from "../common/modal/Modal";

const ApplyStartModal = () => {
  const {
    strings: { startProcess },
  } = useAppContext();
  const history = useHistory();

  return (
    <Modal>
      <h2 className="font-XXL-700 col-primary">{startProcess.title}</h2>
      <div className="font-S-400 mg-24-top col-black">{startProcess.text}</div>
      <Button
        className="mg-24-top"
        onClick={() => {
          history.push("/fundraise");
        }}
      >
        {startProcess.start}
      </Button>
    </Modal>
  );
};

export default ApplyStartModal;
