import React from "react";
import "./SummaryPreviewModal.scss";

// Components
import Modal from "../common/modal/Modal";
import LiveCard from "../liveCard/LiveCard";

const SummaryPreviewModal = ({ data, onClose }) => {
    return (
        <Modal fitContent={true} onClose={onClose}>
            {/* <h2 className="font-XXL-700 col-primary">{summaryPreview.title}</h2> */}
            <LiveCard data={data} />
        </Modal>
    );
};

export default SummaryPreviewModal;
