import React from 'react'
import { useAppContext } from '../../../context/store';
import MemberTeamCard from '../../memberTeamCard/MemberTeamCard'
import "./Teams.scss"

// Components
import Spinner from "../../common/spinner/Spinner";

const Teams = ({ data }) => {
    const { isLoading } = useAppContext();

    if (isLoading) {
        return (
            <Spinner
                wrapperStyle={{
                    height: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            />
        );
    }

    return (
        <div className="detail-teams">
            {
                data.teams.map((member, index) => {
                    return (
                        <MemberTeamCard
                            key={index}
                            member={member} />
                    )
                })
            }
        </div>
    )
}

export default Teams