import React from "react";

import { Oval } from "react-loader-spinner";

const Spinner = ({ style, wrapperStyle }) => {
  return (
    <div style={wrapperStyle}>
      <Oval
        ariaLabel="loading-indicator"
        height={100}
        width={100}
        strokeWidth={6}
        color="var(--color-primary)"
        secondaryColor="var(--color-gray-light)"
        wrapperStyle={{
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      />
    </div>
  );
};

export default Spinner;
