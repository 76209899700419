import React from "react";
import "./BlockItem.scss";

import { FaEdit } from "react-icons/fa";

// Components
import Button from "../../common/button/Button";
import { useAppContext } from "../../../context/store";

const BlockItem = ({
  type,
  onClick,
  label,
  value,
  textButton,
  disableButton,
  loading,
  buttonStyle,
  style,
}) => {
  const {
    strings: { dashboard },
  } = useAppContext();
  return (
    <div className="block-item font-S-600" style={style}>
      <div className="block-item__left">
        {label && <span>{label}:</span>} <span>{value}</span>
      </div>
      {type === "change" && (
        <div className="block-item__change" onClick={onClick}>
          <FaEdit color="#30b8ae" fontSize={22} /> {dashboard.profile.change}
        </div>
      )}
      {type === "button" && (
        <div className="block-item__change">
          <Button
            onClick={onClick}
            disabled={disableButton}
            loading={loading}
            style={buttonStyle}
          >
            {textButton}
          </Button>
        </div>
      )}
      {!type && <div style={{ height: 140 }}></div>}
    </div>
  );
};

export default BlockItem;
