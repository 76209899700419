import React, { useState, useEffect, useRef } from "react";
import "./Select.scss";
import ReactTooltip from "react-tooltip";
import { IoChevronDownSharp } from "react-icons/io5";
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";

const Select = ({
  options = [],
  value,
  onChange,
  className,
  label,
  placeholder,
  dynamicList,
  optionsLabelHandler,
  tooltipText,
  readOnly
}) => {
  const [selected, setSelected] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const [firstOpen, setFirstOpen] = useState(true);
  const [selectOptions, setSelectOptions] = useState(options);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (!isOpen && !firstOpen) {
      if (selected !== undefined) {
        onChange && onChange(selected);
      }
    }
    setFirstOpen(false);
  }, [isOpen]);

  useEffect(() => {
    setSelectOptions(options);
  }, [options]);

  return (
    <div className={`select ${className || ""}`}>
      <ReactTooltip
        place="right"
        effect="solid"
        textColor="var(--color-white)"
        backgroundColor="var(--color-primary"
        id="select-tooltip"
      />
      <label className="label">
        {label}
        {tooltipText ? (
          <span className="input-tooltip" style={{ marginRight: "auto" }}>
            <span
              data-for="select-tooltip"
              data-tip={tooltipText}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InfoIcon />
            </span>
          </span>
        ) : null}
      </label>
      {/* <StyledPopup
        position="bottom"
        trigger={
          <OutLabel>
            {placeHolder}
            <Arrow>
              <ChevronDown size={24} color="#3d3d3d" />
            </Arrow>
          </OutLabel>
        }
        nested
      > */}
      <div
        className={`select__placeholder ${readOnly ? "select__read-only" : ""} font-S-600`}
        onClick={() => {
          if (!readOnly) {
            setIsOpen((prev) => !prev);
          }
          
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !readOnly) {
            setIsOpen((prev) => !prev);
          }
        }}
        tabIndex={0}
      >
        {dynamicList
          ? placeholder
          : optionsLabelHandler
          ? optionsLabelHandler(selected)
          : selected
          ? selected
          : placeholder}
        <span
          className={`select__arrow-icon ${
            isOpen ? "select__arrow-icon--down" : ""
          }`}
        >
          <IoChevronDownSharp color={readOnly ? "var(--color-gray-light)" : "var(--color-primary)"} fontSize={28} />
        </span>
      </div>
      {/* {(close) => */}
      {/* options && ( */}

      {isOpen && (
        <div
          className="select__list"
          ref={ref}
          style={{ top: label ? 80 : 58 }}
        >
          {selectOptions.length > 0 ? (
            selectOptions?.map((o, i) => {
              return (
                <button
                  tabIndex={0}
                  key={o}
                  className={`select__option ${readOnly ? "select__read-only" : ""} font-S-600 ${
                    o === selected ? "select__option--selected" : ""
                  }`}
                  onClick={() => {
                    //   setOption(o);
                    //   placeHolderList.length &&
                    //     setPlaceHolder &&
                    //     setPlaceHolder(placeHolderList[i]);
                    //   setIndex && setIndex(i);

                    if (!readOnly) {
                      setSelected(o);
                      setIsOpen(false);
                    }
                  }}
                >
                  {/* {(placeHolderList.length && placeHolderList[i]) || o} */}
                  {optionsLabelHandler ? optionsLabelHandler(o) : o}
                  <div className="select__option-plus">
                    {dynamicList && <BsPlusLg size={18} color="#2EC6BA" />}
                  </div>
                </button>
              );
            })
          ) : (
            <div className="select__no-items">No items</div>
          )}
        </div>
      )}

      {/* ) } */}
      {/* </StyledPopup> */}
    </div>
  );
};

export default Select;
