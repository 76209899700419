import { apiCall } from "../api";

export const getStatusService = async (appDispatch) => {
  const config = { url: "/fundraising/status", method: "get" };

  return apiCall(config, appDispatch);
};

export const changePasswordService = async (appDispatch, data) => {
  const config = { url: "/passwords/change", method: "post", data };

  return apiCall(config, appDispatch);
};

export const setCampaignDeployed = async (appDispatch, deployId, data) => {
  const config = { url: `/fundraising/deploy/${deployId}/claim`, method: "post", data };

  return apiCall(config, appDispatch);
};

export const getApplicationsGeneralService = async (appDispatch) => {
  const configApp = { url: "/fundraising/applications", method: "get" };
  const configGen = { url: "/fundraising/general", method: "get" };
  const configIdea = { url: "/fundraising/idea", method: "get" };
  const configMedia = { url: "/fundraising/media", method: "get" };
  const configTeams = { url: "/fundraising/teams", method: "get" };
  const configDocuments = { url: "/fundraising/documents", method: "get" };

  try {
    const { data: applicationData } = await apiCall(configApp, appDispatch);
    const { data: generalData } = await apiCall(configGen, appDispatch);
    const { data: ideaData } = await apiCall(configIdea, appDispatch);
    const { data: mediaData } = await apiCall(configMedia, appDispatch);
    const { data: teamsData } = await apiCall(configTeams, appDispatch);
    const { data: documentsData } = await apiCall(configDocuments, appDispatch);
    return { applications: applicationData, general: generalData, idea: ideaData, media: mediaData, teams: teamsData, documents: documentsData };
  } catch (error) {
    throw error;
  };
}
/***********TODO: REMOVE FAKE DATES ********************/

let campaignStartTimestamp = "2022-01-31T14:38:38.744Z";
let campaignEndTimestamp = "2022-12-31T14:38:38.744Z";
export const deployCampaignService = async (appDispatch, campaignId, contracts = [], mainnet) => {
  const config = {
    method: "post",
    url: "/fundraising/status/deployed",
    data: {
      campaignId,
      contracts,
      campaignStartTimestamp,
      campaignEndTimestamp,
      productionDeploy: mainnet
    }
  };

  return apiCall(config, appDispatch);
  // return { ok: "ok" };
};

export const getApplications = async (
  appDispatch
) => {
  const config = {
    url: `/fundraising/applications`,
    method: "GET",
  };

  return apiCall(config, appDispatch, true);
};

export const getGeneral = async (
  appDispatch
) => {
  const config = {
    url: `/fundraising/general`,
    method: "GET",
  };

  return apiCall(config, appDispatch, true);
};

export const getIdea = async (
  appDispatch
) => {
  const config = {
    url: `/fundraising/idea`,
    method: "GET",
  };

  return apiCall(config, appDispatch, true);
};

export const getMedia = async (
  appDispatch
) => {
  const config = {
    url: `/fundraising/media`,
    method: "GET",
  };

  return apiCall(config, appDispatch, true);
};

export const getTeams = async (
  appDispatch
) => {
  const config = {
    url: `/fundraising/teams`,
    method: "GET",
  };

  return apiCall(config, appDispatch, true);
};

export const getDocumentation = async (
  appDispatch
) => {
  const config = {
    url: `/fundraising/documents`,
    method: "GET",
  };

  return apiCall(config, appDispatch, true);
};
