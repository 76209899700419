import BN from 'bn.js'

export const uintToFloat = (uintNum, decimals) => {
  if (!uintNum) return "0";
  decimals = parseInt(decimals);
  let n = uintNum.toString();
  n = "0".repeat(decimals) + n;
  if (decimals > 0) n = n.slice(0, -decimals) + "." + n.slice(-decimals);
  while (n[0] === "0" && n[1] !== ".") {
    n = n.slice(1);
  }
  return n;
};

/**
 * On Ethereum chain currencies are represented as Integers encoded to strings
 * = amount * 10 ^ decimals
 *
 * In JS it quickly collapses to exponential form when encoded in base 10:
 * >> (122323232.23293203923092334*10**18).toString(10)
 * << '1.2232323223293204e+26'
 * >> (0.00000001).toString(10)
 * << '1e-8'
 *
 * Option 1:
 * const [up, dw] = amount.toString(10).split('.')
 * return up.replace(/^0+/, '')
 *     + (dw + '0'.repeat(decimals)).substring(0, decimals)
 *
 * Option 2:
 * https://ethereum.stackexchange.com/questions/41506
 *
 * /Borut 2022-09-18
 *
 * @ts amountToWei(amount: BN | string | number, decimals: string | number): Amount
 */
export function amountToWei(amount, decimals) {

  // Parse and coalesce input
  const bn= (amount instanceof BN) ? amount : new BN(amount)

  // Simple and effective
  const long = bn.mul((new BN(10)).pow(new BN(decimals)))
  if (long.isNeg()) throw new Error('Invalid negative amount')

  // Convert to string without decimals (this is a big integer)
  return long.toString(10);

}

/**
 * This function is used for display purposes, so precision loss is acceptable
 * /Borut 2022-09-18
 *
 * @ts weiToAmountFloat(amount: BN | string | number, decimals: string | number): number
 */
export function weiToAmountFloat(amount, decimals) {
  // Parse and coalesce input
  const nm = (amount instanceof BN) ? (amount).toNumber() : parseFloat(amount)
  // Just divide by decimals
  return nm / 10 ** parseFloat(decimals);
}

export const getDecimalsByToken = (token, config) => {
  return config.tokens.find((t) => t.symbol === token).decimals;
};

export const getAddressByToken = (token, blockchain, config) => {
  return config.tokens
    .find((t) => t.symbol === token)
    .blockchains.find((bc) => bc.name === blockchain)?.address;
};
