/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import "./DetailPage.scss";

import {useAppContext} from "../../context/store";

// Components
import {Navbar, Spinner, WizardNav, Wrapper,} from "../../components";
import {getStatusService} from "../../services/dashboardServices";

import {
  FaDiscord as Discord,
  FaFacebook as Facebook,
  FaInstagram as Instagram,
  FaLinkedin as Linkedin,
  FaTelegram as Telegram,
  FaTiktok as Tiktok,
  FaTwitter as Twitter,
  FaWhatsapp as Whatsapp,
  FaYoutube as Youtube
} from "react-icons/fa";
import {AiOutlineInfoCircle as InfoIcon} from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import CarouselDetail from "../../components/CarouselDetail/CarouselDetail";
import GreenSliceSoon from "../../components/CampaignDetailGreenSlices/GreenSliceSoon/GreenSliceSoon";
import {Idea, Sponsor, Teams} from "../../components/detail";
import Documentation from "../../components/detail/documentation/Documentation";
import {autoIncludeHttp} from "../../utils/helperFunctions";
import {SET_STATUS} from "../../context/reducer";
import {useWeb3Context} from "../../context/web3Store";

import {getCashbackContract} from '../../web3Wrapper/web3Helpers';
import {getDecimalsByToken} from '../../web3Wrapper/helpers';
import {cashback_leadInvestorFSPAmounts} from '../../web3Wrapper/cashbackMethods';


import default_logo from '../../assets/svg/logo.svg';
import {DurationSpan} from '../../v2/DurationSpan';

const DetailPage = () => {
  const {
    isLoading,
    appDispatch,
    strings,
    config,
    isLogged,
    status
  } = useAppContext();
  const {
    applications,
    general,
    idea,
    media,
    teams,
    documents,
    getDataAndContracts,
    walletAddress
  } = useWeb3Context();

  const { detail } = strings;
  const numberFormat = new Intl.NumberFormat("en-EN")

  const [stepNames] = useState([
    {
      step: 0,
      name: "Idea",
    },
    {
      step: 1,
      name: "Sponsor",
    },
    {
      step: 2,
      name: "Team",
    },
    {
      step: 3,
      name: "Documentation",
    }
  ])
  const [data, setData] = useState()
  const [socialList, setSocialList] = useState([])
  const [stepWizard, setStepWizard] = useState(0);
  const [images, setImages] = useState([])
  const [seedAmount, setSeedAmount] = useState();

  const getStatus = async () => {
    try {
      const { data } = await getStatusService(appDispatch);

      appDispatch({ type: SET_STATUS, payload: data });
    } catch (error) {

    }
  };

  useEffect(() => {
    if (isLogged) {
      getStatus();
    }
  }, [isLogged]);

  useEffect(async() => {
     if(applications){
       const cashContract = await getCashbackContract(data?.blockchain, config);
       const decimals = await getDecimalsByToken(data?.paymentToken, config);
       const seedAmount = await cashback_leadInvestorFSPAmounts(cashContract, (status.deploy.campaignId).toString(), walletAddress, decimals);
       setSeedAmount(seedAmount);
     }
  }, [status, applications]);

  const getCurrentComponent = () => {
    switch (stepWizard) {
      case 0:
        return <Idea data={data} />
      case 1:
        return <Sponsor data={data} />
      case 2:
        return <Teams data={data} />
      case 3:
        return <Documentation data={data} config={config} />
      default:
        return <div></div>
    }
  };

  useEffect(() => {
    const newSocialList = []
    const components = {
      discord: Discord,
      facebook: Facebook,
      instagram: Instagram,
      linkedin: Linkedin,
      telegram: Telegram,
      tiktok: Tiktok,
      twitter: Twitter,
      whatsapp: Whatsapp,
      youtube: Youtube
    }

    Object.keys(data ?? {}).forEach((key) => {
      if (config.socials.sort((a, b) => a.order - b.order).map((social) => social.key).includes(key) && (data[key] !== "null" && data[key] !== "" && data[key])) {
        const SocialIcon = components[key]
        newSocialList.push({
          url: data[key],
          component: SocialIcon
        })
      }
    })

    setSocialList(newSocialList)

    const newImages = data?.images ?? []

    if (data?.image) {
      newImages.splice(0, 0, { id: undefined, link: data.image })
    }

    setImages(newImages)
  }, [data])

  useEffect(() => {
    setData({ ...applications, ...general, ...idea, ...media, teams, documents })
  }, [applications, general, idea, media, teams, documents])

  useEffect(() => {
    getDataAndContracts()
  }, [])

  if (isLoading || !data)
    return (
      <Spinner
        wrapperStyle={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  return (
    <>
      <ReactTooltip
        place="top"
        backgroundColor="var(--color-primary)"
        textColor="var(--color-white)"
        effect="solid"
        id="detail-tooltip"
      />
      <Navbar />
      <div className="page-container">
        <div className="page-container__abover-data">
          <div className="page-container__container">
            <div className="page-container__left-wrapper">
              <div className="page-container__title">{data?.name}</div>
              <div className="page-container__description">{data?.intro}</div>
              <div className="page-container__website">
                {detail.websiteLabel}
                <a className="page-container__website-link" href={autoIncludeHttp(data?.website)} target="_blank" rel="noreferrer">{data?.website}</a>
              </div>
              <div className="page-container__social">
                {detail.socialLabel}
                {
                  socialList.map((social, index) => {
                    const Icon = social.component
                    return (
                      <a key={index} href={social.url} style={{ marginLeft: index === 0 ? "48px" : "24px", display: "flex", alignItems: "center", cursor: "pointer", color: "inherit" }} target="_blank" rel="noreferrer">
                        <Icon />
                      </a>
                    )
                  })
                }
              </div>
              <div className="page-container__token">
                <div className="page-container__info-token-group-space">
                  <div className="page-container__info-token-subgroup">
                    {detail.iconLabel}
                    <div className="page-container__info-token">
                      <img src={data?.logoImage ?? default_logo} width="32" height="32" alt="" />
                    </div>
                  </div>
                  <div className="page-container__info-token-subgroup">
                    <div className="page-container__info-title">{detail.symbolLabel}</div>
                    <div className="page-container__info-text">{data?.tokenSymbol}</div>
                  </div>
                </div>
                <div className="page-container__info-token-group-space">
                  <div className="page-container__info-token-subgroup">
                    <div className="page-container__info-title">{detail.tokenLabel}</div>
                    <div className="page-container__info-text">{data?.tokenName}</div>
                  </div>
                  <div className="page-container__info-token-subgroup">
                    <div className="page-container__info-title">
                      {detail.ratioLabel}
                      <div className="label-tooltip" data-for="detail-tooltip" data-tip={detail.ratioTooltip}>
                        <InfoIcon />
                      </div>
                    </div>
                    <div className="page-container__info-text">1/{numberFormat.format(data?.exchangeRate).replaceAll(",", config.general.decimalSeparator)}</div>
                  </div>
                </div>
              </div>
              <div className="page-container__token-row">
                {detail.contractLabel}
                <div className="label-tooltip" data-for="detail-tooltip" data-tip={detail.contractTooltip}>
                  <InfoIcon />
                </div>
                <div className="page-container__info-text-2 ellipsis"><a href={config.blockchains.find((blockchain) => blockchain.name === data?.blockchain)?.scanUrl + status?.deploy?.contracts?.find((contract) => contract.name === "fundTokenContract")?.address ?? ""} target="_blank" rel="noreferrer">{status?.deploy?.contracts?.find((contract) => contract.name === "fundTokenContract")?.address ?? ""}</a></div>
              </div>
              <div className="page-container__token-row">
                  {detail.minimumInvestorSeedBalanceLabel }
                  <div className="page-container__info-text">{
                    numberFormat.format(data?.minimumInvestorSeedBalance).replaceAll(",", config.general.decimalSeparator)} {detail.seed}</div>
                </div>
              {
                data?.seedCashbackDuration > 0 &&   data?.seedCashbackEnabled &&(
                <div className="page-container__token-row">
                  {detail.cashbackCrowdInvestors }
                  <div className="page-container__info-text">{seedAmount} {detail.seed} </div>
                </div>
                )
              }
              {
                data?.seedCashbackDuration > 0 && (
                <div className="page-container__token-row">
                  {detail.lockupPeriod}
                  <div className="page-container__info-text">
                    {numberFormat.format(data?.seedCashbackDuration).replaceAll(",", config.general.decimalSeparator)}&nbsp;
                      (
                      <span className="page-container__estimated-text">{detail.estimated}&nbsp;</span>
                      <DurationSpan blocks={data?.seedCashbackDuration} />
                      )
                  </div>
                </div>
                )
              }
            </div>
            <div className="page-container__right-wrapper">
              {
                images.length > 0 &&
                <CarouselDetail images={images} />
              }
              {
                images.length === 0 &&
                <div className="no-images">{detail.noImages}</div>
              }
            </div>
          </div>
        </div>
        <div className="page-container__abover">
          <div className="page-container__underwrapper">
            <GreenSliceSoon startupInfo={data} />
            <Wrapper className="detail-wrapper">
              <div className="page-container__tag-content">
                <WizardNav
                  className="no-margin"
                  stepNames={stepNames}
                  stepWizard={stepWizard}
                  setStepWizard={(step) => setStepWizard(step)}
                />

                {config ? (
                  getCurrentComponent()
                ) : (
                  <Spinner
                    wrapperStyle={{
                      height: 500,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                )}
              </div>
            </Wrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailPage;
