import React, {useEffect} from 'react';
import './DashboardProgressBar.scss';
import ProgressBar from '@ramonak/react-progress-bar';
//import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";

import { useAppContext } from '../../../context/store';
import { BLOCK_CAMPAIGN } from '../../../context/reducer';
const DashboardProgressBar = () => {
  const getInitialDate = () => {
    try {
      return JSON.parse(localStorage.getItem('USER_LOGIN_DATA'))
      .applicationData.passwordChangeTimestamp;
    } catch (error) {

    }
  };
  const { config, strings, appDispatch } = useAppContext();
  const { progressBar } = strings;
  const startDate = new Date(getInitialDate());
  const endDate = new Date(
    startDate.getTime() + config?.deadLine?.days * 24 * 60 * 60 * 1000
  );

  const percent = Math.round((1 - (endDate.getTime() - new Date().getTime()) / (endDate.getTime() - startDate.getTime())) * 100);

  let daysLeft = Math.round((endDate - new Date()) / (1000 * 60 * 60 * 24));
  daysLeft = daysLeft >= 0 ? daysLeft : 0

  useEffect(() => {
    if (daysLeft === 0) {
      appDispatch({ type: BLOCK_CAMPAIGN });
    }
  }, [appDispatch]);

  return (
    <div className="progress-bar__block">
      <div className="progress-bar__bar-container">
        <div className="progress-bar__upper-small">
          {progressBar.estimated}&nbsp;{daysLeft}
        </div>
        <div className="progress-bar__bar">
          <ProgressBar
            completed={percent}
            height={'12px'}
            bgColor={'#2EC6BA'}
            baseBgColor={'#EFF0F7'}
            isLabelVisible={false}
            borderRadius={'6px'}
            width={'100%'}
          />
        </div>
        <div className="progress-bar__lower-small">
          <span>
            {progressBar.startDate} {new Date(startDate).toLocaleDateString()}
          </span>
          <span>
            {progressBar.endDate} {new Date(endDate).toLocaleDateString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardProgressBar;
