import React, { useState } from "react";
import "./ChangePassModal.scss";

import Input from "../common/Input/Input";
import Modal from "../common/modal/Modal";
import Button from "../common/button/Button";
import { changePasswordService } from "../../services/dashboardServices";
import { useAppContext } from "../../context/store";
import { LOGOUT, UNBLOCK_CAMPAIGN } from "../../context/reducer";
import { toast } from "react-toastify";

const ChangePassModal = ({ onClose }) => {
  const {
    appDispatch,
    requirePasswordChange,
    strings: { changePasswords, common },
  } = useAppContext();
  const [oldP, setOldP] = useState("");
  const [newP, setNewP] = useState("");
  const [repNewP, setRepNewP] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = { oldPassword: oldP, password: repNewP };

    try {
      if (newP === repNewP) {
        await changePasswordService(appDispatch, body);
        appDispatch({ type: LOGOUT });
        appDispatch({ type: UNBLOCK_CAMPAIGN })
        onClose();
      } else {
        toast.error(changePasswords.notTheSameErr);
      }
    } catch (error) {

    }
  };

  return (
    <Modal
      className="change-password-modal"
      onClose={!requirePasswordChange && onClose}
    >
      <h2 className="font-XXL-700 col-primary">{changePasswords.title}</h2>
      <form className="change-password-modal__form" onSubmit={handleSubmit}>
        <Input
          label={changePasswords.oldPasswordLabel}
          type="password"
          id="old_pwd"
          value={oldP}
          onChange={(e) => setOldP(e.target.value)}
          required
          autoComplete="current-password"
          minLength="8"
          placeholder={changePasswords.oldPasswordPlaceholder}
        />
        <Input
          label={changePasswords.newPasswordLabel}
          type="password"
          id="new_pwd"
          value={newP}
          onChange={(e) => setNewP(e.target.value)}
          required
          autoComplete="new-password"
          minLength="8"
          placeholder={changePasswords.newPasswordPlaceholder}
        />
        <Input
          label={changePasswords.newPasswordLabelRep}
          type="password"
          id="new_pwd_rep"
          value={repNewP}
          onChange={(e) => setRepNewP(e.target.value)}
          required
          autoComplete="new-password"
          minLength="8"
          placeholder={changePasswords.newPasswordPlaceholderRep}
        />
        <Button type="submit" className="change-password-modal__button">
          {changePasswords.confirm}
        </Button>
      </form>
    </Modal>
  );
};

export default ChangePassModal;
