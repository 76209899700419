import React from "react";
import { Link } from "react-router-dom";
import "./NavbarLinkButton.scss";

const NavbarLinkButton = (props) => {
  const {
    children,
    to,
    text,
    icon,
    iconComponent,
    href,
    target,
    onClick,
    isFooterLink,
    style,
    ...rest
  } = props;

  if (onClick) {
    return (
      <span
        className={`navbar-link-button ${
          isFooterLink ? "font-XS-600" : "font-S-700"
        }`}
        onClick={onClick}
        {...rest}
      >
        {iconComponent}
        {icon && (
          <div className="navbar-link-button__icon">
            {icon && <img src={icon} alt="icon" />}
          </div>
        )}

        {children || text}
      </span>
    );
  }

  if (href) {
    return (
      <a
        style={style}
        className={`navbar-link-button ${
          isFooterLink ? "font-XS-600" : "font-S-700"
        }`}
        href={href}
        target={target}
        {...rest}
      >
        {icon && (
          <div className="navbar-link-button__icon">
            <img src={icon} alt="icon" />
          </div>
        )}
        {children || text}
      </a>
    );
  }

  return (
    <Link
      className={`navbar-link-button ${
        isFooterLink ? "font-XS-600" : "font-S-700"
      } ${props.className || ""}`}
      to={to}
      target={target}
      style={style}
    >
      {icon && (
        <div className="navbar-link-button__icon">
          {" "}
          <img src={icon} alt="icon" />
        </div>
      )}
      {children || text}
    </Link>
  );
};

export default NavbarLinkButton;
