import React, { useState, useEffect } from "react";
import "./LoginForm.scss";

// Context
import { useAppContext } from "../../../context/store";

// Components
import Input from "../../common/Input/Input";
import Button from "../../common/button/Button";
import { toast } from "react-toastify";

// Services
import { setAuthTokenByLoginRequest } from "../../../services/loginServices";
import { useHistory } from "react-router-dom";

const LoginForm = () => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const [isLoginDisabled, setIsLoginDisabled] = useState(false);

  const {
    appDispatch,
    strings: { login, common },
  } = useAppContext();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setAuthTokenByLoginRequest(username, password, appDispatch, history);
    } catch (error) {
      console.error(error);
    }
  };

  // Handle login button
  useEffect(() => {
    if (username && password) {
      setIsLoginDisabled(false);
    } else {
      setIsLoginDisabled(true);
    }
  }, [username, password]);

  return (
    <form
      className="login-form"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <Input
        autoComplete="off"
        type="text"
        label={login.emailLabel}
        placeholder={login.emailPlaceholder}
        id="username"
        onChange={(e) => setUserName(e.target.value)}
      />
      <Input
        type="password"
        label={login.passwordLabel}
        placeholder={login.passwordPlaceholder}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button type="submit" disabled={isLoginDisabled}>
        {login.submitButton}
      </Button>
    </form>
  );
};

export default LoginForm;
