// Helpers
import {amountToWei} from "./helpers";
import {sha3_256} from "js-sha3";
import {urlToBuffer} from '../utils/helperFunctions';

export const factory_deployCampaign = async (
  name,
  symbol,
  docUrl,
  exchangeRate,
  paymentTokenMaxSupply,
  WLEmissionAnonymThr,
  WLTransferAnonymThr,
  contract,
  walletAddress,
  decimals,
  config,
  handleCampaignDeployed,
  setIsMainnet,
  mainnet
) => {
  if (contract && walletAddress) {
    const docObj = await urlToBuffer(docUrl);
    // used to avoid cors issues
    // const docObj = Buffer.from('DOCUMENTO DI MERDA', "utf-8");
    const hashedDoc = "0x" + sha3_256(docObj);

    // it seems like exchangeRate shall be encoded to WEI with the payment token decimals
    // Emanuele says: il numero di decimali dell'exchange rate viene calcolato come
    // 18 + (18 - numero di decimali del token di pagamento)
    const exRateDecimals =
        (config.smartContractsParameters.default_fund_decimals
            + config.smartContractsParameters.default_fund_decimals
            - decimals)

    const args = [
      name,
      symbol,
      docUrl,
      hashedDoc,
      amountToWei(exchangeRate, exRateDecimals),
      amountToWei(paymentTokenMaxSupply, decimals),
      // todo : review the WLE andWLT variables to fit whitelist specs
      // todo : Does this value uses Payment Token decimals or SC 18 decimals?
      // amountToWei(WLEmissionAnonymThr, decimals),
      // amountToWei(WLTransferAnonymThr, decimals),
      // todo : this is a temporary fix, we use the max uint256 available
      WLEmissionAnonymThr,
      WLTransferAnonymThr
    ]

    console.log('deployCampaign', ...args)

    const response = await contract.methods
      .deployCampaign(...args)
      .send({ from: walletAddress })
      .then((res) => {
        if (handleCampaignDeployed) handleCampaignDeployed(res, mainnet)
        if (setIsMainnet) setIsMainnet(mainnet)
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }
  return null;
};

export const factory_getFSPIndexByAddress = async (
  contract,
  account,
  stateSetter,
  address
) => {
  if (contract && account) {
    await contract.methods
      .getFSPIndexByAddress(address)
      .call({ from: account })
      .then((res) => {
        stateSetter && stateSetter(res);
      })
      .catch((err) => {
        throw err;
      });
  }
};

export const factory_getFSPAddressByIndex = async (
  contract,
  account,
  stateSetter,
  index
) => {
  if (contract && account && index) {
    const response = await contract.methods
      .getFSPAddressByIndex(index)
      .call({ from: account })
      .then((res) => {
        stateSetter && stateSetter(res);
        return res;
      });
    return response;
  }
  return null;
};
