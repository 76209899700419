import React, { useState, useEffect, useRef } from "react";
import "./Media.scss";

import { apiCall } from "../../../api";

import { useAppContext } from "../../../context/store";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { updateCarouselImagesService } from "../../../services/fundraiseServices";

// Helpers
import { getIconBySocial, trimSpaces } from "../../../utils/helperFunctions";

// Components
import DropArea from "../../common/dropArea/DropArea";
import Spinner from "../../common/spinner/Spinner";
import Button from "../../common/button/Button";
import Input from "../../common/Input/Input";
import Select from "../../common/select/Select";

const Media = ({ mediaData, onSave }) => {
  const { config, appDispatch, isLoading, strings } = useAppContext();
  const { media, common } = strings;

  const [coverImage, setCoverImage] = useState(mediaData?.image);
  const [carouselImages, setCarouselImages] = useState(mediaData?.images);

  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [imagesToPost, setImagesToPost] = useState([]);

  const mediaImages = useRef();

  const {
    constants: { MAX_CAROUSEL_IMAGES },
    fileUploadConstants: { IMAGE },
  } = config;

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...mediaData,
      video: mediaData?.video !== "null" ? mediaData?.video : "",
    },
  });

  const watchSocial = watch();

  const onSubmit = handleSubmit(async (values, e) => {
    if (!coverImage) {
      mediaImages.current.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      return toast.error(media.coverImageError);
    }

    try {
      const data = await updateCarouselImagesService(
        imagesToDelete,
        imagesToPost,
        setImagesToDelete,
        setImagesToPost,
        appDispatch
      );

      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (key === "video" && !value) {
          formData.append("video", null);
          return;
        }
        formData.append(key, trimSpaces(value));
      });

      formData.delete("id");

      formData.append("image", coverImage);

      const config = {
        method: "put",
        url: "/fundraising/media",
        data: formData,
      };

      const { data: mediaData } = await apiCall(config, appDispatch);

      if (data || mediaData) {
        onSave(3, data || mediaData);

        toast.success(strings.common.changedData);
      }
    } catch (error) {

    }
  });

  const removeCarouselImage = (image) => {
    const newCarouselImages = carouselImages.filter((carImage) => {
      if (carImage?.id) return carImage?.id !== image.id;
      return carImage !== image;
    });

    // Handle urls image to delete by their ids
    if (image?.id) {
      setImagesToDelete((prev) => [...prev, image?.id]);
    } else {
      setImagesToPost((prev) =>
        prev.filter((postImage) => postImage !== image)
      );
    }

    setCarouselImages(newCarouselImages);
  };

  const addCarouselImage = (e) => {
    setImagesToPost((prev) => [...prev, e]);
    setCarouselImages((prev) => [...prev, e]);
  };

  useEffect(() => {
    setCarouselImages(mediaData?.images);
  }, [mediaData?.images]);

  if (isLoading) {
    return (
      <Spinner
        wrapperStyle={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <form className="media__form" onSubmit={onSubmit}>
      <h2 ref={mediaImages} className="media__images-title font-XL-700">
        {media.images}
      </h2>

      <div className="media__images">
        <DropArea
          tooltipText={media.coverImageTooltip}
          label={media.coverImage}
          areaMessage={media.coverImageSizeAndFormat}
          fileName="Cover image"
          accept={IMAGE.allowedExtensions}
          maxFiles={1}
          maxSize={IMAGE?.sizeInByte}
          imageToPreviewBig={coverImage}
          lastFile={coverImage}
          fileSetter={setCoverImage}
          leftIconOR={coverImage}
          deleteFileFunction={() => {
            setCoverImage(null);
          }}
          withRecycleBinView
        />
        {!(carouselImages.length === MAX_CAROUSEL_IMAGES) && (
          <DropArea
            tooltipText={media.carouselImagesToolip}
            areaMessage={strings.formatString(
              media.carouselImagesSizeAndFormat,
              { MAX_CAROUSEL_IMAGES }
            )}
            fileName="Carousel image"
            imageToPreviewBig={coverImage}
            accept={IMAGE.allowedExtensions}
            maxSize={IMAGE?.sizeInByte}
            fileSetter={addCarouselImage}
            leftIconOR={coverImage}
            deleteFileFunction={() => {
              setCoverImage(null);
            }}
            withRecycleBinView
            label={media.carouselImages}
            maxFiles={MAX_CAROUSEL_IMAGES - carouselImages.length}
            onError={(errors) => {
              errors.forEach((error) => {
                if (error === "too-many-files") {
                  toast.error(
                    strings.formatString(media.maxCarouselImages, {
                      MAX_CAROUSEL_IMAGES,
                    })
                  );
                }
              });
            }}
          />
        )}
      </div>
      <div className="media__carousel-images">
        {carouselImages.map((image, index) => {
          return (
            <DropArea
              key={image?.id || image?.lastModified + index}
              imageToPreviewBig={image?.link || image}
              lastFile={image?.link || image}
              fileName=""
              isCarousel
              deleteFileFunction={(e) => {
                // console.log(e);
                if (e instanceof File) {
                  removeCarouselImage(e);
                } else {
                  removeCarouselImage(image);
                }
              }}
            />
          );
        })}
      </div>
      <div className="media__web-video-social">
        <div className="media__web-video">
          <div className="media__web-video-section">
            <h2 className="media__web-video-section-title">Website</h2>
            <Input
              label={media.websiteLabel}
              {...register("website", {
                required: media.required,
                pattern: {
                  value: new RegExp(config.regex.validURL),
                  message: common.invalidURL,
                },
              })}
              aria-invalid={!!errors.website}
              error={errors?.website}
              tooltipText={media.websiteTooltip}
              placeholder={media.websitePlaceholder}
            />
          </div>
          <div className="media__web-video-section">
            <h2 className="media__web-video-section-title">{media.video}</h2>
            <Input
              label={media.videoLabel}
              {...register("video", {
                pattern: {
                  value: new RegExp(config.regex.validURL),
                  message: common.invalidURL,
                },
              })}
              aria-invalid={!!errors.video}
              error={errors?.video}
              tooltipText={media.videoTooltip}
              placeholder={media.videoPlaceholder}
            />
          </div>
        </div>
        <div className="media__web-video-social-section">
          <div className="media__select">
            <h2 className="media__web-video-section-title">{media.social}</h2>
            <Select
              dynamicList
              label=""
              options={config.socials
                .sort((a, b) => a.order - b.order)
                .filter((social) => watchSocial[social.key] === null || watchSocial[social.key] === "null")
                .map((social) => social.key)
              }
              placeholder={media.addSocial}
              onChange={(e) => {
                if (e !== undefined) {
                  setValue(e, config.socialUrlStart[e]);
                }
              }}
            />
          </div>
          <div className="media__inputs-container">
            {config.socials
              .sort((a, b) => a.order - b.order)
              .map((social) => {
                if (
                  watchSocial[social.key] !== null &&
                  watchSocial[social.key] !== "null"
                )
                  return (
                    <div
                      key={social.key}
                      style={{
                        position: "relative",
                        width: "100%",
                        maxWidth: 325,
                      }}
                    >
                      <Input
                        placeholder={`Enter the ${social.key} url`}
                        label={
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 4,
                            }}
                          >
                            {getIconBySocial(social.key)}
                            {strings.socialLabels[social.key]}
                          </span>
                        }
                        onDelete={() => {
                          setValue(social.key, null);
                        }}
                        {...register(social.key, {
                          required: media.required,
                          pattern: {
                            value: config.regex?.[social.key] ? new RegExp(config.regex[social.key]) : /.*/,
                            message: strings.formatString(
                              media.invalidSocial,
                              { social: social.name }
                            ),
                          },
                        })}
                        aria-invalid={!!errors?.[social.key]}
                        error={errors?.[social.key]}
                        tooltipText={media.socialTooltips[social.key]}
                      />
                    </div>
                  );

                return null;
              })}
          </div>
        </div>
        <Button type="submit">{media.save}</Button>
      </div>
    </form>
  );
};

export default Media;
