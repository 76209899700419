import {amountToWei, weiToAmountFloat} from './helpers';
import Web3 from 'web3';
import {toast} from 'react-toastify';

export const fsp_setEconomicData = async (
  exchRate,
  exchRateOnTop,
  paymentTokenAddress,
  paymentTokenMinSupply,
  paymentTokenMaxSupply,
  minimumSeedToHold,
  seedCashbackEnabled,
  contract,
  account,
  stateSetter,
  decimals,
  config
) => {
  if (contract && account) {

    // it seems like exchangeRate shall be encoded to WEI with the payment token decimals
    // Emanuele says: il numero di decimali dell'exchange rate viene calcolato come
    // 18 + (18 - numero di decimali del token di pagamento)
    const exRateDecimals =
        (config.smartContractsParameters.default_fund_decimals
            + config.smartContractsParameters.default_fund_decimals
            - decimals)

    // TODO: verify the minimumSeedToHold decimals paramenter
    minimumSeedToHold = Web3.utils.toWei(minimumSeedToHold, 'ether');
    paymentTokenMinSupply = amountToWei(paymentTokenMinSupply, decimals);
    paymentTokenMaxSupply = amountToWei(paymentTokenMaxSupply, decimals);
    // minimumSeedToHold = floatToUint(minimumSeedToHold, decimals);

    console.log(
        'setEconomicData',
        amountToWei(exchRate, exRateDecimals),
        amountToWei(exchRateOnTop, exRateDecimals),
        paymentTokenAddress,
        paymentTokenMinSupply,
        paymentTokenMaxSupply,
        minimumSeedToHold,
        seedCashbackEnabled
    )

    const response = await contract.methods
      .setEconomicData(
        amountToWei(exchRate, exRateDecimals),
        amountToWei(exchRateOnTop, exRateDecimals),
        paymentTokenAddress,
        paymentTokenMinSupply,
        paymentTokenMaxSupply,
        minimumSeedToHold,
        seedCashbackEnabled
      )
      .send({ from: account })
      .then((res) => {
        stateSetter && stateSetter(res);
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }
  return null;
};

export const fsp_setCampaignDuration = async (
  contract,
  account,
  stateSetter,
  campaignDurationBlocks
) => {
  if (contract && account) {
    const response = await contract.methods
      .setCampaignDuration(campaignDurationBlocks)
      .send({ from: account })
      .then((res) => {
        stateSetter && stateSetter(res);
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }
  return null;
};

export const fsp_setFSPFinanceable = async (contract, account, stateSetter) => {
  console.log('setFSPFinanceable')
  if (contract && account) {
    const response = await contract.methods
      .setFSPFinanceable()
      .send({ from: account })
      .then((res) => {
        stateSetter && stateSetter(res)
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }
  return null;
};

export const fsp_checkCampaignStatus = async (contract) => {
  let response = {};

  if (contract) {
    const campaignOver = await contract.methods.isCampaignOver().call();
    response.campaignOver = campaignOver;

    if (campaignOver) {
      const campaignSuccessful = await contract.methods
        .isCampaignSuccessful()
        .call();
      response.campaignSuccessful = campaignSuccessful;
    }
    return response;
  }
  return null;
};

// Methods campaign ended
export const fsp_withdrawPaymentTokens = async (contract, startupOwnerAddress, amount, campaignSuccess, symbol, setCampaignDeployed, deployId, appDispatch, message, setLoading) => {
  if (contract) {
    const response = await contract.methods
      .withdrawPaymentTokens(startupOwnerAddress)
      .send({ from: startupOwnerAddress })
      .then((res) => {
        // WALLET, AMOUNT, TRANSACTION HASH
        return setCampaignDeployed(appDispatch, deployId, {
          walletAddress: startupOwnerAddress,
          amount: parseFloat(amount).toFixed(0), // this prevents a backend parsing error
          transactionHash: res?.transactionHash || res?.txHash || res?.tx,
          campaignSuccess,
          symbol
        })
      })
      .then(() => {
        setLoading(false)
        toast.success(message)
      })
      .catch((err) => {
        setLoading(false)
        throw err;
      });
    return response;
  }
  return null;
};

export const fsp_getOwnerData = async (contract) => {
  let response = {};

  if (contract) {
    const ownerData = await contract.methods.getOwnerData().call();
    response.ownerData = ownerData;

    return response;
  }
  return null;
};

export const fsp_data = async (contract, decimals) => {
  if (contract) {
    // if blockchain is ok function send respone
    let [
      amountRaised,
      target,
      maxCap,
      paymentTokenAddress
    ] = await Promise.all([
      contract.methods.totalSentPaymentTokens().call(),
      contract.methods.paymentTokenMinSupply().call(),
      contract.methods.paymentTokenMaxSupply().call(),
      contract.methods.paymentTokenAddress().call()
    ]);
    //TODO: in the lne below the  must be changed with the decimal function used, for the set of the right decimals
    const etherValue = weiToAmountFloat(amountRaised, decimals);
    const response = {
      amountRaised: etherValue,
      target: weiToAmountFloat(target, decimals),
      maxCap: weiToAmountFloat(maxCap, decimals),
      paymentTokenAddress
    };

    return response;
  }
  return null;
};
