import React, { useState } from "react";
import "./DropArea.scss";

import { useDropzone } from "react-dropzone";

// Components
// import ErrorModal from "../Modals/ErrorModal/ErrorModal";
import ReactTooltip from "react-tooltip";
import { DropAreaRename } from "./DropAreaRename";

// Icons
import {
  AiFillFilePdf as PdfIcon
} from "react-icons/ai";
import { HiOutlineTrash as TrashButton } from "react-icons/hi";
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";

// Helper functions
import { getFileExtension } from "../../../utils/helperFunctions";
import { useAppContext } from "../../../context/store";
import { toast } from "react-toastify";
import axios from "axios";

const DEFAULT_COLORS = {
  defaultEmpty: {
    border: "none",
    backgroundColor: "#FBFBFB",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CACACAFF' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
};

const DropArea = ({
  areaMessage,
  accept,
  lastFile,
  fileSetter,
  deleteFileFunction,
  fileName,
  colorOR,
  isRenamingSetter,
  maxSize = Infinity,
  allowedExtensions = [],
  withRecycleBinView = false,
  leftIconOR = "",
  imageToPreviewBig,
  label,
  isCarousel,
  maxFiles,
  onError,
  tooltipText,
  returnMultiple,
  viewOnly,
  deletable = true,
  error,
  hasGap
}) => {
  const [currFile, setCurrFile] = useState();
  const [renameFile, setRenameFile] = useState(false);
  const {
    strings: { dropArea, common },
  } = useAppContext();

  // const handleDownloadContentButton = (e) => {
  //   e.stopPropagation();
  //   let fileUrl =
  //     (currFile instanceof File && URL.createObjectURL(currFile)) ||
  //     (currFile instanceof Object && currFile?.link) ||
  //     currFile?.toString() ||
  //     (lastFile instanceof File && URL.createObjectURL(lastFile)) ||
  //     (lastFile instanceof Object && lastFile?.link) ||
  //     lastFile?.toString();

  //   downloadURI(
  //     fileUrl,
  //     fileName || currFile?.name || lastFile?.name || dropArea.newDownload
  //   );
  // };

  const handleNewWindowButton = async (e) => {
    e.stopPropagation();
    
    let fileUrl =
      (currFile instanceof File && URL.createObjectURL(currFile)) ||
      (currFile instanceof Object && currFile?.link) ||
      currFile?.toString() ||
      (lastFile instanceof File && URL.createObjectURL(lastFile)) ||
      (lastFile instanceof Object && lastFile?.link) ||
      lastFile?.toString();

    if (fileUrl?.startsWith("blob:http")) {
      window.open(fileUrl, "_blank")
    } else {
      const config = {
        url: fileUrl,
        method: "GET",
        responseType: "blob",
        headers: {
          'Accept': accept,
          'Authorization': ""
        }
      };
  
      try {
        axios.get(config.url, { ...config }).then((res) => {
          var file = new Blob([res.data], { type: accept });
          var newFileUrl = URL.createObjectURL(file);
          window.open(newFileUrl, "_blank");
        }, (err) => {
          toast.error(err.message);
        })
      } catch (error) {
        if (error?.message) {
          toast.error(common.genericError);
        }
      }
    }
  };

  const handleDeleteButton = (e) => {
    e.stopPropagation();

    deleteFileFunction && deleteFileFunction(lastFile);
  };

  const onDropRejected = (errors) => {
    let finalErrorsArray = [];

    if (!!errors.length) {
      errors.forEach((fileErrors) => {
        finalErrorsArray = [...finalErrorsArray, ...fileErrors?.errors];
      });

      finalErrorsArray = finalErrorsArray.map((e) => e?.code);

      const errsSet = new Set(finalErrorsArray);

      return onError(Array.from(errsSet));
    }

    return onError(errors);
  };

  const onDrop = (acceptedFiles) => {
    if (returnMultiple) {
      fileSetter(acceptedFiles);
      return;
    }

    acceptedFiles.forEach((file) => {
      if (!maxSize || file.size <= maxSize) {
        if (
          !allowedExtensions.length ||
          allowedExtensions.includes(getFileExtension(file.name).toLowerCase())
        ) {
          if (isRenamingSetter) {
            return setRenameFile(file);
          }

          setCurrFile(file);
          fileSetter(file);
        }
      }
    });
  };

  const onChangeFileName = (newFileName) => {
    setCurrFile(new File([renameFile], newFileName));

    fileSetter(new File([renameFile], newFileName));
    setRenameFile(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    maxFiles: maxFiles || 0,
    maxSize,
    disabled: !!viewOnly,
  });

  if (!!renameFile) {
    return (
      <DropAreaRename file={renameFile} onChangeFileName={onChangeFileName} />
    );
  }

  return (
    <>
      <ReactTooltip
        place="right"
        effect="solid"
        backgroundColor="var(--color-primary)"
        textColor="var(--color-white)"
        id="drop-area-tooltip"
      />
      {lastFile ? (
        (imageToPreviewBig && (
          <div
            className={`drop-area__img-view-placer ${
              isCarousel ? "drop-area__img-view-placer--carousel" : ""
            }`}
          >
            <div className={`drop-area__img-view-title font-S-600 ${error ? "drop-area__label-error" : ""}`}>
              {!isCarousel && (fileName || lastFile?.name || label)}
              {tooltipText ? (
                <span className="input-tooltip" data-for="drop-area-tooltip" data-tip={tooltipText}>
                  <InfoIcon />
                </span>
              ) : null}
              {
                deletable &&
                <div
                  tabIndex={0}
                  className="drop-area__img-view-icon-section"
                  onClick={(e) => handleDeleteButton(e)}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") handleDeleteButton(e);
                  }}
                >
                  <TrashButton size={22} color="red" /> Delete
                </div>
              }
            </div>
            <div
              className={`drop-area__car-cont ${
                isCarousel ? "drop-area__car-cont--carousel" : ""
              }`}
            >
              <img
                className="drop-area__car-img"
                src={
                  (imageToPreviewBig &&
                    imageToPreviewBig instanceof File &&
                    URL.createObjectURL(imageToPreviewBig)) ||
                  imageToPreviewBig
                }
                alt="img"
              />
            </div>
            {error ? (
              <span className="drop-area__error">{error}</span>
            ) : null}
          </div>
        )) || (
          <div className={`drop-area__wrapper`} style={{ gap: hasGap ? "24px" : 0 }}>
            {label && (
              <label className="drop-area__label">
                {label}
                {tooltipText ? (
                  <span className="input-tooltip" data-for="drop-area-tooltip" data-tip={tooltipText}>
                    <InfoIcon />
                  </span>
                ) : null}
              </label>
            )}
            <div
              className="drop-area__doc-button"
              {...getRootProps({
                onClick: (event) => {
                  event.stopPropagation();
                  handleNewWindowButton(event);
                },
              })}
            >
              <input {...getInputProps()} />
              {leftIconOR ? (
                <img
                  className="drop-area__img-left"
                  src={
                    (leftIconOR &&
                      leftIconOR instanceof File &&
                      URL.createObjectURL(leftIconOR)) ||
                    leftIconOR
                  }
                  alt="preview"
                />
              ) : 
              undefined
              // (
              //   <DLButton
              //     size={26}
              //     fill="#30b8ae"
              //     onClick={(e) => handleDownloadContentButton(e)}
              //   />
              // )
              }
              <h1 className="drop-area__text-doc-button font-S-600" onClick={(event) => {
                event.stopPropagation();
                handleNewWindowButton(event);
              }}>
                {fileName || label || lastFile.name}
              </h1>
              {deletable && (withRecycleBinView || !viewOnly) ? (
                <TrashButton
                  size={26}
                  style={{
                    color: "#ea3333",
                  }}
                  onClick={(e) => handleDeleteButton(e)}
                />
              ) : (
                <PdfIcon size={32} color="var(--color-primary)" style={{ minWidth: "32px" }} />
              )}
            </div>
            {error ? (
              <span className="drop-area__error">{error}</span>
            ) : null}
          </div>
        )
      ) : (
        !viewOnly &&
        <div className="drop-area__wrapper-drag">
          {label && (
            <label className={`drop-area__label ${error ? "drop-area__label-error" : ""}`}>
              {label}
              {tooltipText ? (
                <span className="input-tooltip" data-for="drop-area-tooltip" data-tip={tooltipText}>
                  <InfoIcon />
                </span>
              ) : null}
            </label>
          )}
          <div
            className={`drop-area${viewOnly ? " drop-area__view-only" : ""}`}
            {...getRootProps()}
            style={colorOR || DEFAULT_COLORS.defaultEmpty}
          >
            <input {...getInputProps()} />

            <div className="drop-area__placeholder">
              <div className="drop-area__subcontainer">
                {areaMessage}
              </div>
              <div>
                {dropArea.dragOr}
                <div className={`drop-area__selection${viewOnly ? " drop-area__view-only" : ""}`}>{dropArea.select}</div>
              </div>
            </div>
          </div>
          {error ? (
            <span className="drop-area__error">{error}</span>
          ) : null}
        </div>
      )}
    </>
  );
};

export default DropArea;
