import React from "react";
import "./Modal.scss";

import { GrClose } from "react-icons/gr";

const Modal = ({ children, className, fitContent, onClose }) => {
  return (
    <div className="main-modal-overlay">
      <div className={`main-modal ${className || ""}`} style={{ maxWidth: "unset", width: "unset" }}>
        {onClose && (
          <div className="main-modal__close-icon">
            <GrClose onClick={onClose} fontSize="24" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
