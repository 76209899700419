import React from "react";
import "./LiveCard.scss";

import {useAppContext} from "../../context/store";
import {AiOutlineFileImage, AiOutlineInfoCircle as Hint} from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import {DurationSpan} from '../../v2/DurationSpan';

const LiveCard = ({ data }) => {
    const {
        config,
        strings: { liveCard },
    } = useAppContext();

    const numberFormat = new Intl.NumberFormat("en-EN")

    return (
        <div className="live-card" style={{ marginTop: "8px" }}>
            <ReactTooltip
                place="right"
                backgroundColor="var(--color-primary)"
                textColor="var(--color-white)"
                effect="solid"
                id="summary-preview-tooltip"
            />
            {
                data?.image &&
                <img className="live-card__image" height="140" src={data?.image} alt="" />
            }
            {
                !data?.image &&
                <div className="live-card__placeholder">
                    <span>{liveCard.noImage}</span>
                    <AiOutlineFileImage size={36} />
                </div>
            }

            <div className="live-card__text-div">
                <div className="live-card__title">{data?.name ?? ""}</div>
                <div className="live-card__text">{data?.intro ?? ""}</div>
            </div>

            <div className="live-card__row">
                <div className="live-card__label">
                    {liveCard.label}
                </div>
                <div className="live-card__content">
                    {numberFormat.format(data?.target).replaceAll(",", config.general.decimalSeparator)} {data?.paymentToken ?? "???"}
                </div>
            </div>

            <div className="live-card__row">
                <div className="live-card__label">
                    {liveCard.maximum}
                </div>
                <div className="live-card__content">
                    {numberFormat.format(data?.maxCap).replaceAll(",", config.general.decimalSeparator)} {data?.paymentToken ?? "???"}
                </div>
            </div>
            {/* <div className="live-card__row-bar">
                <ProgressBar
                    completed={data?.raisedPercentage}
                    height={"8px"}
                    bgColor={"#2EC6BA"}
                    baseBgColor={"#EFF0F7"}
                    isLabelVisible={false}
                    borderRadius={"6px"}
                    // width={"100%"}
                />
            </div> */}
            <div className="live-card__row">
                <div className="live-card__label">
                    {liveCard.durationBlock}
                    <div className="live-card__hinter">
                        <Hint data-for="summary-preview-tooltip" data-tip={liveCard.durationBlockTooltip} />
                    </div>
                </div>
                <div className="live-card__content">
                    {numberFormat.format(data?.durationBlock).replaceAll(",", config.general.decimalSeparator)}
                    <div>
                        &nbsp;({liveCard.estimated} <DurationSpan blocks={data?.durationBlock} />)
                    </div>
                </div>
            </div>
            {/* <div className="live-card__row-bar">
                <ProgressBar
                    completed={data?.blocksPercentage}
                    height={"8px"}
                    bgColor={"#2EC6BA"}
                    baseBgColor={"#EFF0F7"}
                    isLabelVisible={false}
                    borderRadius={"6px"}
                    // width={progressBarLength}
                />
            </div> */}
        </div>
    );
};

export default LiveCard;



