import React, { useState, useEffect, useMemo } from "react";
import "./Application.scss";
import { toast } from "react-toastify";

import { apiCall } from "../../../api";

import { useForm } from "react-hook-form";

// Components
import Input from "../../common/Input/Input";
import Spinner from "../../common/spinner/Spinner";
import { useAppContext } from "../../../context/store";
import Button from "../../common/button/Button";
import DropArea from "../../common/dropArea/DropArea";
import { checkTokenIntegersAndDecimals, trimSpaces, urlToObject } from "../../../utils/helperFunctions";
import { SET_APPLICATIONS } from "../../../context/web3Reducer";
import { useWeb3Context } from "../../../context/web3Store";
import { labelToValue, valueToLabel } from "../general/General";
import Select from "../../common/select/Select";

const Application = ({ applicationData, onSave }) => {
  const {
    appDispatch,
    isLoading,
    status,
    config,
    strings
  } = useAppContext();
  const { web3Dispatch } = useWeb3Context();

  const { application, dropArea, documents, common } = strings

  const {
    fileUploadConstants: { BUSINESS_PLAN, ELEVATOR_PITCH }
  } = config;

  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    setValue,
    
    formState: { errors, isSubmitted },
  } = useForm({ defaultValues: applicationData });
  const watchToken = watch("paymentToken");
  const watchBlockchain = watch("blockchain");

  // const [blockchain, setBlockchain] = useState(applicationData?.blockchain);
  const [missingFields, setMissingFields] = useState([])
  const [elevatorPitch, setElevatorPitch] = useState(applicationData?.elevatorPitch);
  const [businessPlan, setBusinessPlan] = useState(applicationData?.businessPlan);

  // const getTokenList = (tokens) => {
  //   if (tokens && !!tokens.length) {
  //     return tokens?.map((token) => token?.symbol);
  //   }
  // };

  const currentToken = useMemo(() => config.tokens.find((token) => token.symbol === watchToken || token.name === watchToken), [config, watchToken])

  const getTokenBlockChainInfo = (token, blockchain) => {
    const isNative = config.tokens.find((_token) => _token.name === token)?.nativeBlockchain !== blockchain
    if (isNative) {
      return config?.tokens
      .find((_token) => _token?.symbol === token)
      ?.blockchains.find((chain) => chain?.name === blockchain);
    } else {
      return null
    }
  };

  const onSubmit = handleSubmit(async (values) => {
    const { name, target, paymentToken, email } = values;

    const sendDataObj = {
      name,
      target,
      paymentToken,
      email,
    };

    const formData = new FormData();

    Object.keys(sendDataObj).forEach((key) =>
      formData.append(key, trimSpaces(sendDataObj[key]))
    );

    if(elevatorPitch === null || elevatorPitch === undefined){
      return
    }

    try {
      if (elevatorPitch instanceof File) {
        formData.append("elevatorPitch", elevatorPitch);
      } else {
        const file = await urlToObject(elevatorPitch, "elevatorPitch.pdf");
        formData.append("elevatorPitch", file);
      }
    } catch (error) {

    }

    if (businessPlan && businessPlan instanceof File) {
      formData.append("businessPlan", businessPlan);
    } else {
      formData.delete("businessPlan");
    }

    if (watchBlockchain) {
      formData.append("blockchain", watchBlockchain);
    }

    const applicationsConfig = {
      url: "fundraising/applications",
      method: "put",
      data: formData,
    };

    try {
      const { data } = await apiCall(applicationsConfig, appDispatch);
      onSave(0, data);
      web3Dispatch({ type: SET_APPLICATIONS, payload: data });
      toast.success(common.changedData);
    } catch (error) {

    }
  });

  // Reset default values when update form data
  useEffect(() => {
    reset(applicationData);
    setElevatorPitch(applicationData?.elevatorPitch);
    setBusinessPlan(applicationData?.businessPlan);
  }, [applicationData]);

  // useEffect(() => {
  //   if (applicationData?.blockchain) {
  //     setBlockchain(applicationData?.blockchain);
  //   }
  // }, [applicationData?.blockchain]);

  // useEffect(() => {
  //   if (watchBlockchain !== applicationData?.blockchain) {
  //     setValue("target", 0);
  //   }

  //   if (watchBlockchain === "ethereum") {
  //     setValue(
  //       "paymentToken",
  //       localConfigs.defaultParameters.ethereumDefaultToken
  //     );
  //   } else if (watchBlockchain === "polygon") {
  //     setValue(
  //       "paymentToken",
  //       localConfigs.defaultParameters.polygonDefaultToken
  //     );
  //   }
  // }, [watchBlockchain]);

  function isRequired(field) {
    return missingFields.includes(field) ? common.requiredDeployField : undefined
  }

  useEffect(() => {
    setMissingFields(status?.missingFields?.filter((field) => field?.startsWith("application")).map((field) => field?.split(".")[1]) ?? [])
  }, [status])

  if (isLoading)
    return (
      <Spinner
        wrapperStyle={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );

  return (
    <>
      <form className="application__form" onSubmit={onSubmit}>
        <Input
          label={application.projectLabel}
          placeholder={application.projectPlaceholder}
          {...register("name", {
            required: application.required,
            value: 30,
            message: strings.formatString(common.valueTooLong, {
                maxCharactersAllowed: 30
            })
          })}
          aria-invalid={!!errors.name}
          error={errors?.name}
          // warning={isRequired("name")}
          readOnly={config.applicationReadOnly}
          tooltipText={application.projectTooltip}
        />
        <Input
          label={application.emailLabel}
          placeholder={application.emailPlaceholder}
          {...register("email", {
            required: application.required,
            maxLength: {
              value: 100,
              message: strings.formatString(common.valueTooLong, {
                  maxCharactersAllowed: 100
              })
            },
            pattern: {
              value: new RegExp(config.regex.validEmail),
              message: common.invalidEmail,
            },
          })}
          aria-invalid={!!errors.email}
          error={errors?.email}
          // warning={isRequired("email")}
          readOnly={config.applicationReadOnly}
          tooltipText={application.emailTooltip}
        />
        {watchBlockchain && (
          <div className="application__form-select">
            <Select
              label={application.blockchainLabel}
              options={config?.blockchains.filter((blockchain) => blockchain.netType === "mainnet").map((blockchain) => blockchain?.placeholder) || []}
              value={valueToLabel(watchBlockchain, config)}
              readOnly={config.applicationReadOnly}
              onChange={(e) => {
                setValue("blockchain", labelToValue(e, config));
                setValue("target", 0);
                setValue("paymentToken", config.defaultParameters.defaultToken[labelToValue(e, config)]);
              }}
              tooltipText={application.blockchainTooltip}
              autocomplete="off"
            />
          </div>
        )}
        <Input
          placeholder={application.targetAmountPlaceholder}
          label={application.targetAmountLabel}
          min={0}
          tokenProps={{
            options: config?.blockchains.find((bc) => bc.name === watchBlockchain)?.availablesToken ?? ["USDT"],
            readOnly: config.applicationReadOnly,
            control,
            name: "paymentToken",
            onTokenChange: () => {
              setValue("target", 0);
            },
          }}
          type="number"
          {...register("target", {
            required: application.required,
            min: { value: 1, message: application.minTargetAmount },
            validate: (value) => checkTokenIntegersAndDecimals(value ?? "0", currentToken?.integerMax ?? 10, currentToken?.decimalMax ?? 6, strings)
          })}
          aria-invalid={!!errors.target}
          error={errors?.target}
          // warning={isRequired("target")}
          readOnly={config.applicationReadOnly}
          tooltipText={application.targetAmountTooltip}
        />
        <Input
          label={application.contractAddressLabel}
          value={getTokenBlockChainInfo(watchToken, watchBlockchain)?.address ?? application.nativeCryptocurrency}
          isURL = {getTokenBlockChainInfo(watchToken, watchBlockchain)?.address}
          onClick={() => {
            if (config.tokens.find((token) => token.name === watchToken)?.nativeBlockchain !== watchBlockchain) {
              const completeURL = getTokenBlockChainInfo(watchToken, watchBlockchain)?.blockExplorer +getTokenBlockChainInfo(watchToken, watchBlockchain)?.address;
              window.open(completeURL, "_blank");
            }
          }}
          readOnly
          tooltipText={application.contractAddressTooltip}
        />
        <div className="application__form-buttons">
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "auto"
            }}
          >
            <DropArea
              label={application.elevatorPitch}
              areaMessage={documents.pdf.fileAndFormat}
              fileName="ElevatorPitch.pdf"
              accept={ELEVATOR_PITCH?.allowedExtensions}
              lastFile={elevatorPitch}
              fileSetter={setElevatorPitch}
              deleteFileFunction={() => {
                setElevatorPitch(null);
              }}
              withRecycleBinView
              maxFiles={1}
              maxSize={ELEVATOR_PITCH?.sizeInByte}
              onError={(errors) => {
                errors.forEach((error) => {
                  if (error === "too-many-files") {
                    toast.error(dropArea.errors.onlyOne);
                  } else if (error === "file-too-large") {
                    toast.error(dropArea.errors.tooBig);
                  }
                });
              }}
              error={!elevatorPitch && isSubmitted ? application.elevatorPitchError : undefined}
              viewOnly={config.applicationReadOnly}
              tooltipText={application.elevatorPitchTooltip}
            />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "auto"
            }}
          >
            <DropArea
              label={application.businessPlan}
              areaMessage={documents.pdf.fileAndFormat}
              fileName="BusinessPlan.pdf"
              accept={BUSINESS_PLAN?.allowedExtensions}
              lastFile={businessPlan}
              fileSetter={setBusinessPlan}
              deleteFileFunction={() => {
                setBusinessPlan(null);
              }}
              withRecycleBinView
              maxFiles={1}
              maxSize={BUSINESS_PLAN?.sizeInByte}
              onError={(errors) => {
                errors.forEach((error) => {
                  if (error === "too-many-files") {
                    toast.error(dropArea.errors.onlyOne);
                  } else if (error === "file-too-large") {
                    toast.error(dropArea.errors.tooBig);
                  }
                });
              }}
              viewOnly={config.applicationReadOnly}
              tooltipText={application.businessPlanTooltip}
            />
          </div>
        </div>
        <Button type="submit">{application.save}</Button>
      </form>
    </>
  );
};

export default Application;
