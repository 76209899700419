import React from "react";
import "./Button.scss";
import { FiLoader } from "react-icons/fi";

const Button = (props) => {
  const { loading, className, value, children, style, ...rest } = props;

  return (
    <button
      tabIndex={0}
      {...rest}
      style={style ?? {}}
      className={`button${className ? " " + className : ""}`}
      value={loading ? "loading" : value}
    >
      {loading ? <FiLoader className="button__loading" /> : children}
    </button>
  );
};

export default Button;
