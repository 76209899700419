import React, { useState, useEffect, useRef } from "react";
import "./DropAreaRename.scss";

import {
  AiFillFilePdf as PdfIcon,
  AiOutlineCheck as CheckIcon,
} from "react-icons/ai";

import Input from "../Input/Input";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/store";

export const DropAreaRename = ({ file, onChangeFileName }) => {
  const ref = useRef();

  const {
    strings: { dropArea },
  } = useAppContext();
  const [newFileName, setNewFileName] = useState(file?.name);
  const [fileNotSaved, setFileNotSaved] = useState(false);
  const errors = useRef(0);

  const handleRename = () => {
    let fileNameWithFormat = newFileName;

    if (fileNotSaved) {
      return;
    }

    if (file?.type === "application/pdf") {
      fileNameWithFormat = fileNameWithFormat.includes(".pdf")
        ? fileNameWithFormat
        : fileNameWithFormat + ".pdf";
    }

    if (
      fileNameWithFormat.length > 0 &&
      fileNameWithFormat.match(/^[a-z A-Z0-9_]+.(pdf|png|jpg|jpeg)$/gim)
    ) {
      onChangeFileName(fileNameWithFormat);
    } else {
      toast.error(dropArea.renameNotValid);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref?.current && !ref.current.contains(event.target)) {
        if (errors.current === 0) {
          setFileNotSaved(true);
          errors.current = 1;
          toast.error(
            "Attention: save your renamed document pressing Enter or clicking the check icon before saving all your documents."
          );
        }
      } else {
        setFileNotSaved(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="drop-area-rename" ref={ref}>
      <span className="drop-area-rename__title font-S-600">Rename</span>
      <div className="drop-area-rename__text font-XS-400">
        {dropArea.renameProperName}
      </div>
      <div className="drop-area-rename__input-container">
        <PdfIcon
          size={24}
          color={"var(--color-primary)"}
          className={"drop-area-rename__pdf-container"}
        />
        <Input
          placeholder={dropArea.renamePlaceholder}
          value={newFileName}
          onChange={(e) => {
            setNewFileName(e.target.value);
          }}
          minLength={4}
          autoFocus
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              handleRename();
            }
          }}
        />

        <CheckIcon
          className="drop-area-rename__check-container"
          color={"var(--color-primary)"}
          onClick={handleRename}
          size={24}
        />
      </div>
    </div>
  );
};
