import React from "react";
import "./Sponsor.scss";

import { useAppContext } from "../../../context/store";

// Components
import Spinner from "../../common/spinner/Spinner";

// import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";
// import ReactTooltip from "react-tooltip";

const Sponsor = ({ data }) => {
    const { isLoading, strings } = useAppContext();

    const { detail: { sponsor } } = strings

    if (isLoading) {
        return (
            <Spinner
                wrapperStyle={{
                    height: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            />
        );
    }

    return (
        <div className="detail-sponsor">
            {/* <ReactTooltip
                place="top"
                backgroundColor="var(--color-primary)"
                textColor="var(--color-white)"
                effect="solid"
                id="sponsor-detail-tooltip"
            /> */}
            <div className="detail-sponsor__title">{sponsor?.title}</div>
            <div className="detail-sponsor__block">
                <div className="detail-sponsor__subtitle">{sponsor?.generalRole}</div>
                <div className="detail-sponsor__description">{sponsor?.generalRoleDescription}</div>
            </div>
            <div className="detail-sponsor__block-inline">
                <div className="detail-sponsor__subtitle">{sponsor?.name}</div>
                <div className="detail-sponsor__description">{data?.sponsorFullName}</div>
            </div>
            <div className="detail-sponsor__block-inline">
                <div className="detail-sponsor__subtitle">{sponsor?.publicInfo}</div>
                <div className="detail-sponsor__description"><a href={data?.sponsorUrl} target="_blank" rel="noreferrer">Link</a></div>
            </div>
            {/* <div className="detail-sponsor__block-inline">
                <div className="detail-sponsor__subtitle">
                    {sponsor?.sponsorCommittedAmount}
                    <div className="label-tooltip" data-for="sponsor-detail-tooltip" data-tip={sponsor?.sponsorCommittedAmountTooltip}>
                        <InfoIcon />
                    </div>    
                </div>
                <div className="detail-sponsor__description">{(data?.target ?? 0) / 2} {data?.paymentToken}</div>
            </div> */}
        </div>
    );
};

export default Sponsor;
