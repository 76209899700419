import React from "react";
import "./Block.scss";

const Block = (props) => {
  const { title, icon, children, className, style, contentStyle, noBorder, titleStyle } = props;

  return (
    <div className={`block ${className}`} style={style}>
      <div className="block__title">
        <span className="block__title-icon">
          {icon && <img src={icon} alt={"icon"} />}
        </span>
        <span className="block__title-text font-XL-700" style={titleStyle}>{title}</span>
      </div>
      <div className={`block__content S-600${noBorder ? " block__no-border" : ""}`} style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default Block;
