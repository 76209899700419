import React, { useEffect } from "react";
import "./LoginModal.scss";
import image_logo from "../../../assets/svg/logo.svg";

// Components
import LoginForm from "../loginForm/LoginForm";
import { useAppContext } from "../../../context/store";

const LoginModal = () => {
  const {
    strings: { login },
  } = useAppContext();

  useEffect(() => {
    const { body } = document;
    body.style.overflowY = "hidden";

    return () => (body.style.overflowY = "initial");
  }, []);

  return (
    <div className="login-modal-overlay">
      <div className="login-modal">
        <div className="login-title">
          <h1 className="login-title__main">{login.title}</h1>
          <h2 className="login-title__subtitle">{login.subTitle}</h2>
        </div>

        <p className="login-modal__info-text" style={{ marginBottom: "0" }}>{login.description}</p>
        <div className="login-modal__logo" style={{ marginTop: "0" }}>
          {login.poweredBy}
          <a href="https://www.seedventure.io/" target={"_blank"} rel="noreferrer">
            <div>
              <img
                src={image_logo}
                alt="Seed Venture Logo"
                style={{ width: "100%", height: 64, objectFit: "contain" }}
              />
            </div>
          </a>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginModal;
