import React, { useEffect } from "react";
import "./InfoWalletModal.scss";

import Button from "../common/button/Button";
import { GrClose as CloseIcon } from "react-icons/gr";
import { useWeb3Context } from "../../context/web3Store";
import { getEtherscanAddressURL } from "../../utils/helperFunctions";

import { useAppContext } from "../../context/store";
import { setWalletAddress } from "../../storage";
import { REMOVE_WALLET_ADDRESS, RESET_STEPS } from "../../context/web3Reducer";

const InfoWalletModal = ({ setShowInfoWalletModal }) => {
  const { walletAddress, web3Dispatch } = useWeb3Context();
  const {
    strings: { connectWallet },
  } = useAppContext();

  const handleDisconnect = async () => {
    web3Dispatch({ type: REMOVE_WALLET_ADDRESS });
    web3Dispatch({ type: RESET_STEPS });
    setWalletAddress(null);
  };

  useEffect(() => {
    if (!walletAddress) {
      setShowInfoWalletModal(false);
    }
  }, [walletAddress]);

  return (
    <div className="info-wallet-modal-overlay">
      <div className="info-wallet-modal">
        <CloseIcon
          fontSize={24}
          onClick={() => {
            setShowInfoWalletModal(false);
          }}
          className="connect-wallet-modal__close"
        />
        <h3 className="info-wallet-modal__title font-XL-700">
          {connectWallet.wallet}
        </h3>
        <div className="info-wallet-modal__address-container">
          <span className="info-wallet-modal__address-container-title font-M-400">
            {connectWallet.address}
          </span>
          <span
            className="info-wallet-modal__address-container-content"
            onClick={() => {
              window
                .open(getEtherscanAddressURL(walletAddress), "_blank")
                .focus();
            }}
          >
            {walletAddress}
          </span>
        </div>
        <Button
          onClick={handleDisconnect}
          style={{
            marginTop: "var(--dim-24)",
            background: "#fff3f8",
            border: "1px solid #ec2121",
            color: "#ec2121",
          }}
        >
          {connectWallet.disconnectWallet}
        </Button>
      </div>
    </div>
  );
};

export default InfoWalletModal;
