import React, { useState, useEffect } from "react";
import "./Tags.scss";

import { useAppContext } from "../../../context/store";
import { toast } from "react-toastify";

// Components
import Input from "../../common/Input/Input";
import Tag from "../../tag/Tag";
import Spinner from "../../common/spinner/Spinner";

// Services
import {
  postTag,
  getTagsService,
  deleteTag,
} from "../../../services/tagsServices";

const Tags = ({ tagsData, onSave }) => {
  const { isLoading, appDispatch, strings } = useAppContext();
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState(tagsData?.tags);
  const [isTagOnError, setIsTagOnError] = useState(false);

  const { tags: tagsStr, common } = strings;

  const handleDeleteTags = async (tag) => {
    try {
      await deleteTag(tag.id, appDispatch);
      const { data } = await getTagsService(appDispatch);

      onSave(6, data);
      setTag("");
      toast.success(tagsStr.deletedTag, { autoClose: 200 });
    } catch (error) {

    }
  };

  const handleAddTag = async (e) => {
    if (e.code === "Enter") {
      if (e.target.value.length < 2) {
        return setIsTagOnError(tagsStr.tagsErrorLength);
      }

      if (
        tags
          .map((tag) => tag.tag?.toLowerCase())
          .includes(e.target.value.toLowerCase())
      ) {
        return setIsTagOnError(tagsStr.tagsAlreadyExists);
      } else {
        setIsTagOnError(false);

        try {
          await postTag(e.target.value, appDispatch);
          const { data } = await getTagsService(appDispatch);
          onSave(6, data);
          setTag("");
          toast.success(tagsStr.newTag, { autoClose: 200 });
        } catch (error) {

        }
      }
    }
  };

  useEffect(() => {
    setTags(tagsData?.tags);
  }, [tagsData]);

  if (isLoading) {
    return (
      <Spinner
        wrapperStyle={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="tags">
      <Input
        value={tag}
        placeholder={tagsStr.tagsPlaceholder}
        label={strings.formatString(tagsStr.tagsLabel, {
          Enter: (
            <strong style={{ color: "var(--color-primary)" }}>Enter</strong>
          ),
        })}
        onKeyDown={handleAddTag}
        onChange={(e) => {
          setTag(e.target.value);
        }}
        autoFocus
        aria-invalid={!!isTagOnError}
        error={isTagOnError ? { message: isTagOnError } : undefined}
        tooltipText={tagsStr.tagsTooltip}
      />
      <div className="tags-container">
        {tags.map((tag) => (
          <Tag key={tag?.id} onDelete={handleDeleteTags} tag={tag} />
        ))}
      </div>
    </div>
  );
};

export default Tags;
