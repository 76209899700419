import { apiCall } from "../api";

export const getFundraiseDataService = async (
  step,
  appDispatch,
  newData,
  ...states
) => {
  let mapStates = [
    {
      name: 0,
      state: states[0],
    },
    {
      name: 1,
      state: states[1],
    },
    {
      name: 2,
      state: states[2],
    },
    {
      name: 3,
      state: states[3],
    },
    {
      name: 4,
      state: states[4],
    },
    {
      name: 5,
      state: states[5],
    },
    {
      name: 6,
      state: states[6],
    },
  ];

  const callState = mapStates.find((mapState) => {
    if (mapState.name === step && mapState.state) {
      return mapState.state;
    }
    return false;
  });

  if (callState?.state && !newData) return { data: callState?.state };

  if (newData) return newData;

  const handleURL = () => {
    if (step === 0) return "applications";
    if (step === 1) return "general";
    if (step === 2) return "idea";
    if (step === 3) return "media";
    if (step === 4) return "teams";
    if (step === 5) return "documents";
    if (step === 6) return "tags";
    return step;
  };

  const config = {
    url: `/fundraising/${handleURL()}`,
    method: "get",
  };

  return apiCall(config, appDispatch, true);
};

export const deleteGeneralLogoImage = async (appDispatch) => {
  const config = {
    method: "delete",
    url: "/fundraising/general/logo-image",
  };

  try {
    await apiCall(config, appDispatch);
  } catch (error) {

  }
};

export const updateCarouselImagesService = async (
  imagesToDelete,
  imagesToPost,
  setImagesToDelete,
  setImagesToPost,
  appDispatch
) => {
  const postImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    const config = {
      method: "post",
      url: `/fundraising/media/image`,
      data: formData,
    };

    return await apiCall(config, appDispatch);
  };

  const deleteImage = async (id) => {
    const config = {
      method: "delete",
      url: `/fundraising/media/image/${id}`,
    };

    return await apiCall(config, appDispatch);
  };

  const postAllImages = async () => {
    return Promise.all(imagesToPost.map((image) => postImage(image)));
  };

  const deleteAllImages = async () => {
    return Promise.all(imagesToDelete.map((id) => deleteImage(id)));
  };

  try {
    const postData = await postAllImages();
    const deleteData = await deleteAllImages();

    setImagesToPost([]);
    setImagesToDelete([]);

    if (
      (!!postData?.length && postData.length > 0) ||
      (!!deleteData?.length && deleteData.length > 0)
    ) {
      const config = { url: "/fundraising/media", method: "get" };
      const { data } = await apiCall(config, appDispatch);
      return data;
    }
  } catch (error) {
    throw new Error(error);
  }
};
