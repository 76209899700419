const USER_TOKEN = "authToken";
const REFRESH_TOKEN = "refreshToken";
const USER_LOGIN_DATA = "USER_LOGIN_DATA";
const WALLET_ADDRESS = "walletAddress";

export const setUserLoginData = (user) => {
  if (user) {
    return localStorage.setItem(USER_LOGIN_DATA, JSON.stringify(user));
  }
  localStorage.setItem(USER_LOGIN_DATA, JSON.stringify({}));
};

export const getUserLoginData = () => {
  try {
    const userData = JSON.parse(localStorage.getItem(USER_LOGIN_DATA));
    return userData;
  } catch (error) {
    return {};
  }
};

// User Token
export const setTokensStorage = (token, refreshToken) => {
  localStorage.setItem(USER_TOKEN, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getAuthTokenStorage = () => {
  return localStorage.getItem(USER_TOKEN);
};

export const getRefreshTokenStorage = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const resetStorage = () => {
  localStorage.removeItem(USER_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(USER_LOGIN_DATA);
  localStorage.removeItem(WALLET_ADDRESS);
};

export const getWalletAddress = () => {
  return localStorage.getItem(WALLET_ADDRESS);
};

export const setWalletAddress = (address) => {
  if (address === null) {
    return localStorage.removeItem(WALLET_ADDRESS);
  }
  localStorage.setItem(WALLET_ADDRESS, address);
};
