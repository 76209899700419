import React from "react";
import "./Footer.scss";

import NavbarLinkButton from "../navbar/navbarLinkButton/NavbarLinkButton";
import {useAppContext} from "../../context/store";

const Footer = () => {
  const {
    config,
    strings
  } = useAppContext();

  const { navigation } = strings

  return (
    <div className="footer">
      <div className="footer__border-top"></div>
      <div className="footer__container">
        <div className="footer__section">
          <h3 className="footer__section-title font-XS-700">
            {navigation.seedVenture}
          </h3>
          <div className="footer__section-links">
            <NavbarLinkButton href='' isFooterLink>
              {navigation.policy}
            </NavbarLinkButton>
            <NavbarLinkButton href='' isFooterLink>
              {navigation.privacy}
            </NavbarLinkButton>
          </div>
        </div>
        <div className="footer__section">
          <h3 className="footer__section-title font-XS-700">
            {navigation.navigation}
          </h3>
          <div className="footer__section-links">
            <NavbarLinkButton to={"/"} isFooterLink>
              {navigation.dashboard}
            </NavbarLinkButton>
            <NavbarLinkButton to={"/faq"} isFooterLink>
              {navigation.faq}
            </NavbarLinkButton>
          </div>
        </div>
        <div className="footer__section">
          <h3 className="footer__section-title font-XS-700">
            {navigation.support}
          </h3>
          <div className="footer__section-links">
            <NavbarLinkButton
              href={config?.URLs?.telegram}
              isFooterLink
              target="_blank"
            >
              {navigation.telegram}
            </NavbarLinkButton>
            <NavbarLinkButton
              target="_blank"
              isFooterLink
              href={config?.URLs?.discord}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "var(--dim-12)",
                }}
              >
                {navigation.discord}
              </span>
            </NavbarLinkButton>
          </div>
        </div>
      </div>
      <p className="footer__version">{strings.formatString(navigation.version, { version: process.env.REACT_APP_VERSION })}</p>
    </div>
  );
};

export default Footer;
