import React, { useEffect, useState, useCallback } from "react";
import "./FundraisePage.scss";
import { useAppContext } from "../../context/store";

// Images
import { stepImages } from "../../utils/stepsInfo";
// Components
import { Navbar, Spinner, WizardNav, Wrapper } from "../../components";

// Services
import { getFundraiseDataService } from "../../services/fundraiseServices";

// Wizard sections
import {
  Application,
  General,
  Idea,
  Media,
  Team,
  Documentation,
  Tags,
} from "../../components/fundraise";
import { stepNames } from "../../utils/stepsInfo";
import { SET_STATUS } from "../../context/reducer";
import { getStatusService } from "../../services/dashboardServices";
import { toast } from "react-toastify";
import { GrClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import { useWeb3Context } from "../../context/web3Store";
import { SET_DEPLOY_CONTRACTS } from "../../context/web3Reducer";

const FundraisePage = () => {
  const {
    appDispatch,
    config,
    isLogged,
    strings: { fundraise, common },
  } = useAppContext();
  const history = useHistory()
  const { web3Dispatch } = useWeb3Context();

  const [stepWizard, setStepWizard] = useState(0);
  const [applicationData, setApplicationData] = useState(null);
  const [generalData, setGeneralData] = useState(null);
  const [ideaData, setIdeaData] = useState(null);
  const [mediaData, setMediaData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [documentationData, setDocumentationData] = useState(null);
  const [tagsData, setTagsData] = useState(null);
  const [saved, setSaved] = useState(false);

  const handleSetState = (step, data) => {
    switch (step) {
      case 0:
        setApplicationData(data);
        break;
      case 1:
        setGeneralData(data);
        break;
      case 2:
        setIdeaData(data);
        break;
      case 3:
        setMediaData(data);
        break;
      case 4:
        setTeamData(data);
        break;
      case 5:
        setDocumentationData(data);
        break;
      case 6:
        setTagsData(data);
        break;
      default:
        break;
    }

    setStepWizard(step);
  };

  const onChangeStep = useCallback(
    async (step, newData) => {
      try {
        const { data } = await getFundraiseDataService(
          step,
          appDispatch,
          newData,
          applicationData,
          generalData,
          ideaData,
          mediaData,
          teamData,
          documentationData,
          tagsData
        );

        handleSetState(step, data);
      } catch (error) {

        handleSetState(step, error);
      }
    },
    [
      appDispatch,
      applicationData,
      generalData,
      ideaData,
      mediaData,
      teamData,
      documentationData,
      tagsData,
    ]
  );

  // return step based on the current stepWizard state
  const getCurrentStep = () => {
    switch (stepWizard) {
      case 0:
        return {
          title: fundraise.application,
          description: fundraise.applicationDesc,
          component: () => (
            <Application
              applicationData={applicationData}
              onSave={saveStep}
              config={config}
            />
          ),
        };
      case 1:
        return {
          title: fundraise.general,
          description: fundraise.generalDesc,
          component: () => (
            <General
              generalData={generalData}
              applicationsData={applicationData}
              onSave={saveStep}
              config={config}
            />
          ),
        };
      case 2:
        return {
          title: fundraise.idea,
          description: fundraise.ideaDesc,
          component: () => (
            <Idea ideaData={ideaData} onSave={saveStep} config={config} />
          ),
        };
      case 3:
        return {
          title: fundraise.media,
          description: fundraise.mediaDesc,
          component: () => (
            <Media mediaData={mediaData} onSave={saveStep} config={config} />
          ),
        };
      case 4:
        return {
          title: fundraise.team,
          description: fundraise.teamDesc,
          component: () => (
            <Team teamData={teamData} onSave={saveStep} config={config} />
          ),
        };
      case 5:
        return {
          title: fundraise.documentation,
          description: fundraise.documentationDesc,
          component: () => (
            <Documentation
              documentationData={documentationData}
              onSave={saveStep}
              config={config}
            />
          ),
        };
      case 6:
        return {
          title: fundraise.tags,
          description: fundraise.tagsDesc,
          component: () => (
            <Tags tagsData={tagsData} onSave={saveStep} config={config} />
          ),
        };
      default:
        return {
          title: "no title",
          description: stepWizard,
          component: () => <div></div>,
        };
    }
  };

  // Save state of the current step, updating the view with the new state
  const saveStep = (step, newData) => {
    handleSetState(step, newData);
    getStatus();
    setSaved(true);
  };

  useEffect(() => {
    onChangeStep(stepWizard);
  }, [stepWizard]);

  const getStatus = async () => {
    try {
      const { data } = await getStatusService(appDispatch);

      appDispatch({ type: SET_STATUS, payload: data });
      web3Dispatch({ type: SET_DEPLOY_CONTRACTS, payload: data?.contracts ?? [] })
    } catch (error) {

    }
  };

  useEffect(() => {
    if (isLogged) {
      getStatus();
    }
  }, [isLogged]);

  return (
    <>
      <Navbar />
      <div className="fundraise-img-section">
        {stepImages.find((image) => image.step === stepWizard)?.image ? (
          <img
            src={stepImages.find((image) => image.step === stepWizard)?.image}
            alt={fundraise.backgroundAlt}
          />
        ) : (
          <span className="font-XL-700">{fundraise.noImage}</span>
        )}
      </div>
      <Wrapper>
        <h2 className="wizard__title font-XL-700 col-primary">
          {getCurrentStep()?.title}
        </h2>
        <p className="wizard__description font-M-400">
          {getCurrentStep()?.description}
        </p>
        <WizardNav
          stepNames={stepNames}
          stepWizard={stepWizard}
          setStepWizard={(e) => {
            onChangeStep(e);
          }}
        />

        {config ? (
          getCurrentStep()?.component()
        ) : (
          <Spinner
            wrapperStyle={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        )}
      </Wrapper>
      <div className={`redirect-popup ${saved ? "show" : "hide"}`}>
        <div className="redirect-popup__title">
          {fundraise.saveCompleted}
          <div className="popup-close-icon">
            <GrClose onClick={() => setSaved(false)} fontSize="16" color="white" />
          </div>
        </div>
        <div className="redirect-popup__description" onClick={() => {
          window.scrollTo({ top: 0, left: 0 })
          history.push("/dashboard")
        }}>
          {fundraise.returnToDashboard}
        </div>
      </div>
    </>
  );
};

export default FundraisePage;
