import React from "react";
import "./Idea.scss";

import { useAppContext } from "../../../context/store";

// Components
import Spinner from "../../common/spinner/Spinner";

const Idea = ({ data }) => {
   
    const { isLoading, strings, config } = useAppContext();

    const { detail: { idea } } = strings

    function getText(text) {
        return text
    }

    if (isLoading) {
        return (
            <Spinner
                wrapperStyle={{
                    height: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            />
        );
    }

    return (
        <div className="detail-idea">
            <div className="detail-idea__block">
                <div className="detail-idea__subtitle">{idea?.intro}</div>
                <div className="detail-idea__description" dangerouslySetInnerHTML={{ __html: getText(data?.intro ?? "") }}></div>
            </div>
            <div className="detail-idea__title">{idea?.overview}</div>
            {
                config.overviews.sort((a, b) => a.order - b.order).filter((overview) => data?.overview?.[overview.key] && data?.overview?.[overview.key] !== "").map((overview, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="detail-idea__block">
                                <div className="detail-idea__subtitle">{strings.ideaLabels[overview.key]}</div>
                                <div className="detail-idea__description" dangerouslySetInnerHTML={{ __html: getText(data?.overview?.[overview.key] ?? "") }}></div>
                            </div>
                        </React.Fragment>
                    )
                })
            }
            {
                Object.keys(data?.risksAndDisclosure ?? {}).filter((key) => data?.risksAndDisclosure?.[key] && data?.risksAndDisclosure?.[key] !== "").length > 0 &&
                <div className="detail-idea__title">{idea?.risksAndDisclosure}</div>
            }
            {
                Object.keys(data?.risksAndDisclosure ?? {}).filter((key) => data?.risksAndDisclosure?.[key] && data?.risksAndDisclosure?.[key] !== "").map((key, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="detail-idea__block">
                                <div className="detail-idea__subtitle">{idea?.[key]}</div>
                                <div className="detail-idea__description" dangerouslySetInnerHTML={{ __html: getText(data?.risksAndDisclosure?.[key] ?? "") }}></div>
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </div>
    );
};

export default Idea;
