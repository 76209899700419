import React from "react";
import "./FAQPage.scss";
import parse from "html-react-parser";

// Components
import { Navbar, Wrapper } from "../../components";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

// Questions&Answers
import { FAQs } from "../../utils/FAQs";

const FAQPage = () => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <div className="faq__container">
          <h1 className="faq__title">FAQ</h1>
          <Accordion allowZeroExpanded>
            {FAQs.map((faq, index) => (
              <AccordionItem key={index}>
                <AccordionItemHeading>
                  <AccordionItemButton>{faq.q}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion__panel font-S-400">
                  {parse(faq.a)}
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </Wrapper>
    </>
  );
};

export default FAQPage;
