import React from "react";
import { Redirect } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

import { useAppContext } from "../../context/store";


// Components
import Inner from "../inner/Inner";
import "react-toastify/dist/ReactToastify.css";

// Sass
import "./Outer.scss";

// Pages
import LoginPage from "../../pages/loginPage/LoginPage";

const Outer = () => {
  const {
    isLogged,
  } = useAppContext();

  return (
    <Switch>
        <Route path="/login" exact component={LoginPage} />
        <Route path='/' render={() => isLogged ? <Inner /> : <Redirect to='/login' />} />
        <Redirect to="/login" />
    </Switch>
  );
};

export default Outer;

