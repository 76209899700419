import Web3 from "web3";
import { setWalletAddress } from "../storage";

import factoryContractJson from "./ABI/Factory.json";
import fspContracts from "./ABI/FundingSinglePanel.json";
import cashbackContractJson from "./ABI/Cashback.json";
import tokenContracts from "./ABI/Token.json";

import { toast } from "react-toastify";

// const CONTRACTS_ADDRESSES = localConfigs.smartContractsParameters.ethereum;

export const web3Connect = async () => {
  const setup = async () => {
    try {
      const [address] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      window.web3 = new Web3(window.ethereum);

      if (address) {
        setWalletAddress(address);
        return address;
      }
    } catch (error) {
      throw error;
    }
  };

  if (window.ethereum) {
    return await setup();
  } else if (typeof window.ethereum === "undefined") {
    toast.error("Metamask is not installed");
  } else {
    window.addEventListener(
      "ethereum#initialized",
      async () => {
        return await setup();
      },
      {
        once: true,
      }
    );

    // If the event is not dispatched by the end of the timeout,
    // the user probably doesn't have MetaMask installed.
    setTimeout(async () => {
      return await setup();
    }, 2000);
  }
};

export const getFactoryContract = async (blockchain, config) => {
  let web3 = new Web3(window.ethereum);
  const networkId = await web3.eth.net.getId();

  const factoryDeployedNetwork =
    config.smartContractsParameters[blockchain]?.addresses?.Factory;
  let factoryAbi = factoryContractJson.abi || factoryContractJson;
  if (factoryAbi && factoryDeployedNetwork) {
    return new web3.eth.Contract(factoryAbi, factoryDeployedNetwork);
  }
};

export const getFspContract = async (address) => {
  let web3 = new Web3(window.ethereum);
  const networkId = await web3.eth.net.getId();

  let fspAbi = fspContracts.abi || fspContracts;
  if (fspAbi && address) {
    return new web3.eth.Contract(fspAbi, address);
  }
};

export const getCashbackContract = async (blockchain, config) => {
  let web3 = new Web3(window.ethereum);
  const networkId = await web3.eth.net.getId();

  const factoryDeployedNetwork =
    config.smartContractsParameters[blockchain]?.addresses?.Cashback;

  let fspAbi = cashbackContractJson.abi || cashbackContractJson;
  if (fspAbi && factoryDeployedNetwork) {
    return new web3.eth.Contract(fspAbi, factoryDeployedNetwork);
  }
};

export const getSeedContract = async (blockchain, config) => {
  let web3 = new Web3(window.ethereum);
  const networkId = await web3.eth.net.getId();

  const deployedNetwork =
    config.smartContractsParameters[blockchain]?.addresses?.SEED;
  let abi = tokenContracts.abi || tokenContracts;
  if (abi && deployedNetwork) {
    return new web3.eth.Contract(abi, deployedNetwork);
  }
};
