import fundraise_image from "../assets/png/FundraiseImage.png";

export const stepImages = [
  { step: 0, image: fundraise_image },
  { step: 1, image: fundraise_image },
  { step: 2, image: fundraise_image },
  { step: 3, image: fundraise_image },
  { step: 4, image: fundraise_image },
  { step: 5, image: fundraise_image },
  { step: 6, image: fundraise_image },
];

export const stepNames = [
  {
    step: 0,
    name: "Application",
  },
  {
    step: 1,
    name: "General",
  },
  {
    step: 2,
    name: "Idea",
  },
  {
    step: 3,
    name: "Media",
  },
  {
    step: 4,
    name: "Team",
  },
  {
    step: 5,
    name: "Documentation",
  },
  {
    step: 6,
    name: "Tags",
  },
];
