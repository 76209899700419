import React from "react";
import "./MobileIcon.scss";

const MobileIcon = ({ onClick, open }) => {
  return (
    <div id="nav-icon" className={open ? "open" : ""} onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default MobileIcon;
