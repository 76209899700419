import React from "react";

import image_logo from "../../../assets/svg/logo.svg";

const NavbarLogo = () => {
  return (
    /*
     TODO: change the url to the correct uone when defined
      <a href="https://" target={"_blank"} rel="noreferrer">
     */
    <a href="/"  rel="noreferrer">
      <div>
        <img
          src={image_logo}
          alt="Seed Venture Logo"
          style={{ width: "100%", height: 48, objectFit: "contain" }}
        />
      </div>
    </a>
  );
};

export default NavbarLogo;
