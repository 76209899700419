import {amountToWei} from "./helpers";

export const seed_approve = async (
  contract,
  account,
  stateSetter,
  spenderAddress,
  amount,
  config
) => {
  if (contract && account) {
    amount = amountToWei(
      amount,
      config.smartContractsParameters.seedTokenDecimals
    );

    const response = await contract.methods
      .approve(spenderAddress, amount)
      .send({ from: account })
      .then((res) => {
        stateSetter && stateSetter(res);
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }
  return null;
};
