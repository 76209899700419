/* eslint-disable no-template-curly-in-string */
export const en = {
  maxIntegersAndDecimalsAllowed: "Enter a value with maximum {integers} integers and maximum {decimals} decimals",
  maxIntegersAllowed: "Enter a value with maximum {integers} integers",
  maxDecimalsAllowed: "Enter a value with maximum {decimals} decimals",

  common: {
    changedData: "Data was changed",
    genericError: "Ops, something went wrong.",
    invalidURL: "Enter a valid URL",
    invalidEmail: "Enter a valid email",
    valueTooLong: 'Enter a value with less than {maxCharactersAllowed} characters',
    requiredDeployField: "This field is required to deploy the project",
    remainingLength: "{remainingLength} characters left"
  },
  login: {
    title: "Login",
    subTitle: "to My Fundraising AWS Suite Services",
    description: "Start the campaign building process",
    poweredBy: "Powered by",
    emailLabel: "Username",
    emailPlaceholder: "Enter your username",
    passwordLabel: "Password",
    passwordPlaceholder: "Enter yor password",
    submitButton: "Login",
  },
  connectWallet: {
    wallet: "Wallet",
    connectWallet: "Connect wallet",
    disconnectWallet: "Disconnect",
    error: "Error:",
    errorInstall: "Error: please install Metamask",
    metamask: "Metamask",
    metamaskLogoAlt: "Metamask wallet logo",
    metamaskDescr: "Connect to your metamask wallet",
    address: "Address:",
    switchNetwork:
      "Switch network to {blockchain} on Metamask or change the blockchain in the Application section.",
  },
  navigation: {
    dashboard: "Dashboard",
    faq: "FAQ",
    support: "Support",
    telegram: "Telegram",
    discord: "Discord",
    policy: "Cookie Policy",
    privacy: "Privacy Policy",
    seedVenture: "Seed Venture",
    navigation: "Navigation",
    version: "Version {version}"
  },
  wallet: {
    connect: "Connect Wallet",
  },
  dashboard: {
    profile: {
      profile: "Startup Profile",
      username: "Username",
      password: "Password",
      passwordPlaceholder: "********",
      change: "Change",
      starting: "Editable",
      deployed: "Deployed",
    },
    sendSeed:{
      seedStep0: "Approve Sending",
      seedStep1: "Send SEED"
    },
    fundraise: {
      fundraise: "Campaign Definition",
      status: "Status",
      mandatoryFields: "Mandatory fields filled:",
      deploy: "Deploy on {blockchain}",
      edit: "Edit",
      deployNoBc: "Connect wallet to Deploy",
    },
    addresses: {
      addresses: "Last deploy addresses",
      fspContract: "Funding Contract",
      fundTokenContract: "Fund Token Contract"
    },
    previews: {
      previews: "Previews",
      summary: "Summary",
      detail: "Detail",
    },
    campaignEnded: {
      inputPlaceholder: "Wallet address...",
      fundsClaimed: "Funds already claimed",
      totalRaised: "Total raised",
      seedsRefunded: "SEEDs already refunded",
      mainTitleSuccess: "Campaign successful",
      mainTitleFail: "Campaign failed",
      titleSuccess: "Claim your funds",
      titleFail: "Refund your SEED",
      campaignFailed: "Your campaign failed",
      campaignFailedDescription: "Your target of {target} was not reached. The total amount that was collected equals to {investedAmount}.",
      buttonSuccess: "Claim Funds",
      buttonFailed: "Refund SEED",
      claimDate: "Claim Date",
      claimWallet: "Wallet",
      claimAmount: "Amount",
      claimTxHash: "Tx Hash",
      refundDate: "Refund Date",
      refundWallet: "Wallet",
      refundAmount: "Amount",
      refundTxHash: "Tx Hash",
      fundsClaimedSuccessfully: "Your funds have been claimed successfully",
      seedsReturnedSuccessfully: "Your SEEDs have been refunded successfully"
    },
    deadLine:{
      title: "Deadline of Campaign Definition"
    },
    completed: "Completed",
    setEconomicData: "Set Economic Data",
    setCampaignDuration: "Set Campaign Duration",
    initializeCashback: "Initialize Cashback with SEEDs",
    initializeCampaign: "Initialize Campaign",
    newCampaign: "A new campaign was created",
    genericErrorTransaction: "Ops, something went wrong with the transaction",
    successFullTransaction: "Successfull transaction",
    awaitFinanceable:"Last step, approve transaction ",
    campaignDeployed: "Your campaign is successfully deployed",
    modalInfoDeployCampaign:
      "A new campaign is being deployed on the {blockchain} blockchain. This is the first step to attend in order to complete the whole process. Please wait until the transaction is confirmed.",
    modalInfoSetEcData:
      "This method will set the economic data of your campaign.",
    modalInfoSetCampDuration:
      "this method will set the duration of your campaign.",
    modalInfoInitializeCashback:
      "This method will initialize the cashback of your campaign.",
    modalInfoInitializeCampaign:
      "This method will initialize your campaign.",
  },
  changePasswords: {
    title: "Change password",
    oldPasswordLabel: "Old password",
    oldPasswordPlaceholder: "Enter the old password",
    newPasswordLabel: "New password",
    newPasswordPlaceholder: "Enter the new password",
    newPasswordLabelRep: "Repeat New password",
    newPasswordPlaceholderRep: "Enter the new password again",
    confirm: "confirm",
    notTheSameErr: "Error: passwords are not the same",
  },
  startProcess: {
    title: "Application Process",
    text: "In the next step you will be asked informations regarding your campaign. You don’t have to do it all at once, and you can skip a step or go back whenever you feel like. When you’re ready just deploy the campaign using the dashboard.",
    start: "Start now",
  },
  doubleStep:{
    title: "Verification process",
    text: "In the next step you will be asked in two separated windows to approve the transfer and then to proceed with the operation.",
    start: "Continue",
  },
  summaryPreview: {
    title: "Summary Preview",
  },
  progressBar:{
    title: "Dead line",
    tooltip: "Remaining time to complete the deployment steps",
    estimated: "Days left",
	startDate: "Start Date:",
	endDate: "End Date:"
  },
  detail: {
    seed: "SEED",
    estimated: "Estimated",
    websiteLabel: "Website",
    socialLabel: "Social",
    iconLabel: "Icon",
    tokenLabel: "Name",
    symbolLabel: "Symbol",
    ratioLabel: "Ratio",
    ratioTooltip: "Exchange ratio.",
    contractLabel: "Token contract",
    contractTooltip: "Address of the project contract.",
    cashbackCrowdInvestors: "Cashback for crowd investors",
    minimumInvestorSeedBalanceLabel: "Minimum Investor SEED Balance",
    lockupPeriod: "Lockup period",
    noImages: "No images found",
    idea: {
      intro: "One-liner intro",
      overview: "Overview",
      financials: "Financials",
      highlights: "Hightlights",
      marketLandscape: "Market Landscape",
      mission: "Mission",
      pitchDeck: "Pitch Deck",
      problem: "Problem",
      solution: "Solution",
      vision: "Vision",
      risksAndDisclosure: "Risks and Disclosures",
      general: "General",
      companyRelated: "Company Related"
    },
    sponsor: {
      title: "Sponsor",
      generalRole: "Sponsor General Role",
      generalRoleDescription: "The Sponsor refers to individuals or entities that support the goals and objectives the Startup. It means that the Sponsors, for istance, lend their name and reputation to generate interests and influence the adoption of a platform, solution an so on. If the Sponsors is also an investor, it means he could be the lead investor of the Startup. ",
      name: "Name",
      publicInfo: "Public Info",
      sponsorCommittedAmount: "Sponsor Committed Amount",
      sponsorCommittedAmountTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    team: {
      noImage: "No image"
    },
    documentation: {
      title: "Documentation",
      description: "Documents uploaded by the Proponent.",
      businessDocuments: "Business documents",
      legalDocuments: "Legal documents"
    }
  },
  greenSlice: {
    soon: {
      maximumLabel: "Maximum",
      targetLabel: "Target",
      durationLabel: "Blocks duration",
      durationTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      estimated: "Estimated"
    }
  },
  liveCard: {
    estimated: "Estimated",
    label: "Target",
    maximum: "Maximum",
    durationBlock: "Blocks duration",
    durationBlockTooltip: "Blocks duration tooltip",
    noImage: "No image"
  },
  fundraise: {
    backgroundAlt: "Fundraise section",
    application: "Application",
    applicationDesc: "Information you already submitted about your fundraise.",
    general: "General",
    generalDesc: "Informations regarding your raise funding campaign.",
    idea: "Idea",
    ideaDesc:
      "This is what investors will see in the fundraising page, the more you add, the better.",
    media: "Media",
    mediaDesc: "Add social networks and media of your fundraise.",
    team: "Team",
    teamDesc: "Add one or more members of your team.",
    documentation: "Documentation",
    documentationDesc:
      "Here you can upload documents regarding your fundraise.",
    tags: "Tags",
    tagsDesc: "Add one or more tags that define your fundraise.",
    noImage: "No image",
    saveCompleted: "Save completed",
    returnToDashboard: "Return to the Dashboard"
  },
  application: {
    minTargetAmount: "Target Amount must be greater than 1",
    minSponsorAmount: "Sponsor Committed Amount must be greater than 0",
    required: "Please enter a value",
    projectLabel: "Project/Startup Name",
    projectPlaceholder: "Enter the project name",
    projectTooltip: "The name of the project",
    emailLabel: "Email",
    emailPlaceholder: "Enter the email",
    emailTooltip: "Email of the Startup Owner",
    targetAmountLabel: "Fundraising Target Amount",
    targetAmountPlaceholder: "Enter the target amount",
    targetAmountTooltip: "The minimum capital the startup intend to raise",
    commAmountLabel: "Sponsor committed amount",
    commAmountPlaceholder: "Enter the committed amounts",
    commAmountTooltip: "Sponsor committed amount",
    contractAddressLabel: "Contract address",
    contractAddressTooltip: "Contract Address (In Beta testing it’s always the PAYT1 token)",

    save: "Save",
    blockchainLabel: "Blockchain / Layers",
    blockchainTooltip: "The blockchain or different layer where the smart contracts related to the fundraising campaign will be deployed. In Beta testing, it’s the Goerli Testnet",
    businessPlan: "Business Plan - Optional",
    businessPlanTooltip: "The business plan will not be displayed to investors",
    elevatorPitch: "Elevator Pitch",
    elevatorPitchTooltip: "The elevator pitch will not be displayed to investors",
    elevatorPitchError: "Elevator Pitch cannot be empty.",
    nativeCryptocurrency: "Blockchain native cryptocurrency"
  },
  general: {
    save: "Save",
    required: "Please, enter a value",
    minCapError: "Maximum cap cannot be lower than the target amount",
    minDurationBlockError: "Duration must be qual or greater than 0",
    minSeedCashbackDurationError:
      "Full incentive duration must be greater than 0",
    minExchangeRateError: "Exchange rate must be greater than 0",
    tokenSymbolFormatError:
      "Only english letters and numbers, max 12 characters, no spaces",
    tokenNameError: "Only english letters, numbers, spaces, max 20 characters",
    maxCapLabel: "Maximum Target Amount",
    maxCapPlaceholder: "Enter the maximum target amount",
    maxCapTooltip: "The maximum capital the Startup intend to raise",
    minimumInvestorSeedBalanceLabel: "Minimum Investor SEED Balance",
    minimumInvestorSeedBalancePlaceholder: 1,
    minimumInvestorSeedBalanceTooltip: "Minimum number of SEED that the Investor MUST have in wallet to proceed with the investment. The number MUST be greater than zero.",
    minimumInvestorSeedBalanceError:"The number MUST be greater than zero.",
    sponsorUrlLabel: "Sponsor URL",
    sponsorUrlPlaceholder: "Enter the Sponsor URL",
    sponsorUrlTooltip: "If any, the Startup will provide a web link referring to the Sponsor Investor - Linkedin is suggested",
    fullIncentive: "Cashback for crowd investors",
    fullIncentiveTooltip: "Answer 'Yes' if the Startup intends to use all or part of the Sponsor Investor's capital, expressed (or converted) into SEED tokens that will be transferred using the 'Incentive' section",
    durationBlock: {
      ethereum: {
        label: "Fundraising Period: Ethereum Blocks",
        placeholder: "Enter the block ethereum period",
        tooltip: "The fundraising campaign ethereum period the startup intends to set",
      },
      polygon: {
        label: "Fundraising Period: Polygon Blocks",
        placeholder: "Enter the block polygon period",
        tooltip: "The fundraising campaign polygon period the startup intends to set",
      },
    },
    seedCashbackDurationLabel: "Cashback Lockup Period",
    seedCashbackDurationPlaceholder: "Enter the SEED Cashback duration",
    seedCashbackDurationTooltip: "Answer 'Yes' if the Startup intends to buy SEED tokens as an incentive for all the campaign's participants, by putting them in a vault (smart contract); it will automatically unlock after the period choosed by the Startup in favour of all the crowd investors",
    sponsorNameLabel: "Sponsor name / company",
    sponsorNamePlaceholder: "Enter the sponsor name / company",
    sponsorNameTooltip: "If any, the Startup will write the name or Company's name acting as Sponsor Investor",
    fundTokenSymbLabel: "Funding Token Ticker",
    fundTokenSymbPlaceholder: "Enter the funding token symbol",
    fundTokenSymbTooltip: "The Symbol that the Startup intends to assign to the funding token.",
    fundTokenNameLabel: "Funding Token Name",
    fundTokenNamePlaceholder: "Enter the funding token name",
    fundTokenNameTooltip: "The name that the Startup intends to assign to the funding token that will be automatically issued by the platform as a counterpart of the investment",
    exchangeRateLabel: "Funding Token Issuing Ratio",
    exchangeRatePlaceholder: "Enter the exachange rate",
    exchangeRateTooltip: "The amount that the Startup intends to assign, and the platform will automatically issue, to a single investment unit. That is. 1 USDC - 1 Funding Token (therefore 1 USDC - 'n' Funding Token)",
    tokenLogo: "Token logo - Optional",
    tokenLogoTooltip: "Token logo tooltip",
    tokenLogoFileAndFormat: "32x32 pixels, png",
    blocksPerDay: "≈ {days} days, {hours} hours",
  },
  idea: {
    intro: "One-liner-intro",
    introPlaceholder: "Enter an intro (maximum {totalLength} characters)",
    introTooltip: 'Use a descriptive sentence, and not a marketing one, that It will be visible in the Startup’s showroom. If you do not already have one, feel free to use a formula like this: "(Company name) is developing (a defined offering) to help (a defined audience) (solve a problem) with (secret sauce).',
    introError: "The intro section cannot be empty",
    overview: "Overview",
    addSections: "Add sections",
    editorPlaceholder: "Enter the {section} section...",
    risksAndDisclosures: "Risks and disclosures",
    save: "Save",
    tooltipInfo:
      "Startup's short introduction. The text will be shown in the general tableau of Startups.",
  },
  media: {
    required: "Please, enter a value.",
    images: "Images",
    coverImage: "Cover Image",
    coverImageError: "Cover image cannot be empty.",
    coverImageTooltip: "Cover image tooltip",
    coverImageSizeAndFormat: "(jpeg or png, max 20MB)",
    carouselImagesSizeAndFormat:
      "(max {MAX_CAROUSEL_IMAGES}, jpeg or png, max 20MB)",
    maxCarouselImages:
      "Error: Max number of carousel images must be {MAX_CAROUSEL_IMAGES}",
    carouselImages: "Carousel images  - Optional",
    carouselImagesToolip: "Carousel images tooltip",
    website: "Website",
    websiteLabel: "Website URL",
    websiteTooltip: "Website URL",
    websitePlaceholder: "Enter the website URL",
    video: "Video",
    videoLabel: "Video URL - Optional",
    videoTooltip: "Video URL - Optional",
    videoPlaceholder: "Enter the video URL",
    social: "Social",
    addSocial: "Add social",
    save: "Save",
    delete: "Delete",
    invalidSocial: "Enter a valid {social} URL",
    socialTooltips: {
      youtube: "Youtube tooltip",
      twitter: "Twitter tooltip",
      linkedin: "Linkedin tooltip",
      tiktok: "Tiktok tooltip",
      telegram: "Telegram tooltip",
      instagram: "Instagram tooltip",
      whatsapp: "Whatsapp tooltip",
      facebook: "Facebook tooltip",
      discord: "Discord tooltip"
    }
  },
  team: {
    required: "Please, enter a value",
    imageRequired: "Please, upload an image",
    memberAdded: "The team member was successfully removed",
    memberError: "Ops, something went wrong. The team member was not removed",
    addAnother: "Add another",
    nameLabel: "First Name",
    namePlaceholder: "Enter the first name",
    nameTooltip: "First Name tooltip",
    lastNameLabel: "Last Name",
    lastNamePlaceholder: "Enter the last name",
    lastNameTooltip: "Last Name tooltip",
    roleLabel: "Role",
    rolePlaceholder: "Enter the role",
    roleTooltip: "Role tooltip",
    linkLabel: "Link",
    linkPlaceholder: "Enter a link",
    linkTooltip: "Link",
    descriptionLabel: "Description",
    descriptionPlaceholder: "Enter a description",
    descriptionTooltip: "Description tooltip",
    imageLabel: "Image",
    imageSizeAndFormat: "(jpeg or png, max 20MB)",
    imageTooltip: "Image tooltip",
    newMember: "New member",
    confirm: "Confirm",
    noImage: "No image",
    edit: "Edit",
    delete: "Delete",
    link: "Link - {link}",
  },
  documentation: {
    businessTitle: "Business Documents",
    legalTitle: "Legal Documents",
    agreeLabelBusiness: "Investor deck",
    agreeTooltipBusiness: "Investment deck tooltip",
    agreeLabelLegal: "Investment agreement",
    agreeTooltipLegal: "Investment agreement tooltip",
    agreeSizeAndFormat: "(max 20MB)",
    otherDocsLabelBusiness: "Other business documents - optional",
    otherDocsLabelLegal: "Other legal documents - optional",
    otherDocsTooltipBusiness: "Other business documents tooltip",
    otherDocsTooltipLegal: "Other legal documents tooltip",
    otherDocsSizeAndFormat: "(max 20MB)",
    alreadyUploadedBusiness: "Other business documents already uploaded:",
    alreadyUploadedLegal: "Other legal documents already uploaded:",
    save: "Save",
    sha3: "{hash} (Investment Agreement SHA-3 256 hash)",
    copiedSha3: "SHA-3 256 hash has been copied"
  },
  tags: {
    tagsErrorLength: "Tags must be at least 3 characters long",
    tagsAlreadyExists: "Error: this tags already exists.",
    tagsLabel: "Add a tag and press {Enter}",
    tagsPlaceholder: "Add a tag and press Enter",
    tagsTooltip: "Tags tooltip",
    save: "Save",
    newTag: "A new tag has been added.",
    deletedTag: "A tag has been deleted.",
  },
  dropArea: {
    dragOr: "Drag the file here or",
    select: "select file",
    errors: {
      onlyOne: "Error: only one document can be uploaded",
      tooBig: "Error: the document is too large",
    },
    newDownload: "New Download",
    renameNotValid: "Error: file format not valid",
    renamePlaceholder: "Enter a proper name",
    renameProperName:
      "Please give a proper name to the PDF file, investors will see this name in the fundraising page.",
  },
  htmlEditor: {
    emptySection: "This section can not be empty",
    delete: "Delete",
  },
  documents: {
    pdf: {
      fileAndFormat: "(PDF), max 20MB",
    },
  },
  socialLabels: {
    youtube: "Youtube",
    twitter: "Twitter",
    linkedin: "Linkedin",
    tiktok: "Tiktok",
    telegram: "Telegram",
    instagram: "Instagram",
    whatsapp: "Whatsapp",
    facebook: "Facebook",
    discord: "Discord"
  },
  ideaLabels: {
    highlights: "Idea & Highlights",
    mission: "Mission & Vision",
    problem: "Problem",
    solution: "Solution",
    marketLandscape: "Market Landscape & Strategy",
    financials: "Accomplishments to date",
    vision: "Use of Proceeds",
    pitchDeck: "Roadmap & Key Information",
    general: "General",
    companyRelated: "Company Related"
  }
};
