import React from "react";
import "./Tag.scss";

import { HiOutlineTrash as TrashButton } from "react-icons/hi";

const Tag = ({ tag, onDelete }) => {
  return (
    <span className="tag">
      {tag?.tag}
      <TrashButton
        size={26}
        style={{
          color: "#ea3333",
          cursor: "pointer",
        }}
        onClick={() => {
          onDelete && onDelete(tag);
        }}
      />
    </span>
  );
};

export default Tag;
