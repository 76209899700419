import React from "react";
import { Redirect } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import Spinner from "../common/spinner/Spinner";

// Sass
import "./Inner.scss";

// Pages
import { DashboardPage, FAQPage, FundraisePage } from "../../pages";
import { Footer } from "../../components";
import { useAppContext } from "../../context/store";
import DetailPage from "../../pages/detailPage/DetailPage";
//import { useWeb3Context } from "../../context/web3Store";

const Inner = () => {
  const { isDeployed,isCampaignOutOfDate, config } = useAppContext();
 // const { applications} = useWeb3Context();



  if (!config) {
    return (
      <Spinner
        wrapperStyle={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  //const netType = config?.blockchains?.find((blockchain) => blockchain?.name === applications?.blockchain)?.netType;
  return (

    <React.Fragment>
      {
        config &&
        <Switch>
          <Route path="/dashboard" exact component={DashboardPage} />
          {(!isDeployed /*|| netType === "testnet"*/) && !isCampaignOutOfDate && (
            <Route path="/fundraise" exact component={FundraisePage} />
          )}
          <Route path="/faq" exact component={FAQPage} />
          <Route path="/detail" exact component={DetailPage} />
          <Redirect to="/dashboard" />
        </Switch>
      }
      <Footer />
    </React.Fragment>
  );
};

export default Inner;
