import {toast} from "react-toastify";
import {amountToWei, uintToFloat} from "./helpers";

export const cashback_addFSPtoCashback = async (
  contract,
  account,
  stateSetter,
  fspNumber,
  minSeedThr,
  maxSeedThr,
  unlockedBlock,
  decimals
) => {
  if (contract && account) {
    minSeedThr = amountToWei(minSeedThr, decimals);
    maxSeedThr = amountToWei(maxSeedThr, decimals);
    const response = await contract.methods
      .addFSPtoCashback(fspNumber, minSeedThr, maxSeedThr, unlockedBlock)
      .send({ from: account })
      .then((res) => {
        stateSetter && stateSetter(res);
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }
  return null;
};

export const cashback_addLeadInvestorAmountToFSP = async (
  contract,
  account,
  stateSetter,
  leadInvestor,
  fspNumber,
  amount,
  config
) => {

  if (contract && account) {
    amount = amountToWei(amount, config.smartContractsParameters.seedTokenDecimals);

    const response = await contract.methods
      .addLeadInvestorAmountToFSP(leadInvestor, fspNumber, amount)
      .send({ from: account })
      .then((res) => {
        stateSetter && stateSetter(res);
      });
    return response;
  }
  return null;
};


export const cashback_returnSeedsToLeadInvestor = async (
  contract,
  leadInvestor,
  fspNumber,
  amount,
  campaignSuccess,
  symbol,
  setCampaignDeployed,
  deployId,
  appDispatch,
  message,
  setLoading
  // amount,
  // config
) => {

  if (contract) {
    //  amount = amountToWei(
    //    amount,
    //    config.smartContractsParameters.seedTokenDecimals
    //  );

    const response = await contract.methods
      .returnSeedsToLeadInvestor(fspNumber)
      .send({ from: leadInvestor })
      .then((res) => {
        return setCampaignDeployed(appDispatch, deployId, {
          walletAddress: leadInvestor,
          amount,
          transactionHash: res?.transactionHash || res?.txHash || res?.tx,
          campaignSuccess,
          symbol
        })
      })
      .then(() => {
        setLoading(false)
        toast.success(message)
      })
      .catch((err) => {
        setLoading(false)
      })
    return response;
  }
  return null;
};

export const cashback_isLeadInvestorInFSP = async (
  contract,
  fspNumber
) => {

  if (contract) {
    const response = await contract.methods
      .isLeadInvestorInFSP(fspNumber)
      .call();
    return response;
  }
  return null;
};

export const cashback_leadInvestorFSPAmounts = async (
  contract,
  fspNumber,
  walletAddress,
  decimals
)=>{
  if (contract) {
    const response = await contract.methods
      .leadInvestorFSPAmounts(fspNumber,walletAddress)
      .call()
      .then((res) => {
        res = parseFloat(uintToFloat(res, decimals));
        return res
      })
    return response;
  }
  return null;
};
