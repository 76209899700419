import React, { useEffect, useContext, useReducer } from "react";
import { getUserLoginData } from "../storage";
import { LOGIN, GET_CONFIG, reducer } from "./reducer";
import { apiCall } from "../api";
import LocalizedStrings from "react-localization";
import { en } from "../utils/localization";

export const AppContext = React.createContext();

const initialState = {
  alert: null,
  isLoading: true,
  loadingCount: 0,
  isLogged: !!getUserLoginData(),
  authToken: null,
  username: null,
  authorities: [],
  requirePasswordChange: false,
  roles: [],
  isDeployed: false,
  isCampaignOutOfDate: false,
  status: null,
};

const AppProvider = ({ children }) => {
  const [state, appDispatch] = useReducer(reducer, initialState);

  let strings = new LocalizedStrings({
    en,
  });

  const { common } = strings;

  // get global configs (e.g. constants...)
  const getGlobalConfigs = async () => {
    const config = {
      method: "get",
      url: "/config",
    };

    try {
      const { data } = await apiCall(config, appDispatch);
      appDispatch({ type: GET_CONFIG, payload: data });
    } catch (error) {

    }
  };

  // Automatically try to log user if user data is in localStorage
  useEffect(() => {
    const userData = getUserLoginData();
    if (userData) {
      appDispatch({ type: LOGIN, payload: userData });
    }
  }, []);

  useEffect(() => {
    getGlobalConfigs();
  }, []);

  return (
    <AppContext.Provider value={{ ...state, strings, appDispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export default AppProvider;
