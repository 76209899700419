import React, { useEffect, useState } from "react";
import "./MemberTeamForm.scss";
import { useForm } from "react-hook-form";

// Components
import Input from "../common/Input/Input";
import Button from "../common/button/Button";
import { HtmlEditor } from "..";
import DropArea from "../common/dropArea/DropArea";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/store";

const MemberTeamForm = React.forwardRef(
  ({ member, config, handleOnSubmit }, ref) => {
    const {
      fileUploadConstants: { IMAGE },
    } = config;
    const {
      handleSubmit,
      reset,
      register,
      control,
      watch,
      formState: { errors, isSubmitted },
    } = useForm({ defaultValues: member });
    const [memberImage, setMemberImage] = useState(null);
    const { strings } = useAppContext();
    const { dropArea, team, common } = strings;

    const watchDescription = watch("description");

    const onSubmit = handleSubmit((e, values) =>{
      if (memberImage) handleOnSubmit(e, values, memberImage)
    });

    useEffect(() => {
      reset(member);
      setMemberImage(member?.image);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member]);

    return (
      <div className="member-team__form" ref={ref}>
        {!member ? (
          <h4 className="member-team__form-title">{team.newMember}</h4>
        ) : (
          <span className="member-team__form-divider"></span>
        )}

        <form onSubmit={onSubmit}>
          <Input
            label={team.nameLabel}
            {...register("firstName", { required: team.required })}
            aria-invalid={!!errors.firstName}
            error={errors?.firstName}
            placeholder={team.namePlaceholder}
            tooltipText={team.nameTooltip}
          />
          <Input
            label={team.lastNameLabel}
            {...register("lastName", { required: team.required })}
            aria-invalid={!!errors.lastName}
            error={errors?.lastName}
            placeholder={team.lastNamePlaceholder}
            tooltipText={team.lastNameTooltip}
          />
          <Input
            label={team.roleLabel}
            {...register("role", { required: team.required })}
            aria-invalid={!!errors.role}
            error={errors?.role}
            placeholder={team.rolePlaceholder}
            tooltipText={team.roleTooltip}
          />
          <Input
            label={team.linkLabel}
            aria-invalid={!!errors?.link}
            error={errors?.link}
            {...register("link", {
              required: team.required,
              pattern: {
                value: new RegExp(config.regex.validURL),
                message: common.invalidURL,
              },
            })}
            placeholder={team.linkPlaceholder}
            tooltipText={team.linkTooltip}
          />
          {/* <TextArea

      
          /> */}
          <HtmlEditor
            style={{ width: "100%" }}
            placeholder={team.descriptionPlaceholder}
            label={team.descriptionLabel}
            value={watchDescription}
            // name={`description`}
            {...register("description", { required: team.required })}
            error={errors?.description ? {
              message: team.required
            } : undefined}
            control={control}
            noUploadImage
            tooltipText={team.descriptionTooltip}
          />
          <div className="member-team__form-image">
            <DropArea
              label={team.imageLabel}
              areaMessage={team.imageSizeAndFormat}
              fileName="Image"
              accept={IMAGE.allowedExtensions.join(",")}
              lastFile={memberImage}
              fileSetter={setMemberImage}
              imageToPreviewBig={memberImage}
              deleteFileFunction={() => {
                setMemberImage(null);
              }}
              withRecycleBinView
              maxFiles={1}
              maxSize={IMAGE.sizeInByte}
              onError={(errors) => {
                errors.forEach((error) => {
                  if (error === "too-many-files") {
                    toast.error(dropArea.errors.onlyOne);
                  }
                  if (error === "file-too-large") {
                    toast.error(dropArea.errors.tooBig);
                  }
                });
              }}
              tooltipText={team.imageTooltip}
              error={!memberImage && isSubmitted ? team.imageRequired : undefined}
            />
          </div>

          <Button>{team.confirm}</Button>
        </form>
      </div>
    );
  }
);

export default MemberTeamForm;
