import React from "react";
import "./MemberTeam.scss";

import { FaEdit } from "react-icons/fa";
import { HiOutlineTrash as TrashButton } from "react-icons/hi";
import { AiOutlineFileImage } from "react-icons/ai";
import { autoIncludeHttp, handleTextLength } from "../../utils/helperFunctions";
import { useAppContext } from "../../context/store";

import parse from "html-react-parser";

const MemberTeam = ({ member, onDelete, onEdit }) => {
  const { strings } = useAppContext();
  const { team } = strings;

  const tryParse = (text) => {
    if(!!text)
      return parse(text)
    else
      return ""
  }

  return (
    <div className="member-team">
      <div className="member-team__image">
        {member?.image ? (
          <img src={member?.image} alt={member?.description} />
        ) : (
          <div className="member-team__placeholder">
            <span>{team.noImage}</span>
            <AiOutlineFileImage size={36} />
          </div>
        )}
      </div>
      <div className="member-team__text-content">
        <h4 className="member-team__title font-S-700">
          <span>
            {member?.firstName} {member?.lastName} - {member?.role}
          </span>
          <div className="member-team__buttons">
            <button
              tabIndex={0}
              className="member-team__button"
              onClick={() => {
                onEdit && onEdit(member);
              }}
            >
              <FaEdit color="var(--color-primary)" size={18} /> {team.edit}
            </button>
            <button
              tabIndex={0}
              className="member-team__button"
              onClick={() => {
                onDelete && onDelete(member?.id);
              }}
            >
              <TrashButton color="#e03737" size={18} />
              {team.delete}
            </button>
          </div>
        </h4>
        <p className="member-team__link">
          {strings.formatString(team.link, {
            link: (
              <a href={autoIncludeHttp(member?.link)} target="_blank" rel="noreferrer">
                {member?.link}
              </a>
            ),
          })}
        </p>

        <p className="member-team__description font-S-400">
          {/* {handleTextLength(member?.description, 360)} */}
          {tryParse(member?.description)}
        </p>
      </div>
    </div>
  );
};

export default MemberTeam;
