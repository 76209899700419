import React from "react";
import "./TextArea.scss";

// Icons

import { HiOutlineTrash as TrashButton } from "react-icons/hi";
import { useAppContext } from "../../../context/store";

const TextArea = React.forwardRef(({ ...props }, ref) => {
  const { strings } = useAppContext();
  const { maxLength, currentLength, onDelete } = props;

  function getColor(chars) {
    if (chars > (maxLength / 2)) {
      return "green"
    } else if (chars > (maxLength / 4)) {
      return "yellow"
    } else {
      return "red"
    }
  }

  return (
    <div className="textarea-container">
      <label
        className={`textarea__label font-S-400 ${
          props.error ? "label--error" : ""
        }`}
        htmlFor={props.id}
      >
        {props.label}
        {onDelete && (
          <span
            className="textarea__label-delete font-S-700"
            onClick={() => {
              onDelete && onDelete();
            }}
          >
            <TrashButton size={18} color="red" /> Delete
          </span>
        )}
      </label>
      <textarea
        tabIndex={0}
        ref={ref}
        maxLength={maxLength}
        onClick={(e) => {
          e.preventDefault();
          if (props?.onClick) {
            props?.onClick();
          }
        }}
        className={`textarea font-S-600`}
        {...props}
      >
        {props.value}
      </textarea>
      {props.error && (
        <label className="textarea__label-error">{props.error.message}</label>
      )}
      {
        currentLength > 0 && maxLength > 0 &&
        <div className={`textarea__remaining-chars ${getColor(maxLength - currentLength)}`}>{strings.formatString(strings.common.remainingLength, {
          remainingLength: maxLength - currentLength,
        })}</div>
      }
    </div>
  );
});

export default TextArea;
