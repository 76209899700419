import React, { useState } from "react";
import "./Navbar.scss";

// Components
import NavbarLogo from "../navbarLogo/NavbarLogo";

import ConnectWalletButton from "../connectWalletButton/ConnectWalletButton";
import NavbarRightLinks from "../navbarRightLinks/NavbarRightLinks";
import NavbarLinkButton from "../navbarLinkButton/NavbarLinkButton";

import MobileIcon from "../mobileIcon/MobileIcon";
import MobileLeftBar from "../mobileLeftbar/MobileLeftBar";
import { FiLogOut } from "react-icons/fi";

import { useAppContext } from "../../../context/store";
import { LOGOUT } from "../../../context/reducer";
import { resetStorage } from "../../../storage";
import { removeApiToken } from "../../../api";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const [openLeftMenu, setOpenLeftMenu] = useState(false);
  const {
    strings: { navigation },
    appDispatch,
  } = useAppContext();
  const history = useHistory();

  return (
    <div className="navbar">
      <NavbarLogo />

      <div className="navbar-links">
        <NavbarLinkButton text={navigation.dashboard} to={"/"} />
        <NavbarLinkButton text={navigation.faq} to={"/faq"} />
      </div>
      <NavbarRightLinks />
      <MobileIcon
        open={openLeftMenu}
        onClick={() => {
          setOpenLeftMenu((prev) => !prev);
        }}
      />
      <MobileLeftBar open={openLeftMenu} />
      <ConnectWalletButton />
      <button
        className="navbar__logout"
        onClick={() => {
          // window.location = "/";
          removeApiToken();
          resetStorage();
          appDispatch({ type: LOGOUT });
          history.push("/login");
        }}
      >
        Sign out
        <FiLogOut />
      </button>
    </div>
  );
};

export default Navbar;
