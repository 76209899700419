import React, { useState } from "react";
import { useAppContext } from "../../../context/store";
import "./ConnectWalletButton.scss";

import InfoWalletModal from "../../InfoWalletModal/InfoWalletModal";
import ConnectWalletModal from "../../ConnectWalletModal/ConnectWalletModal";

import { useWeb3Context } from "../../../context/web3Store";

const ConnectWalletButton = ({ isInMobileHeader }) => {
  const { walletAddress } = useWeb3Context();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showInfoWalletModal, setShowInfoWalletModal] = useState(false);
  const {
    strings: { wallet },
  } = useAppContext();

  const handleConnectWallet = () => {
    if (!walletAddress) {
      setShowConnectModal(true);
    } else {
      setShowInfoWalletModal(true);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {showConnectModal && (
        <ConnectWalletModal setShowConnectModal={setShowConnectModal} />
      )}
      {showInfoWalletModal && (
        <InfoWalletModal setShowInfoWalletModal={setShowInfoWalletModal} />
      )}
      <button
        className="connect-wallet-button font-S-700"
        style={{ display: isInMobileHeader && "initial" }}
        onClick={handleConnectWallet}
      >
        {walletAddress || wallet.connect}
      </button>
    </div>
  );
};

export default ConnectWalletButton;
