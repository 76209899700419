export const SET_WALLET_ADDRESS = "SET_WALLET_ADDRESS";
export const REMOVE_WALLET_ADDRESS = "REMOVE_WALLET_ADDRESS";
export const SET_CONTRACTS = "SET_CONTRACTS";
export const SET_DEPLOY_CONTRACTS = "SET_DEPLOY_CONTRACTS";
export const CANCEL_WEB3_LOADING = "CANCEL_WEB3_LOADING";
export const SET_WEB3_LOADING = "SET_WEB3_LOADING";
export const SET_ECONOMIC_DATA = "SET_ECONOMIC_DATA";
export const SET_CAMPAIGN_DURATION = "SET_CAMPAIGN_DURATION";
export const SET_STEP = "SET_STEP";
export const RESET_STEPS = "RESET_STEPS";
export const SET_GENERAL = "SET_GENERAL";
export const SET_APPLICATIONS = "SET_APPLICATIONS";
export const SET_IS_BLOCKCHAIN_WRONG = "SET_IS_BLOCKCHAIN_WRONG";
export const SET_FSP_CONTRACT = "SET_FSP_CONTRACT";
export const SET_DEPLOYED_SEED_CASHBACK_ENABLED = "SET_DEPLOYED_SEED_CASHBACK_ENABLED"
export const SET_NEW_CAMPAIGN = "SET_NEW_CAMPAIGN"

export const STEP_INIT = 0;
export const STEP_CONTRACT_DATA = 1;
export const STEP_INITIALIZE_CASHBACK = 2;
export const STEP_INITIALIZE_CAMPAIGN = 3;
export const STEP_COMPLETED = 4;

export const web3Reducer = (prevState, action) => {
  switch (action.type) {
    case SET_WALLET_ADDRESS:
      return {
        ...prevState,
        walletAddress: action.payload,
      };
    case REMOVE_WALLET_ADDRESS:
      return {
        ...prevState,
        walletAddress: null,
      };
    case SET_CONTRACTS:
      return {
        ...prevState,
        ...action.payload.contracts,
        general: action.payload.general,
        applications: action.payload.applications,
        idea: action.payload.idea,
        media: action.payload.media,
        teams: action.payload.teams,
        documents: action.payload.documents
      };
    case SET_DEPLOY_CONTRACTS:
      return {
        ...prevState,
        deployContracts: action.payload,
      };
    case SET_GENERAL:
      return {
        ...prevState,
        general: action.payload,
      };
    case SET_APPLICATIONS:
      return {
        ...prevState,
        applications: action.payload,
      };
    case SET_WEB3_LOADING:
      return {
        ...prevState,
        web3Loading: true,
      };
    case CANCEL_WEB3_LOADING:
      return {
        ...prevState,
        web3Loading: false,
      };
    case SET_FSP_CONTRACT:
      return {
        ...prevState,
        fspContract: action.payload,
      };
    case SET_ECONOMIC_DATA:
      return {
        ...prevState,
        isEconomicDataSetted: true,
      };
    case SET_CAMPAIGN_DURATION:
      return {
        ...prevState,
        isCampaignDurationSetted: true,
      };
    case SET_DEPLOYED_SEED_CASHBACK_ENABLED:
      return {
        ...prevState,
        deployedSeedCashbackEnabled: action.payload
      }
    case SET_NEW_CAMPAIGN:
      return {
        ...prevState,
        isCampaignDurationSetted: false,
        isEconomicDataSetted: false,
        campaignStep: STEP_CONTRACT_DATA
      }

    case SET_STEP: {
      return {
        ...prevState,
        campaignStep: action.payload
      }
    }

    case SET_IS_BLOCKCHAIN_WRONG: {
      return {
        ...prevState,
        isBlockChainWrong: action.payload,
      };
    }

    case RESET_STEPS: {
      return {
        ...prevState,
        campaignStep: STEP_INIT,
      };
    }

    default:
      return prevState;
  }
};
