import React from "react";
import "./NavbarRightLinks.scss";

// Components
import NavbarLinkButton from "../navbarLinkButton/NavbarLinkButton";

import { telegram_logo } from "../../../assets/svg";
import { useAppContext } from "../../../context/store";
import { FaDiscord } from "react-icons/fa";

const NavbarRightLinks = () => {
  const {
    config,
    strings: { navigation },
  } = useAppContext();
  return (
    <div className="navbar-right-links">
      <NavbarLinkButton
        href={config?.URLs.telegram}
        target="_blank"
        icon={telegram_logo}
        style={{
          border: "1px solid var(--color-primary)",
          borderRadius: 4,
          padding: "4px 12px",
          height: 42,
          // boxShadow: "0px 2px 4px rgb(46 198 186 / 25%)",
        }}
      />
      <NavbarLinkButton
        target="_blank"
        href={config?.URLs.discord}
        style={{
          border: "1px solid var(--color-primary)",
          borderRadius: 4,
          padding: "4px 12px",
          height: 42,
          // boxShadow: "0px 2px 4px rgb(46 198 186 / 25%)",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "var(--dim-12)",
          }}
        >
          <FaDiscord fontSize={32} color="var(--color-primary)" />{" "}
          {navigation.support}
        </span>
      </NavbarLinkButton>
    </div>
  );
};

export default NavbarRightLinks;
