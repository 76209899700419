export const FAQs = [
  {
    q: "Why invest in Venture Capital?",
    a: `<span>Venture</span> Capital is a high-risk form of “alternative” investment, but one that can deliver exceptional returns and performance. It entails investing early in business ventures, i.e. in the birth and growth stages of start-ups and scale-ups. From a social point of view, investing directly in start-ups helps to advance innovation in a country, helps to develop ideas and drive virtuous initiatives forward.`,
  },
  {
    q: "Who invests in Startups?",
    a: "There are no specific requirements, anyone can participate in financing innovation. Thanks to Angel Investors, Venture Capitalists, and Venture Funds (professional entities), and the emergence of crowdfunding portals, access to investment in startups has become more accessible, while not limiting the risks.",
  },
  {
    q: "Why was Seed Venture created?",
    a: "To facilitate the matching of supply and demand of capital for innovation, solving the major problem of illiquidity and mitigating the risks associated with investing, issues that have not yet been resolved.",
  },
  {
    q: "So is it a classic startup crowdfunding experience?",
    a: "No, because it is entirely based on the concept of decentralization and liquidity of investments.",
  },
];
