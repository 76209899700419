import React, { useEffect, useState, useRef } from "react";
import "./Team.scss";

import { useAppContext } from "../../../context/store";

// Services
import {
  deleteMemberTeamImageService,
  deleteMemberTeamService,
  editMemberTeamService,
} from "../../../services/teamServices";

// Components
import Spinner from "../../common/spinner/Spinner";
import MemberTeamForm from "../../memberTeamForm/MemberTeamForm";
import MemberTeam from "../../memberTeam/MemberTeam";
import Button from "../../common/button/Button";
import { toast } from "react-toastify";
import { trimSpaces } from "../../../utils/helperFunctions";

const Team = ({ teamData, onSave }) => {
  const { isLoading, appDispatch, strings, config } = useAppContext();
  const [memberToEdit, setMemberToEdit] = useState(null);
  const { team, common } = strings;

  const [showForm, setShowForm] = useState(
    !!teamData?.length ? teamData.length === 0 : false
  );
  const memberTeamForm = useRef();

  const handledDeleteMemberTeam = async (id) => {
    try {
      const { data } = await deleteMemberTeamService(id, appDispatch);
      onSave(4, data);

      toast.success(team.memberAdded);
    } catch (error) {

    }
  };

  const handleOnSubmit = async (values, e, memberImage) => {
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, trimSpaces(values[key]))
    })

    if (memberImage) {
      formData.set("image", memberImage);
    } else {
      formData.set("image", "");
    }

    // formData.append("image", memberImage);

    try {
      //delete image if its null or ""
      if(!memberImage){
        await deleteMemberTeamImageService(
          memberToEdit?.id,
          appDispatch
        );
      }

      const { data } = await editMemberTeamService(
        formData,
        memberToEdit?.id,
        appDispatch
      );

      onSave(4, data);

      toast.success(common.changedData);

      setShowForm(false);
    } catch (error) {

    }
  };

  useEffect(() => {
    if (memberToEdit) {
      setShowForm(true);
    }
  }, [memberToEdit]);

  useEffect(() => {
    if (showForm && memberToEdit) {
      memberTeamForm.current.scrollIntoView();
    }
  }, [memberToEdit, showForm]);

  useEffect(() => {
    if (!showForm) {
      setMemberToEdit(null);
    }
  }, [showForm]);

  if (isLoading)
    return (
      <Spinner
        wrapperStyle={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );

  return (
    <div className="team">
      <div className="team__members-container">
        {teamData
          ?.sort((memberA, memberB) => memberA?.id - memberB?.id)
          ?.map((member, index) => (
            <MemberTeam
              key={index}
              member={member}
              onDelete={(id) => handledDeleteMemberTeam(id)}
              onEdit={(member) => setMemberToEdit(member)}
            />
          ))}
      </div>
      {showForm ? (
        <MemberTeamForm
          member={memberToEdit}
          config={config}
          ref={memberTeamForm}
          setShowForm={setShowForm}
          handleOnSubmit={handleOnSubmit}
        />
      ) : (
        <Button
          style={{ marginBottom: "var(--dim-56)" }}
          onClick={() => setShowForm(true)}
        >
          {team.addAnother}
        </Button>
      )}
    </div>
  );
};

export default Team;
