import React from "react";
import "./Input.scss";

// Components
import Select from "react-select";
import { HiOutlineTrash as TrashButton } from "react-icons/hi";
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";
import ReactTooltip from "react-tooltip";

import { Controller } from "react-hook-form";

const selectStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    visibility: state.isDisabled && "hidden",
    position: "absolute",
    left: 47,
    color: "var(--color-primary)",
  }),
  container: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: 28,
    left: 2,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    width: state.isDisabled ? 72 : 80,
    color: "var(--color-primary)",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state?.isDisabled ? "var(--color-gray-light)" : "var(--color-primary)",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state?.isFocused ? "var(--color-white)" : "var(--color-primary)",
    backgroundColor: state?.isFocused
      ? "var(--color-primary)"
      : "var(--color-white)",
  }),
};

const Input = React.forwardRef(({ ...props }, ref) => {
  const { tokenProps, onDelete, isURL, customText, tooltipText, ...rest } =
    props;

  return (
    <div className="input-container">
      <ReactTooltip
        place="right"
        backgroundColor="var(--color-primary)"
        textColor="var(--color-white)"
        effect="solid"
        id="input-tooltip"
      />
      <label
        className={`label ${props.error ? "label--error" : ""} ${props.warning ? "label--warning" : ""}`}
        htmlFor={props.id}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
      >
        <span className="input-text-container">
          {props.label}{" "}
          {tooltipText ? (
            <span className="input-tooltip" data-for="input-tooltip" data-tip={tooltipText}>
              <InfoIcon />
            </span>
          ) : null}
        </span>

        {onDelete && (
          <button
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
            type="button"
            className="label__delete-button"
          >
            <TrashButton size={22} color="red" /> delete
          </button>
        )}
      </label>
      {tokenProps && (
        <Controller
          control={tokenProps?.control}
          name={tokenProps?.name}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <Select
                // menuIsOpen
                isDisabled={tokenProps?.readOnly}
                isSearchable={false}
                styles={selectStyles}
                inputRef={ref}
                onChange={(token) => {
                  if (tokenProps.onTokenChange) {
                    tokenProps.onTokenChange(token.value)
                  }
                  onChange(token.value)
                }}
                value={tokenProps?.options
                  ?.map((token) => ({
                    value: token,
                    label: token,
                  }))
                  .filter((c) => (tokenProps?.value ? tokenProps.value : value) === c.value)}
                options={tokenProps?.options?.map((token) => ({
                  value: token,
                  label: token,
                }))}
              />
            )
          }}
        />
      )}

      <input
        tabIndex={0}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          if (props?.onClick) {
            props?.onClick();
          }
        }}
        // autoFocus
        className={`input ${props.token ? "input__token" : ""} ${
          isURL ? "input__url" : ""
        } ${props.warning ? "input-warning" : ""}`}
        onInput={(e) => {
          if (e.target?.type === "number" && e.target?.maxLength !== -1) {
            if (e.target?.value.length > e.target.maxLength) {
              e.target.value = e.target.value.slice(0, e.target.maxLength);
            }
          }
        }}
        style={{
          paddingLeft: props?.tokenProps
            ? tokenProps?.readOnly
              ? 68
              : 90
            : 10,
        }}
        {...rest}
      />
      <span className="input__error-text">{props?.error?.message || null}</span>
      <span className="input__warning-text">{props?.warning || null}</span>
      {customText && !props?.error?.message ? (
        <span className="input__custom-text">{customText}</span>
      ) : null}
    </div>
  );
});

export default Input;
