import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Context providers
import AppProvider from "./context/store";
import Web3Provider from "./context/web3Store";

ReactDOM.render(
  <React.StrictMode>
      <AppProvider>
        <Web3Provider>
          <App />
        </Web3Provider>
      </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
