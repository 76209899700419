import React from 'react'
import "./MemberTeamCard.scss"

import { AiOutlineFileImage } from "react-icons/ai";
import { useAppContext } from '../../context/store';
import { autoIncludeHttp } from '../../utils/helperFunctions';

const MemberTeamCard = ({ member }) => {
    const { strings } = useAppContext();

    const { detail: { team } } = strings

    function getText(text) {
        return text
    }

    return (
        <div className="member-card">
            <div className="member-card__name">{member?.firstName} {member?.lastName}</div>
            <div className="member-card__role-link">{member?.role} - <a href={autoIncludeHttp(member?.link)} target="_blank" rel="noreferrer">Link</a> </div>
            <div className="member-card__image">
                {
                    member?.image &&
                    <img src={member.image} alt="profile-img" />
                }
                {
                    !member?.image &&
                    <div className="member-card__placeholder">
                        <span>{team.noImage}</span>
                        <AiOutlineFileImage size={36} />
                    </div>
                }
            </div>
            <div className="member-card__description" dangerouslySetInnerHTML={{ __html: getText(member?.description ?? "") }}></div>
        </div>
    )
}

export default MemberTeamCard