import React, { useEffect, useContext, useReducer } from "react";

import {
  SET_WALLET_ADDRESS,
  SET_CONTRACTS,
  REMOVE_WALLET_ADDRESS,
  web3Reducer as reducer,
  SET_FSP_CONTRACT,
  SET_ECONOMIC_DATA,
  SET_CAMPAIGN_DURATION,
  SET_STEP,
  SET_WEB3_LOADING,
  CANCEL_WEB3_LOADING,
  SET_IS_BLOCKCHAIN_WRONG,
  STEP_INIT,
  STEP_INITIALIZE_CASHBACK,
  STEP_INITIALIZE_CAMPAIGN,
  STEP_COMPLETED,
  STEP_CONTRACT_DATA,
  SET_DEPLOYED_SEED_CASHBACK_ENABLED,
} from "./web3Reducer";
import { getWalletAddress, setWalletAddress } from "../storage";

import { getApplicationsGeneralService } from "../services/dashboardServices";
import { useAppContext } from "./store";
import {
  getCashbackContract,
  getFspContract,
} from "../web3Wrapper/web3Helpers";

import { NULL_ADDRESS } from "../utils/constants";
import { toast } from "react-toastify";
import { valueToLabel } from "../components/fundraise/general/General";

export const Web3Context = React.createContext();

const initialState = {
  campaignIndexByAddress: null,
  walletAddress: null,
  factoryContract: null,
  fspContract: null,
  cashbackContract: null,
  seedContract: null,
  deployContracts: [],
  web3Loading: false,
  applications: null,
  general: null,
  idea: null,
  media: null,
  teams: null,
  documents: null,
  isEconomicDataSetted: false,
  isCampaignDurationSetted: false,
  campaignStep: STEP_INIT,
  isBlockChainWrong: false,
  deployedSeedCashbackEnabled: false
};

const AppProvider = ({ children }) => {

  const { appDispatch, config, strings, status } = useAppContext();
  const [state, web3Dispatch] = useReducer(reducer, initialState);
  const { connectWallet } = strings;

  // Get address from local storage if was previously connected
  useEffect(() => {

    const address = getWalletAddress();
    if (address) {
      web3Dispatch({ type: SET_WALLET_ADDRESS, payload: address });
    }
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length === 0) {
          web3Dispatch({ type: REMOVE_WALLET_ADDRESS });
          setWalletAddress(null);
        }
      });
    }
  }, [window.ethereum]);

  // Get general data and application data for passing those values as smart contract parameters
  const getDataAndContracts = async () => {
    // todo : why the fuck there was this line ?
    // if (!status) return
    try {
      const { applications, general, idea, media, teams, documents } = await getApplicationsGeneralService(
        appDispatch
      );

      web3Dispatch({
        type: SET_CONTRACTS,
        payload: { applications, general, idea, media, teams, documents },
      });

      return { applications, general, idea, media, teams, documents };
    } catch (err) {

    }
  };

  const getCampaignCurrentStatus = async (applications, campaignId, contracts) => {
    web3Dispatch({ type: SET_WEB3_LOADING });
    // const contractFactory = await getFactoryContract(blockchain, config);
    // console.log("contractFactory", contractFactory);
    try {
      // const fspAddress = await factory_getFSPAddressByIndex(
      //   contractFactory,
      //   state.walletAddress,
      //   null,
      //   campaignId
      // );
      let economicData = false
      let campaignDuration = false
      let step = STEP_INIT
      const fspAddress = contracts.find((contract) => contract.name === "fspContract")?.address

      const fspContract = await getFspContract(fspAddress);

      web3Dispatch({ type: SET_FSP_CONTRACT, payload: fspContract });

      const cashbackContract = await getCashbackContract(
        applications?.blockchain,
        config
      );

      await fspContract.methods
        .ownerDataSet()
        .call({ from: state.walletAddress })
        .then((res) => {
          if (!!res) {
            if (state?.campaignStep <= STEP_CONTRACT_DATA) step = STEP_CONTRACT_DATA
          }
        });

      if (step === STEP_CONTRACT_DATA) {
        await fspContract.methods
          .economicDataSet()
          .call({ from: state.walletAddress })
          .then((res) => {
            if (!!res) {
              web3Dispatch({ type: SET_ECONOMIC_DATA });
              economicData = true
            }
          });

        await fspContract.methods
          .campaignDurationSet()
          .call({ from: state.walletAddress })
          .then((res) => {
            if (!!res) {
              web3Dispatch({ type: SET_CAMPAIGN_DURATION });
              campaignDuration = true
            }
          });

        if (economicData && campaignDuration) {
          await fspContract.methods
            .shouldDepositSeedAsGuarantee()
            .call({ from: state.walletAddress })
            .then((res) => {
              step = res ? STEP_INITIALIZE_CASHBACK : STEP_INITIALIZE_CAMPAIGN
              web3Dispatch({ type: SET_DEPLOYED_SEED_CASHBACK_ENABLED, payload: res })
            });
        }
      }

      if (step === STEP_INITIALIZE_CASHBACK) {
        await cashbackContract.methods
          .FSPAddresses(campaignId)
          .call({ from: state.walletAddress })
          .then((res) => {
            if (res !== NULL_ADDRESS) step = STEP_COMPLETED
          });
      }

      if (step === STEP_INITIALIZE_CAMPAIGN) {
        await fspContract.methods
          .isFundFinanceable()
          .call({ from: state.walletAddress })
          .then((res) => {
            if (res) step = STEP_COMPLETED
          });
      }

      web3Dispatch({ type: SET_STEP, payload: step })
    } catch (error) {
      web3Dispatch({ type: CANCEL_WEB3_LOADING });
    }
  };

  useEffect( async () => {
    if (state.walletAddress && config && status?.deploy?.campaignId && state?.applications) {
      // TODO - LINE BELOW SHOULDN'T BE NEEDED, SO IT WAS COMMENTED TO AVOID DUPLICATE CALLS ON PAGE ARRIVAL
      // const { applications } = await getDataAndContracts();

      await getCampaignCurrentStatus(state?.applications, status.deploy.campaignId, status.deploy.contracts);
      web3Dispatch({ type: CANCEL_WEB3_LOADING });

      // TODO - WHY SHOULD WE RESET THE STEPS WHEN WE DISCONNECT THE WALLET?
      // AS IT IS, THIS SHOULD NEVER HAPPEN
      // if (!state.walletAddress) {
      //   web3Dispatch({ type: RESET_STEPS });
      // }
    }
  }, [state.walletAddress, config, status?.deploy?.campaignId, state?.applications]);

  // useEffect(() => {
  //   if (state.isCampaignDurationSetted && state.isEconomicDataSetted) {
  //     web3Dispatch({ type: INCREASE_STEP });
  //   }
  // }, [state.isCampaignDurationSetted, state.isEconomicDataSetted]);

  useEffect(() => {
    if(window.ethereum){ 
      if (state.applications) {
        const bcIdFromGeneral = config.blockchains.find((bc) => {
          return bc.name === state.applications.blockchain;
        }).chainId;

        web3Dispatch({
          type: SET_IS_BLOCKCHAIN_WRONG,
          payload: parseInt(window.ethereum.networkVersion) !== bcIdFromGeneral,
        });

        window.ethereum.on("chainChanged", (e) => {
          console.log('CHANGED', state.applications, e);
          const bc = config.blockchains.find((bc) => {
            return bc.chainId === parseInt(e, 16);
          });
          console.log(bc);

          web3Dispatch({
            type: SET_IS_BLOCKCHAIN_WRONG,
            payload: bc.name !== state.applications.blockchain,
          });
        });
      }

      return () => {
        window.ethereum.removeAllListeners("chainChanged");
      }
  }
  }, [state.applications]);

  useEffect(() => {
    if (state.isBlockChainWrong) {
      toast.error(
        strings.formatString(connectWallet.switchNetwork, {
          blockchain: valueToLabel(state.applications?.blockchain, config),
        }),
        {
          toastId: 99,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          closeButton: false,
          style: {
            cursor: "initial",
          },
        }
      );
    } else {
      toast.dismiss(99);
    }
  }, [state.isBlockChainWrong, state.applications?.blockchain]);

  return (
    <Web3Context.Provider
      value={{ ...state, getDataAndContracts, web3Dispatch }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3Context = () => {
  return useContext(Web3Context);
};

export default AppProvider;
