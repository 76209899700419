import { apiCall } from "../api";

export const getDocsService = (appDispatch) => {
  const config = {
    method: "get",
    url: "/fundraising/documents",
  };

  return apiCall(config, appDispatch);
};

export const postDoc = async (doc, appDispatch) => {
  const formData = new FormData();
  formData.append("documentType", doc?.type);
  formData.append("name", doc?.doc?.name);
  formData.append("file", doc?.doc);

  const config = {
    method: "post",
    url: `/fundraising/documents`,
    data: formData,
  };

  return await apiCall(config, appDispatch);
};

export const deleteDoc = async (id, appDispatch) => {
  const config = {
    method: "delete",
    url: `/fundraising/documents/${id}`,
  };

  return await apiCall(config, appDispatch);
};

export const postAllDocs = async (docsToPost, appDispatch) => {
  return Promise.all(docsToPost.map((doc) => postDoc(doc, appDispatch)));
};

// const deleteAllDocs = async (tagsToDelete, appDispatch) => {
//   return Promise.all(tagsToDelete.map((id) => deleteDoc(id, appDispatch)));
// };

// export const postAndDeleteDocsService = async (
//   docsToPost,
//   docsToDelete,
//   informativeAgree,
//   appDispatch
// ) => {
//   try {
//     let newDocsToPost = [...docsToPost];

//     if (informativeAgree instanceof File) {
//       newDocsToPost = [
//         ...docsToPost,
//         { doc: informativeAgree, type: "informativeAgreement" },
//       ];
//     }

//     const postData = await postAllDocs(newDocsToPost, appDispatch);
//     const deleteData = await deleteAllDocs(docsToDelete, appDispatch);
//     if (
//       (!!postData?.length && postData.length > 0) ||
//       (!!deleteData?.length && deleteData.length > 0)
//     ) {
//       const response = await getDocsService(appDispatch);
//       return response;
//     }
//     return false;
//   } catch (error) {
//     throw error;
//   }
// };
