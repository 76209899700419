/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import './DashboardPage.scss';

import { useAppContext } from '../../context/store';

// Components
import {
  Wrapper,
  Navbar,
  ApplyStartModal,
  ChangePassModal,
  Spinner,
  Input,
  DashboardProgressBar,
  DashboardStartupProfile,
  DashboardLastDeployAddresses,
  DashboardPreviews,
  DashboardCampaignEnded,
} from '../../components';

import Block from '../../components/dashboard/block/Block';
import BlockItem from '../../components/dashboard/BlockItem/BlockItem';
import Button from '../../components/common/button/Button';

// icons
import { fundraise_icon, profile_icon, timer_icon, eye_icon, coin_icon, link_icon } from '../../assets/svg';
import seed_icon from '../../assets/ico/seed_icon.ico';
import { FiLoader } from 'react-icons/fi';

import { useWeb3Context } from '../../context/web3Store';
import { toast } from 'react-toastify';
import { valueToLabel } from '../../components/fundraise/general/General';

// Services
import {
  deployCampaignService,
  getStatusService,
} from '../../services/dashboardServices';

// Helpers
import { handleundefined } from '../../utils/helperFunctions';

// ----- web3 factory methods -----

import {
  getCashbackContract,
  getFactoryContract,
  getFspContract,
  getSeedContract,
} from '../../web3Wrapper/web3Helpers';
import {
  RESET_STEPS,
  SET_DEPLOY_CONTRACTS,
  SET_FSP_CONTRACT,
  SET_STEP,
  STEP_INITIALIZE_CASHBACK,
  STEP_COMPLETED,
  SET_NEW_CAMPAIGN,
  STEP_INIT,
  STEP_CONTRACT_DATA,
  STEP_INITIALIZE_CAMPAIGN
} from '../../context/web3Reducer';
import { DEPLOY_CAMPAIGN, SET_STATUS } from '../../context/reducer';

import {
  factory_deployCampaign,
  factory_getFSPIndexByAddress,
} from '../../web3Wrapper/factory_methods';

// ----- web 3 FSP_methods -----

import {
  fsp_setCampaignDuration,
  fsp_setEconomicData,
  fsp_checkCampaignStatus,
  fsp_setFSPFinanceable
} from '../../web3Wrapper/fsp_methods';

import { seed_approve } from '../../web3Wrapper/seed_methods';

import {
  cashback_addFSPtoCashback,
  cashback_addLeadInvestorAmountToFSP,
  cashback_leadInvestorFSPAmounts
} from '../../web3Wrapper/cashbackMethods';
import {
  getAddressByToken,
  getDecimalsByToken,
} from '../../web3Wrapper/helpers';
import SummaryPreviewModal from '../../components/SummaryPreviewModal/SummaryPreviewModal';

const DashboardPage = () => {
  const {
    isLoading,
    appDispatch,
    config,
    strings,
    status,
    requirePasswordChange,
    isLogged,
    isCampaignOutOfDate,
    connectWallet,
  } = useAppContext();

  const {
    walletAddress,
    web3Loading,
    web3Dispatch,
    applications,
    general,
    idea,
    media,
    documents,
    fspContract,
    isEconomicDataSetted,
    isCampaignDurationSetted,
    campaignStep,
    getDataAndContracts,
    isBlockChainWrong,
  } = useWeb3Context();

  const { dashboard } = strings;

  const [showPassModal, setShowPassModal] = useState(false);
  const [showApplyStartModal, setshowApplyStartModal] = useState(false);
  const [showSummaryPreviewModal, setShowSummaryPreviewModal] = useState(false);
  const [seedAmount, setSeedAmount] = useState(0);
  const [campaignIndexByAddress, setCampaignIndexByAddress] = useState(null);
  const [isMainnet, setIsMainnet] = useState(null)

  const history = useHistory();

  const handleGoToFundraisePage = () => {
    const isFirstEdit = JSON.parse(localStorage.getItem('isFirstEdit'));
    if (isFirstEdit === null) {
      setshowApplyStartModal(true);
      localStorage.setItem('isFirstEdit', 'false');
    } else {
      localStorage.setItem('isFirstEdit', 'false');
      history.push('/fundraise');
    }
  };

  const mapStatus = (value) => {
    switch (value) {
      case 'EDITABLE':
        return dashboard.profile.starting;
      case 'DEPLOYED':
        return dashboard.profile.deployed;

      default:
        return handleundefined(value);
    }
  };

  const getStatus = async () => {
    try {
      const { data } = await getStatusService(appDispatch);

      appDispatch({ type: SET_STATUS, payload: data });
    } catch (error) {

    }
  };

  const isDeployDisabled = (filled, required, disable) => !(filled === required) || disable;

  const fspContractAddress = status?.deploy?.contracts.find(
    (contract) => contract.name === 'fspContract'
  )?.address;

  const testBlockchain = useMemo(() => config.defaultParameters.defaultTestnet, [config])
  const testPaymentToken = useMemo(() => config.defaultParameters.defaultTestPaymentToken, [config])
  const blockchainToDeploy = useMemo(() => {
    if (isMainnet === null || isMainnet === undefined) return null
    return isMainnet ? applications?.blockchain : testBlockchain}
  , [isMainnet, testBlockchain, applications?.blockchain])
  const paymentTokenToDeploy = useMemo(() => {
    if (isMainnet === null || isMainnet === undefined) return null
    return isMainnet ? applications?.paymentToken : testPaymentToken
  }, [isMainnet, testPaymentToken, applications?.paymentToken])

  // const blockchainName = applications?.blockchain
  // const cashbackContractAddress = config?.smartContractsParameters?.[blockchainName]?.addresses?.Cashback;
  const campaignID = campaignIndexByAddress ? campaignIndexByAddress : status?.deploy?.campaignId;

  useEffect(() => {
    const checkTesnet =
      config.blockchains.find(
        (blockchain) => blockchain.name === applications?.blockchain
      )?.netType === 'testnet';

    if (status?.status === 'DEPLOYED' && !checkTesnet) {
      appDispatch({ type: DEPLOY_CAMPAIGN });
    }

    setIsMainnet(status?.deploy?.productionDeploy ?? null)
  }, [status]);

  useEffect(() => {
    if (requirePasswordChange) {
      setShowPassModal(true);
    }
  }, [requirePasswordChange]);

  useEffect(() => {
    if (isLogged) {
      getStatus();
    }
  }, [isLogged]);

  useEffect(async () => {
    if (blockchainToDeploy && paymentTokenToDeploy && campaignID && walletAddress) {
      const cashContract = await getCashbackContract(blockchainToDeploy, config);
      const decimals = await getDecimalsByToken(paymentTokenToDeploy, config);
      const seedAmount =  await cashback_leadInvestorFSPAmounts(cashContract, campaignID.toString(), walletAddress, decimals);
      setSeedAmount(seedAmount);
    }
  }, [status, blockchainToDeploy, paymentTokenToDeploy, seedAmount, campaignID]);

  // ------------------------- WEB3 PART ----------------------------------------------

  const [showDeployModal, setShowDeployModal] = useState(false);

  const handleInfoModalText = (step) => {
    switch (step) {
      case STEP_INIT: case STEP_COMPLETED:
        return strings.formatString(dashboard.modalInfoDeployCampaign, {
          blockchain: valueToLabel(blockchainToDeploy, config),
        });
      case STEP_CONTRACT_DATA:
        if (economicDataLoading) {
          return dashboard.modalInfoSetEcData;
        } else {
          return dashboard.modalInfoSetCampDuration;
        }
      case STEP_INITIALIZE_CASHBACK:
        return dashboard.modalInfoInitializeCashback;

      case STEP_INITIALIZE_CAMPAIGN:
        return dashboard.modalInfoInitializeCampaign;

      default:
        break;
    }
  };

  // ---------------------------- DEPLOY CAMPAIGN ------------------------------

  const [deployCampaignTestnetLoading, setDeployCampaignTestnetLoading] = useState(false);
  const [deployCampaignMainnetLoading, setDeployCampaignMainnetLoading] = useState(false);
  const [dashboardLastDeployAddressesProps, setDashboardLastDeployAddressesProps] = useState();

  const handleDeployCampaign = async (mainnet) => {
    setDeployCampaignTestnetLoading(!mainnet)
    setDeployCampaignMainnetLoading(mainnet);
    setShowDeployModal(true);
    const informative_agreement = documents?.find((document) => document.name === "informative_agreement")?.link
    try {
      const factoryContract = await getFactoryContract(
        config.blockchains.find((blockchain) => blockchain.name === (mainnet ? applications?.blockchain : testBlockchain)).name,
        config
      );

      await factory_deployCampaign(
        general?.tokenName,
        general?.tokenSymbol,
        informative_agreement,
        general?.exchangeRate,
        general?.maxCap,
        config.smartContractsParameters.WLEmissionAnonymThr,
        config.smartContractsParameters.WLTransferAnonymThr,
        factoryContract,
        walletAddress,
        getDecimalsByToken(mainnet ? applications?.paymentToken : testPaymentToken, config),
        config,
        handleCampaignDeployed,
        setIsMainnet,
        mainnet
      );
      setShowDeployModal(false);
      web3Dispatch({ type: SET_NEW_CAMPAIGN });
      toast.success(dashboard.newCampaign);
    } catch (error) {
      toast.error(error?.message || dashboard.genericErrorTransaction);
      setDeployCampaignTestnetLoading(false);
      setDeployCampaignMainnetLoading(false);
      setShowDeployModal(false);
    }
  };

  const handleCampaignDeployed = useCallback(async (campaignDeployed, mainnet) => {
    if (status?.status !== 'DEPLOYED' || !mainnet) {
      const contracts = [
        {
          name: 'admintToolsContract',
          address:
            campaignDeployed?.events?.NewPanelCreated?.returnValues
              ?.admintTools,
        },
        {
          name: 'creatorContract',
          address:
            campaignDeployed?.events?.NewPanelCreated?.returnValues?.creator,
        },
        {
          name: 'fspContract',
          address: campaignDeployed?.events?.NewPanelCreated?.returnValues?.fsp,
        },
        {
          name: 'fundTokenContract',
          address:
            campaignDeployed?.events?.NewPanelCreated?.returnValues?.fundToken,
        },
        {
          name: 'wrapperContract',
          address:
            campaignDeployed?.events?.NewPanelCreated?.returnValues?.wrapper,
        },
      ];
      await deployCampaignService(
        appDispatch,
        campaignDeployed?.events?.NewPanelCreated?.returnValues?.deployedFSPs -
          1,
        contracts,
        mainnet
      );
      web3Dispatch({ type: SET_DEPLOY_CONTRACTS, payload: contracts });
      getStatus()
    }

    const fspContract = await getFspContract(
      campaignDeployed?.events?.NewPanelCreated?.returnValues?.fsp
    );

    const deployAddressesProps = {
      "fspContractAddress": campaignDeployed?.events?.NewPanelCreated?.returnValues?.fsp,
      "foundTokenContractAddress":campaignDeployed?.events?.NewPanelCreated?.returnValues?.fundToken

    }
    web3Dispatch({ type: SET_FSP_CONTRACT, payload: fspContract });

    setDeployCampaignTestnetLoading(false);
    setDeployCampaignMainnetLoading(false);
    setShowDeployModal(false);

    setDashboardLastDeployAddressesProps(deployAddressesProps)
  }, []);

  useEffect(() => {
    setEconomicDataDisabled(isEconomicDataSetted ?? false);
    setCampaignDurationDisabled(isCampaignDurationSetted ?? false);
  }, [isEconomicDataSetted, isCampaignDurationSetted]);

  // --------------------- SET ECONOMIC DATA ---------------------

  const [economicData, setEconomicData] = useState();
  const [economicDataLoading, setEconomicDataLoading] = useState(false);
  const [economicDataDisabled, setEconomicDataDisabled] = useState(false);

  const handleSetEconomicData = async () => {
    const tokenAddress = getAddressByToken(paymentTokenToDeploy, blockchainToDeploy, config);
    setShowDeployModal(false);
    setEconomicDataLoading(true);
    setEconomicDataDisabled(true);
    const minimumInvestorSeedBalanceToString = (general.minimumInvestorSeedBalance).toString();
    try {
      await fsp_setEconomicData(
        general?.exchangeRate,
        general?.exchangeRate,
        tokenAddress,
        applications?.target,
        general?.maxCap,
        minimumInvestorSeedBalanceToString,
        general.seedCashbackEnabled,
        fspContract,
        walletAddress,
        setEconomicData,
        getDecimalsByToken(paymentTokenToDeploy, config),
        config
      );

      toast.success(dashboard.successFullTransaction);
      setEconomicDataLoading(false);
      setShowDeployModal(false);
    } catch (error) {
      toast.error(error?.message || error);
      setEconomicDataDisabled(false);
      setEconomicDataLoading(false);
    }
  };

  // --------------------- CAMPAIGN DURATION ---------------------

  const [campaignDuration, setCampaignDuration] = useState();

  const [campaignDurationLoading, setCampaignDurationLoading] = useState(false);
  const [campaignDurationDisabled, setCampaignDurationDisabled] = useState(isCampaignDurationSetted);

  const handleSetCampaignDuration = async () => {
    setCampaignDurationLoading(true);
    setCampaignDurationDisabled(true);
    setShowDeployModal(true);

    try {
      await fsp_setCampaignDuration(
        fspContract,
        walletAddress,
        setCampaignDuration,
        general?.durationBlock
      );
      toast.success(dashboard.successFullTransaction);
      setCampaignDurationLoading(false);
    } catch (error) {
      toast.error(error?.message || error);
      setCampaignDurationDisabled(false);
      setCampaignDurationLoading(false);
    }
    setShowDeployModal(false);
  };

  // TODO - WHEN ECONOMIC DATA IS SET, THIS USEEFECCT IS NOT EXECUTED
  useEffect(() => {
    if (economicData) {
      web3Dispatch({ type: SET_STEP, payload: general?.seedCashbackEnabled ? STEP_INITIALIZE_CASHBACK : STEP_INITIALIZE_CAMPAIGN })
    }
  }, [economicData]);

  // ------------------------ INITIALIZE CASHBACK --------------------------

  const [fspToCashback, setFspToCashback] = useState();
  const [disabledCashbackButton, setDisabledCashbackButton] = useState(false);
  const [loadingCashback, setLoadingCashback] = useState(false);

  const handleInitializeCashback = async () => {
    setDisabledCashbackButton(true);
    setLoadingCashback(true);
    setShowDeployModal(true);

    const factoryContract = await getFactoryContract(
      blockchainToDeploy,
      config
    );

    const fspAddress = fspContract._address;

    if (fspAddress) {
      await factory_getFSPIndexByAddress(
        factoryContract,
        walletAddress,
        setCampaignIndexByAddress,
        fspAddress
      );
    } else {
      const cashbackContract = await getCashbackContract(
        blockchainToDeploy,
        config
      );

      try {
        await cashback_addFSPtoCashback(
          cashbackContract,
          walletAddress,
          setFspToCashback,
          campaignID,
          config.smartContractsParameters.minSeedThr,
          config.smartContractsParameters.maxSeedThr,
          general.seedCashbackDuration,
          getDecimalsByToken(paymentTokenToDeploy, config)
        );
        setLoadingCashback(false);
        setShowDeployModal(false);
        web3Dispatch({ type: SET_STEP, payload: STEP_COMPLETED });
      } catch (error) {
        toast.error(error?.message || error);
        setDisabledCashbackButton(false);
        setLoadingCashback(false);
        setShowDeployModal(false);
      }
    }
  };

  useEffect(async () => {
    const cashbackContract = await getCashbackContract(
      blockchainToDeploy,
      config
    );

    try {
      if (campaignIndexByAddress) {
        await cashback_addFSPtoCashback(
          cashbackContract,
          walletAddress,
          setFspToCashback,
          campaignIndexByAddress,
          config.smartContractsParameters.minSeedThr,
          config.smartContractsParameters.maxSeedThr,
          general.seedCashbackDuration,
          getDecimalsByToken(paymentTokenToDeploy, config)
        );
        setLoadingCashback(false);
        setShowDeployModal(false);
        web3Dispatch({ type: SET_STEP, payload: STEP_COMPLETED });
      }
    } catch (error) {
      toast.error(error?.message || error);
      setDisabledCashbackButton(false);
      setLoadingCashback(false);
      setShowDeployModal(false);
    }
  }, [campaignIndexByAddress]);

  const [seedApprove, setSeedApprove] = useState(null);
  const [seedStep, setSeedStep] = useState(0);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [disabledSeed, setDisabledSeed] = useState(false);
  const [loadingSeed, setLoadingSeed] = useState(false);

  const [addLeadInvestor, setAddLeadInvestor] = useState(null);

  // ------------------------ INITIALIZE CAMPAIGN --------------------------

  const [fspToCampaign, setFspToCampaign] = useState();
  const [disabledCampaignButton, setDisabledCampaignButton] = useState(false);
  const [loadingCampaign, setLoadingCampaign] = useState(false);

  const handleInitializeCampaign = async () => {
    setDisabledCampaignButton(true);
    setLoadingCampaign(true);
    setShowDeployModal(true);

    const fspContract = await getFspContract(fspContractAddress);

    try {
      await fsp_setFSPFinanceable(
        fspContract,
        walletAddress,
        setFspToCampaign
      );
      setLoadingCampaign(false);
      setShowDeployModal(false);
      web3Dispatch({ type: SET_STEP, payload: STEP_COMPLETED });
    } catch (error) {
      toast.error(error?.message || error);
      setDisabledCampaignButton(false);
      setLoadingCampaign(false);
      setShowDeployModal(false);
    }
  };

  // ------------------------- SEED APPROVE --------------------------
  const handleSeedApprove = async () => {
    const seedContract = await getSeedContract(blockchainToDeploy, config);
    const spenderAddress = await config.smartContractsParameters[blockchainToDeploy]?.addresses?.Cashback;

    try {
      setDisabledSeed(true);
      setLoadingSeed(true);
      await seed_approve(
        seedContract,
        walletAddress,
        setSeedApprove,
        spenderAddress, // walletAddress,
        currentAmount,
        config
      );
      setSeedStep((prev) => prev + 1);
    } catch (error) {
      toast.error(error?.message || dashboard.genericErrorTransaction);
    }
    setLoadingSeed(false);
    setDisabledSeed(false);
  };

  // ------------------------- SEED TRANSFER --------------------------
  const handleSendSeed = async () => {
    try {
      setDisabledSeed(true);
      setLoadingSeed(true);
      const cashbackContract = await getCashbackContract(blockchainToDeploy, config);

      await cashback_addLeadInvestorAmountToFSP(
        cashbackContract,
        walletAddress,
        setAddLeadInvestor,
        walletAddress,
        campaignID,
        currentAmount,
        config
      );
      // the line below is used to display the total seeds immediately
      setSeedAmount((currentSeedAmmount) => Number(currentSeedAmmount) + Number(currentAmount));
      setSeedStep(0);
      setCurrentAmount(0);
    } catch (error) {
      toast.error(error?.message || dashboard.genericErrorTransaction);
    }
    setLoadingSeed(false);
    setDisabledSeed(false);
  };

  useEffect(() => {
    getDataAndContracts();
  }, []);

  useEffect(() => {
    return () => {
      web3Dispatch({ type: RESET_STEPS });
    }
  }, [])

  // ------------------------- GET DATA OF FSP_CONTRACT to check campaign status --------------------------

  const [campaignEnded, setCampaignEnded] = useState();

  useEffect(() => {
    campaignDetails();
  }, [fspContractAddress]);

  const campaignDetails = async () => {
    if (window.ethereum) {
      try {
        const fspContract = await getFspContract(dashboardLastDeployAddressesProps?.fspContractAddress ?? fspContractAddress);
        const responseData = await fsp_checkCampaignStatus(fspContract);
        //const ownerData = await fsp_getOwnerData(fspContract);

        setCampaignEnded(responseData);
      } catch (error) {

      }
    } else {
      toast.error(connectWallet.errorInstall);
    }
  };

  // --------------------RENDERING COMPONENTS ----------------------

  if (isLoading || !config || web3Loading)
    return (
      <Spinner
        wrapperStyle={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    );

  return (
    <>
      {showSummaryPreviewModal && (
        <SummaryPreviewModal
          data={{ ...applications, ...general, ...idea, ...media }}
          onClose={() => {
            setShowSummaryPreviewModal(false);
          }}
        />
      )}
      {showApplyStartModal && <ApplyStartModal />}
      {showPassModal && (
        <ChangePassModal
          onClose={() => {
            setShowPassModal(false);
          }}
        />
      )}
      <Navbar />

      <Wrapper>
        {showDeployModal && (
          <div className="dashboard__info-modal">
            <h3 className="dashboard__info-modal-title">
              <FiLoader />
              Loading
            </h3>
            <p>{handleInfoModalText(campaignStep)}</p>
          </div>
        )}
        <div className="block-container">
          <Block title={dashboard.profile.profile} icon={profile_icon}>
            <DashboardStartupProfile setShowPassModal={setShowPassModal} />
          </Block>
          {(campaignStep === STEP_INIT || !isMainnet) && (
            <Block title={dashboard.fundraise.fundraise} icon={fundraise_icon}>
              <BlockItem
                type="button"
                onClick={handleGoToFundraisePage}
                label={dashboard.fundraise.status}
                value={
                  <span className="font-S-700">
                    {mapStatus(status?.status)}
                  </span>
                }
                textButton={dashboard.fundraise.edit}
                disableButton={status?.status === 'DEPLOYED' || isCampaignOutOfDate}
              />
              {status?.completionPercentage !== 100 ? (
                <BlockItem
                  type="button"
                  // onClick={handleDeployCampaign}
                  value={
                    status?.completionPercentage !== 100 && (
                      <span>
                        {dashboard.fundraise.mandatoryFields}{' '}
                        {handleundefined(status?.compiledFields)}/
                        {handleundefined(status?.requiredFields)} (
                        <span className="font-S-700 col-primary">
                          {handleundefined(status?.completionPercentage)}%
                        </span>
                        )
                      </span>
                    )
                  }
                  disableButton={
                    isDeployDisabled(
                      status?.compiledFields,
                      status?.requiredFields,
                      !walletAddress
                    ) ||
                    deployCampaignTestnetLoading ||
                    deployCampaignMainnetLoading ||
                    isBlockChainWrong
                  }
                  textButton={'Deploy'}
                  buttonStyle={{
                    textTransform: 'capitalize',
                    fontSize: 13,
                  }}
                  loading={deployCampaignTestnetLoading}
                />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: 40 }}>
                  {/* todo : temporary remove of testnet */}
                  {/*<Button*/}
                  {/*  onClick={() => handleDeployCampaign(false)}*/}
                  {/*  loading={deployCampaignTestnetLoading}*/}
                  {/*  style={{ width: '50%', height: '100%', marginRight: '8px', }}*/}
                  {/*  disabled={*/}
                  {/*    isDeployDisabled(*/}
                  {/*      status?.compiledFields,*/}
                  {/*      status?.requiredFields,*/}
                  {/*      !walletAddress*/}
                  {/*    ) ||*/}
                  {/*    deployCampaignTestnetLoading ||*/}
                  {/*    deployCampaignMainnetLoading ||*/}
                  {/*    isBlockChainWrong ||*/}
                  {/*    (campaignStep !== STEP_INIT && campaignStep !== STEP_COMPLETED) ||*/}
                  {/*  isCampaignOutOfDate*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  {walletAddress ? strings.formatString(dashboard.fundraise.deploy, {*/}
                  {/*        blockchain: valueToLabel(testBlockchain, config) ?? "testnet",*/}
                  {/*      }): dashboard.fundraise.deployNoBc}*/}
                  {/*</Button>*/}
                  <Button
                    onClick={() => handleDeployCampaign(true)}
                    loading={deployCampaignMainnetLoading}
                    style={{ width: '50%', height: '100%', marginLeft: '8px' }}
                    disabled={
                      isDeployDisabled(
                        status?.compiledFields,
                        status?.requiredFields,
                        !walletAddress
                      ) ||
                      deployCampaignTestnetLoading ||
                      deployCampaignMainnetLoading ||
                      isBlockChainWrong ||
                      (campaignStep !== STEP_INIT && campaignStep !== STEP_COMPLETED) ||
                    isCampaignOutOfDate || status?.status === "DEPLOYED"
                    }
                  >
                    {walletAddress ? strings.formatString(dashboard.fundraise.deploy, {
                          blockchain: valueToLabel(applications?.blockchain, config) ?? "Ethereum mainnet",
                        }): dashboard.fundraise.deployNoBc}
                  </Button>
                </div>
              )}
            </Block>
          )}
          {campaignStep === STEP_CONTRACT_DATA && (
            <Block
              title={dashboard.fundraise.fundraise}
              icon={fundraise_icon}
              contentStyle={{
                height: 110,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  gap: 'var(--dim-48)',
                }}
              >
                <Button
                  style={{ width: '100%', padding: 8 }}
                  onClick={handleSetCampaignDuration}
                  disabled={
                    campaignDurationDisabled ||
                    economicDataLoading ||
                    isBlockChainWrong ||
                  isCampaignOutOfDate
                  }
                  loading={campaignDurationLoading}
                >
                  {campaignDuration || campaignDurationDisabled
                    ? dashboard.completed
                    : dashboard.setCampaignDuration}
                </Button>
                <Button
                  style={{ width: '100%', padding: 8 }}
                  onClick={handleSetEconomicData}
                  loading={economicDataLoading}
                  disabled={
                    economicDataDisabled ||
                    campaignDurationLoading ||
                    !campaignDurationDisabled ||
                    isBlockChainWrong ||
                  isCampaignOutOfDate
                  }
                >
                  {economicData || economicDataDisabled
                    ? dashboard.completed
                    : dashboard.setEconomicData}
                </Button>
              </span>
            </Block>
          )}
          {campaignStep === STEP_INITIALIZE_CASHBACK && (
            <Block
              title={dashboard.fundraise.fundraise}
              icon={fundraise_icon}
              contentStyle={{
                height: 110,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  gap: 'var(--dim-48)',
                }}
              >
                <Button
                  style={{ width: '100%', padding: 8 }}
                  onClick={handleInitializeCashback}
                  loading={loadingCashback}
                  disabled={disabledCashbackButton || isBlockChainWrong || isCampaignOutOfDate}
                >
                  {fspToCashback
                    ? dashboard.completed
                    : dashboard.initializeCashback}
                </Button>
              </span>
            </Block>
          )}
          {campaignStep === STEP_INITIALIZE_CAMPAIGN && (
            <Block
              title={dashboard.fundraise.fundraise}
              icon={fundraise_icon}
              contentStyle={{
                height: 110,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  gap: 'var(--dim-48)',
                }}
              >
                <Button
                  style={{ width: '100%', padding: 8 }}
                  onClick={handleInitializeCampaign}
                  loading={loadingCampaign}
                  disabled={disabledCampaignButton || isBlockChainWrong || isCampaignOutOfDate}
                >
                  {fspToCampaign
                    ? dashboard.completed
                    : dashboard.initializeCampaign}
                </Button>
              </span>
            </Block>
          )}
          {campaignStep === STEP_COMPLETED && (
            <Block
              title={dashboard.fundraise.fundraise}
              icon={fundraise_icon}
              contentStyle={{
                height: 110,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'var(--color-primary)',
              }}
            >
            <span>{  dashboard.campaignDeployed  }</span>

            </Block>
          )}
          {campaignStep === STEP_COMPLETED && general?.seedCashbackEnabled && !campaignEnded?.campaignOver && (
            <Block title="Seed Token" icon={seed_icon}  className="preview-block">
              <div className="seed-container">
                {/* <p className="seed-block-text"></p> */}
                <span className="seed-input-container">
                  <Input
                    label={"Add Seeds ( Total Seeds: " + (seedAmount || 0) + " )"}
                    value={ (currentAmount || 0)}
                    onChange={(e) => setCurrentAmount(e.target.value)}
                    type="number"
                    min="0"
                    disabled={disabledSeed || isBlockChainWrong ||isCampaignOutOfDate}
                  />
                  <Button
                    onClick={() => {
                      if (seedStep === 1) {
                        handleSendSeed();
                      } else if (seedStep === 0) {
                        handleSeedApprove();
                      }
                    }}
                    disabled={
                      !walletAddress ||
                      !currentAmount ||
                      disabledSeed ||
                      isBlockChainWrong ||
                    isCampaignOutOfDate
                    }
                    loading={loadingSeed}
                  >
                   {dashboard?.sendSeed?.["seedStep" + seedStep]}
                  </Button>
                </span>
              </div>
            </Block>
          )}
          {
            /*status?.status === "DEPLOYED" &&*/ status?.deploy?.contracts && (
              <Block
                className="preview-block"
                title={dashboard.addresses.addresses}
                icon={link_icon}
              >
                <DashboardLastDeployAddresses />
              </Block>
            )
          }
          <Block
            className="preview-block"
            title={dashboard.previews.previews}
            icon={eye_icon}
          >
            <DashboardPreviews setShowSummaryPreviewModal={setShowSummaryPreviewModal} />
          </Block>
          {(campaignEnded?.campaignOver) && (
            <Block
              className="preview-block"
              title={
                (campaignEnded?.campaignSuccessful)
                  ? dashboard.campaignEnded.mainTitleSuccess
                  : dashboard.campaignEnded.mainTitleFail
              }
              titleStyle={(!campaignEnded?.campaignSuccessful) ? { color: "var(--color-error)" } : undefined}
              icon={coin_icon}
            >
              <DashboardCampaignEnded
                campaignSuccess={campaignEnded?.campaignSuccessful}
                fspContractAddress={fspContractAddress}
                fspNumber={campaignID}
                blockchain={applications?.blockchain}
                details={status?.deploy?.claims?.[0]}
                getStatus={getStatus}
                seedAmount={seedAmount}
              />
            </Block>
          )}
          {status?.status !== 'DEPLOYED' &&  (
            <Block
              className="preview-block"
              title={dashboard.deadLine.title}
              icon={timer_icon}
            >
              <DashboardProgressBar />
            </Block>
          )}

          <div style={{ flex: 1, maxWidth: 540, minWidth: 380 }}></div>
        </div>
      </Wrapper>
    </>
  );
};

export default DashboardPage;
