import React from 'react';
import { useHistory } from 'react-router-dom';
import '../../../pages/dashboardPage/DashboardPage.scss';

// Store
import { useAppContext } from '../../../context/store';

// Components
import Button from '../../common/button/Button';

const DashboardPreviews = ({ setShowSummaryPreviewModal }) => {
  const { strings } = useAppContext();
  const { dashboard } = strings;

  const history = useHistory();

  return (
    <div className="preview-container">
      <Button
        style={{ padding: '8px' }}
        onClick={() => {
          setShowSummaryPreviewModal(true);
        }}
        loading={false}
      >
        {dashboard.previews.summary}
      </Button>
      <Button
        style={{ padding: '8px' }}
        onClick={() => {
          history.push('/detail');
        }}
        loading={false}
      >
        {dashboard.previews.detail}
      </Button>
    </div>
  );
};

export default DashboardPreviews;
