import React from "react";

//Store
import { useAppContext } from "../../../context/store";

// Helpers
import { getUserLoginData } from "../../../storage";
import { handleundefined } from "../../../utils/helperFunctions";

// Components
import BlockItem from "../BlockItem/BlockItem";

const DashboardStartupProfile = ({ setShowPassModal }) => {
  const { strings } = useAppContext();
  const { dashboard } = strings;

  return (
    <>
      <BlockItem
        label={dashboard.profile.username}
        value={handleundefined(getUserLoginData()?.username)}
      />
      <BlockItem
        type="change"
        onClick={() => {
          setShowPassModal(true);
        }}
        label={dashboard.profile.password}
        value={dashboard.profile.passwordPlaceholder}
      />
    </>
  );
};

export default DashboardStartupProfile;
