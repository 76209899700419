import { apiCall } from "../api";

export const getTagsService = (appDispatch) => {
  const config = {
    method: "get",
    url: "/fundraising/tags",
  };

  return apiCall(config, appDispatch);
};

export const postTag = async (tag, appDispatch) => {
  const formData = new FormData();
  formData.append("tag", tag);

  const config = {
    method: "post",
    url: `/fundraising/tags`,
    data: formData,
  };

  return await apiCall(config, appDispatch);
};

export const deleteTag = async (id, appDispatch) => {
  const config = {
    method: "delete",
    url: `/fundraising/tags/${id}`,
  };

  return await apiCall(config, appDispatch);
};

// const postAllTags = async (tagToPost, appDispatch) => {
//   return Promise.all(tagToPost.map((tag) => postTag(tag?.tag, appDispatch)));
// };

// const deleteAllTags = async (tagsToDelete, appDispatch) => {
//   return Promise.all(tagsToDelete.map((id) => deleteTag(id, appDispatch)));
// };

// export const postAndDeleteTagsService = async (
//   tagsToPost,
//   tagsToDelete,
//   appDispatch
// ) => {
//   try {
//     const deleteData = await deleteAllTags(tagsToDelete, appDispatch);
//     const postData = await postAllTags(tagsToPost, appDispatch);

//     if (
//       (!!postData?.length && postData.length > 0) ||
//       (!!deleteData?.length && deleteData.length > 0)
//     ) {
//       const response = await getTagsService(appDispatch);
//       return response;
//     }
//     return false;
//   } catch (error) {
//     throw error;
//   }
// };
