import React from "react";
import "./GreenSliceSoon.scss";
import {useAppContext} from "../../../context/store";
import ReactTooltip from "react-tooltip";
import {AiOutlineInfoCircle as InfoIcon} from "react-icons/ai";
import {DurationSpan} from '../../../v2/DurationSpan';

const GreenSliceSoon = ({ startupInfo }) => {
    const {
        strings,
        config
    } = useAppContext();

    const numberFormat = new Intl.NumberFormat("en-EN")

    const { greenSlice } = strings;

    return (
        <div className="green-slice">
            <ReactTooltip
                place="right"
                backgroundColor="var(--color-primary)"
                textColor="var(--color-white)"
                effect="solid"
                id="green-slice-tooltip"
            />
            <div className="green-slice__block">
                <div className="green-slice__title">
                    {greenSlice.soon.maximumLabel}
                </div>
                <div className="green-slice__lower-big">
                    {numberFormat.format(startupInfo?.maxCap).replaceAll(",", config.general.decimalSeparator)} {startupInfo?.paymentToken}
                </div>
            </div>
            <div className="green-slice__block">
                <div className="green-slice__title">
                    {greenSlice.soon.targetLabel}
                </div>
                <div className="green-slice__lower-big">
                    {numberFormat.format(startupInfo?.target).replaceAll(",", config.general.decimalSeparator)} {startupInfo?.paymentToken}
                </div>
            </div>
            <div className="green-slice__block">
                <div className="green-slice__title">
                    {greenSlice.soon.durationLabel}
                    <div className="label-tooltip" data-for="green-slice-tooltip" data-tip={greenSlice.soon.durationTooltip}>
                        <InfoIcon />
                    </div>
                </div>
                <div className="green-slice__lower-big">
                    {numberFormat.format(startupInfo?.durationBlock).replaceAll(",", config.general.decimalSeparator)}&nbsp;
                    <div className="green-slice__lower-small">
                        (
                        {greenSlice.soon.estimated}&nbsp;
                        <DurationSpan blocks={startupInfo?.durationBlock} />
                        )
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GreenSliceSoon;
