import React, { useEffect } from "react";
import "./ConnectWalletModal.scss";
import { metamask_logo, twt_logo } from "../../assets/svg";
import { GrClose as CloseIcon } from "react-icons/gr";

import { useWeb3Context } from "../../context/web3Store";
import { useAppContext } from "../../context/store";
import { SET_WALLET_ADDRESS } from "../../context/web3Reducer";

// Web3 helpers
import { web3Connect } from "../../web3Wrapper/web3Helpers";
import { toast } from "react-toastify";

const ConnectWalletModal = ({ setShowConnectModal }) => {
  const {
    strings: { connectWallet },
  } = useAppContext();
  const { walletAddress, web3Dispatch } = useWeb3Context();

  const handleConnectWallet = async () => {
    try {
      const address = await web3Connect();
      web3Dispatch({ type: SET_WALLET_ADDRESS, payload: address });
    } catch (error) {
      if (error?.message) {
        toast.error(connectWallet.error + error?.message);
      } else {
        toast.error(connectWallet.errorInstall);
      }
    }
  };

  useEffect(() => {
    if (walletAddress) {
      setShowConnectModal(false);
    }
  }, [walletAddress]);

  useEffect(() => {
    const { body } = document;

    body.style.overflowY = "hidden";
    return () => (body.style.overflow = "initial");
  }, []);

  return (
    <div className="connect-wallet-modal-overlay">
      <div className="connect-wallet-modal">
        <CloseIcon
          fontSize={24}
          onClick={() => {
            setShowConnectModal(false);
          }}
          color="blue"
          className="connect-wallet-modal__close"
        />
        <h2 className="connect-wallet-modal__title font-XXL-700">
          {connectWallet.connectWallet}
        </h2>
        <div className="connect-wallet-modal__wallet-buttons-container">
          <div
            className="connect-wallet-modal__wallet-button"
            onClick={handleConnectWallet}
          >
            <img src={metamask_logo} alt={connectWallet.metamaskLogoAlt} />
            <h3 className="font-L-700">{connectWallet.metamask}</h3>
            <p className="font-S-400">{connectWallet.metamaskDescr}</p>
          </div>
          {/* <div
            className="connect-wallet-modal__wallet-button"
            onClick={handleConnectWallet}
          >
            <img src={twt_logo} alt="metamask wallet logo" />
            <h3 className="font-L-700">Trustwallet</h3>
            <p className="font-S-400">Connect to your Trustwallet wallet</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ConnectWalletModal;
