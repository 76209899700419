import {useWeb3Context} from '../context/web3Store';
import {useAppContext} from '../context/store';
import LocalizedStrings from 'react-localization';
import {en} from '../utils/localization';
import {useMemo} from 'react';

// todo : deprecate
let strings = new LocalizedStrings({
    en,
});

export function useBlocksDuration(blocks) {
    const { config } = useAppContext();
    const { applications } = useWeb3Context();

    return useMemo(() => {
        // Find blocks speeds from config
        const blockchain = applications?.blockchain
        const secondsPerBlock = config.blockchains.find((configBc) => configBc.name === blockchain)?.secondsPerBlock;
        // Compute all the thresholds
        let seconds = blocks * secondsPerBlock;
        const SECONDS_IN_DAY = 60 * 60 * 24
        const days = Math.floor(seconds / SECONDS_IN_DAY)
        seconds -= days * SECONDS_IN_DAY;
        const SECONDS_IN_HOUR = 60 * 60
        const hours = Math.floor(seconds / SECONDS_IN_HOUR)
        seconds -= days * SECONDS_IN_HOUR;
        const minutes = Math.floor(seconds / 60)
        // Generate the string
        return strings.formatString(strings.general.blocksPerDay, { days, hours, minutes });

    }, [applications?.blockchain, config, blocks])
}

export function DurationSpan({ blocks }) {
    return useBlocksDuration(blocks);
}
