import React, { useMemo } from "react";
// Store
import { useAppContext } from "../../../context/store";
import { useWeb3Context } from "../../../context/web3Store";

const DashboardLastDeployAddresses = () => {
  const { config, strings, status } = useAppContext();
  const { applications } = useWeb3Context();
  const { dashboard } = strings;

  const scanUrl = useMemo(() => config.blockchains.find((blockchain) => blockchain.name === applications?.blockchain)?.scanUrl, [config, applications])
  const fspAddress = useMemo(() => status?.deploy?.contracts.find((contract) => contract.name === "fspContract")?.address, [status])
  const fundAddress = useMemo(() => status?.deploy?.contracts.find((contract) => contract.name === "fundTokenContract")?.address, [status])
  const fspLink = useMemo(() => scanUrl + fspAddress, [scanUrl, fspAddress])
  const fundLink = useMemo(() => scanUrl + fundAddress, [scanUrl, fundAddress])

  return (
    <div className="preview-container-col">
      <div>
        <div className="title">{dashboard.addresses.fspContract}</div>
        <a href={fspLink} target="_blank"rel="noreferrer">{fspAddress}</a>
      </div>
      <div>
        <div className="title">{dashboard.addresses.fundTokenContract}</div>
        <a href={fundLink} target="_blank" rel="noreferrer">{fundAddress}</a>
      </div>
    </div>
  );
};

export default DashboardLastDeployAddresses;
