import React, { useEffect } from "react";
import "./MobileLeftBar.scss";

import { telegram_logo } from "../../../assets/svg";
import { FiLogOut } from "react-icons/fi";

// Components
import NavbarLinkButton from "../navbarLinkButton/NavbarLinkButton";
import ConnectWalletButton from "../connectWalletButton/ConnectWalletButton";
import { useAppContext } from "../../../context/store";
import { removeApiToken } from "../../../api";
import { resetStorage } from "../../../storage";
import { LOGOUT } from "../../../context/reducer";
import { FaDiscord } from "react-icons/fa";

const MobileLeftBar = ({ open }) => {
  const {
    config,
    strings: { navigation },
    appDispatch,
  } = useAppContext();

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflowY = "initial";
    }
  }, [open]);

  return (
    <div className="mobile-left-bar" style={{ left: open ? 0 : "-110%" }}>
      <div className="mobile-left-bar__links">
        <NavbarLinkButton
          text={navigation.dashboard}
          to={"/"}
          className="font-XL-700"
        />
        <NavbarLinkButton
          text={navigation.faq}
          to={"/faq"}
          className="font-XL-700"
        />
      </div>
      <div className="mobile-left-bar__divider"></div>
      <div className="mobile-left-bar__bottom">
        <ConnectWalletButton isInMobileHeader />
        <NavbarLinkButton
          text={navigation.telegram}
          href={config?.URLs.telegram}
          target="_blank"
          icon={telegram_logo}
          style={{
            borderRadius: 4,
            padding: "4px 12px",
            height: 42,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <NavbarLinkButton
          target="_blank"
          href={config?.URLs.discord}
          style={{
            borderRadius: 4,
            padding: "4px 12px",
            height: 42,
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "var(--dim-12)",
            }}
          >
            <FaDiscord fontSize={32} color="var(--color-primary)" />{" "}
            {navigation.support}
          </span>
        </NavbarLinkButton>
        <button
          className="navbar__logout navbar__logout--mobile"
          onClick={() => {
            window.location = "/";
            removeApiToken();
            resetStorage();
            appDispatch({ type: LOGOUT });
          }}
        >
          Sign out
          <FiLogOut />
        </button>
      </div>
    </div>
  );
};

export default MobileLeftBar;
