import { apiCall } from "../api";

const getMemberTeamsService = (appDispatch) => {
  const config = {
    method: "get",
    url: "/fundraising/teams",
  };

  return apiCall(config, appDispatch);
};

export const deleteMemberTeamService = async (id, appDispatch) => {
  const config = {
    method: "delete",
    url: `/fundraising/teams/${id}`,
  };

  try {
    await apiCall(config, appDispatch);
    const response = await getMemberTeamsService(appDispatch);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editMemberTeamService = async (member, id, appDispatch) => {
  const config = {
    method: id ? "put" : "post",
    url: `/fundraising/teams${id ? `/${id}` : ""}`,
    data: member,
  };

  try {
    await apiCall(config, appDispatch);

    const response = await getMemberTeamsService(appDispatch);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteMemberTeamImageService = async (id, appDispatch) => {
  const config = {
    method: "delete",
    url: `/fundraising/teams/${id}/image`,
  };

  try {
    await apiCall(config, appDispatch);
  } catch (error) {
    throw new Error(error);
  }
};
