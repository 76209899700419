import axios from "axios";
import {
  getAuthTokenStorage,
  getRefreshTokenStorage,
  resetStorage,
  setTokensStorage,
} from "./storage";

import {
  SET_LOADING,
  CANCEL_LOADING,
  LOGOUT,
  SHOW_ALERT,
} from "./context/reducer";
import { toast } from "react-toastify";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.REACT_APP_API_URL + "/";

const localAxiosInstance = axios.create();

// Set token in the headers
const setApiToken = (token) => {
  axios.defaults.headers.common["Authorization"] = token;
};

// Remove token in the headers
export function removeApiToken() {
  axios.defaults.headers.common["Authorization"] = undefined;
}

// Decomment appDispatch function when context will be implemented
export async function apiCall(config, appDispatch, showError = true) {
  //Update token from storage
  setApiToken(getAuthTokenStorage());

  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (config) => {
      appDispatch({ type: SET_LOADING });
      return config;
    },
    (error) => {
      appDispatch({ type: CANCEL_LOADING });
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      appDispatch({ type: CANCEL_LOADING });
      return response;
    },
    async (error) => {
      const originalConfig = error.config;

      if (
        (originalConfig &&
          error?.response &&
          error?.response?.data?.detail === "Invalid token") ||
        error?.response?.data?.detail === "Token expired"
      ) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          // Get current refreshToken
          const refreshToken = getRefreshTokenStorage();

          try {
            const config = {
              url: "/token",
              method: "get",
              headers: { Authorization: refreshToken },
            };

            const {
              data: { token },
            } = await localAxiosInstance(config, appDispatch);

            setTokensStorage(token, refreshToken);

            appDispatch({ type: CANCEL_LOADING });

            return localAxiosInstance({
              ...originalConfig,
              headers: { Authorization: token },
            });
          } catch (_error) {
            resetStorage();
            removeApiToken();
            appDispatch({ type: LOGOUT });
            window.location = "/";
          }
        }
      } else if (showError) {
        let message = error?.response?.data?.detail || error?.response?.data?.message || error?.response?.data?.error || "An unexpected error has occurred, please contact an Administrator."
        if (message === 'Password must be updated') {
          toast.warn(message);
        } else {
          toast.error(message);
        }
      }

      appDispatch({ type: CANCEL_LOADING });

      return Promise.reject(error);
    }
  );
  return axiosInstance.request(config);
}
