import React from "react";
import "./HtmlEditor.scss";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import { useController } from "react-hook-form";

// Icons
import { HiOutlineTrash as TrashButton } from "react-icons/hi";
import { useAppContext } from "../../../context/store";
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";
import ReactTooltip from "react-tooltip";

const SuneditorTemplate = ({
  tooltipText,
  required,
  onDelete,
  label,
  error,
  style,
  placeholder,
  control,
  name,
  defaultValue,
  noUploadImage,
  ...props
}) => {
  const {
    strings: { htmlEditor },
  } = useAppContext();

  const {
    field: { value, ...inputProps },
  } = useController({
    name,
    control,
    rules: {
      validate: (e) => {
        if(required && !e)
          return htmlEditor.emptySection;
        else if(!e)
          return 
        const strippedString = e.replace(/(<([^>]+)>)/gi, "");
        if (required && !strippedString) {
          return htmlEditor.emptySection;
        }
      },
    },
    defaultValue: defaultValue || "",
  });

  const { ref, ...otherInputProps } = inputProps

  const buttonList = [
    "bold",
    "underline",
    "italic",
    "strike",
    "link",
    "list",
    "align",
    "fontSize",
    "formatBlock",
    "table",
    "image",
    "undo",
    "redo",
  ];

  if (noUploadImage) {
    buttonList.splice(buttonList.indexOf("image"), 1);
  }

  return (
    <div className="html-editor-container" style={{ ...style, position: "relative" }}>
      <label
        className={`textarea__label font-M-700 ${error ? "label--error" : ""}`}
      >
        <ReactTooltip
          place="right"
          effect="solid"
          textColor="var(--color-white)"
          backgroundColor="var(--color-primary)"
          id="html-editor-tooltip"
        />
        <span style={{ display: "flex", gap: 4 }}>
          {label && (
            <span style={{ textTransform: "capitalize" }}>{label}</span>
          )}
          {tooltipText && (
            <span
              data-for="html-editor-tooltip"
              data-tip={tooltipText}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InfoIcon />
            </span>
          )}
        </span>
        {onDelete && (
          <span
            className="textarea__label-delete font-S-700"
            onClick={() => {
              onDelete && onDelete();
            }}
          >
            <TrashButton size={18} color="red" /> {htmlEditor.delete}
          </span>
        )}
      </label>
      {error?.message && (
        <span
          style={{
            position: "absolute",
            bottom: "-24px",
            left: 0,
            color: "#ec2121",
          }}
        >
          {error?.message}
        </span>
      )}
      <div className={`${error ? "textarea__error-container" : ""}`}>
        <SunEditor
          {...props}
          {...otherInputProps}
          height="200"
          defaultValue={value}
          placeholder={placeholder}
          setDefaultStyle="font-size: 16px;font-family: Nunito"
          setOptions={{
            buttonList: [buttonList],
          }}
        />
      </div>
    </div>
  );
};
export default SuneditorTemplate;
