import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

// Components
import Outer from "./components/outer/Outer";
import Toast from "./components/toastContainer/ToastContainer";
import "react-toastify/dist/ReactToastify.css";

// Sass
import "./sass/index.scss";

const App = () => {
  return (
    <Router>
      <Toast />
      <Outer />
    </Router>
  );
};

export default App;
