import React, { useEffect, useState } from "react";
import "./Documentation.scss";
import { sha3_256 } from 'js-sha3'

import { toast } from "react-toastify";

import { useAppContext } from "../../../context/store";

// Components

import DropArea from "../../common/dropArea/DropArea";
import Spinner from "../../common/spinner/Spinner";

// Services
import {
  getDocsService,
  deleteDoc,
  postAllDocs,
} from "../../../services/documentationServices";
import { urlToBuffer } from "../../../utils/helperFunctions";
import Button from "../../common/button/Button";
import { RiFileCopyLine as CopyIcon } from "react-icons/ri"

const Documentation = ({ documentationData, onSave }) => {
  const { appDispatch, isLoading, strings, config } = useAppContext();
  const { documentation, dropArea, common } = strings;
  const {
    fileUploadConstants: { PDF },
    constants: { MAX_OPTIONAL_DOCUMENTS, MAX_AGREEMENT_DOCUMENT },
  } = config;

  const [shaInfoAgreeBusiness, setShaInfoAgreeBusiness] = useState(null);
  const [shaInfoAgreeLegal, setShaInfoAgreeLegal] = useState(null);
  const [investorDeck, setInvestorDeck] = useState(documentationData.find((doc) => doc?.type === "investorDeck"));
  const [otherDocsBusiness, setOtherDocsBusiness] = useState(documentationData.filter((doc) => doc?.type === "businessDocumentGeneric"));
  const [informativeAgree, setInformativeAgree] = useState(documentationData.find((doc) => doc?.type === "informativeAgreement"));
  const [otherDocsLegal, setOtherDocsLegal] = useState(documentationData.filter((doc) => doc?.type === "legalDocumentGeneric"));
  const [maxDocNumberBusiness, setMaxDocNumberBusiness] = useState(MAX_OPTIONAL_DOCUMENTS);
  const [maxDocNumberLegal, setMaxDocNumberLegal] = useState(MAX_OPTIONAL_DOCUMENTS);

  const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {

    }
    document.body.removeChild(textArea);
  }

  const copyToCliboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      unsecuredCopyToClipboard(text);
    }
    toast.info(documentation.copiedSha3)
  }

  const handleAddDocument = async (docs, type) => {
    const newDocs = docs.map((doc) => ({
      doc,
      type,
    }));

    try {
      if (!!docs.length && docs?.length > 0) {
        await postAllDocs(newDocs, appDispatch);
        const { data } = await getDocsService(appDispatch);
        onSave(5, data);
        toast.success(common.changedData, { autoClose: 500 });
      }
    } catch (error) {

    }
  };

  const removeFile = async (file) => {
    try {
      await deleteDoc(file?.id, appDispatch);
      const { data } = await getDocsService(appDispatch);
      onSave(5, data);
      toast.success(common.changedData, { autoClose: 100 });
    } catch (error) {

    }
  };
  const handleGetShaDocBusiness = async (doc) => {
    try {
      if (doc) {
        const docObj = await urlToBuffer(doc.link);
        const hashedDoc = sha3_256(docObj);

        setShaInfoAgreeBusiness("0x" + hashedDoc);
      } else {
        setShaInfoAgreeBusiness(null);
      }
    } catch (error) {
      toast.error(common.failedToHash, { autoClose: 500 })
    }
  };

  const handleGetShaDocLegal = async (doc) => {
    try {
      if (doc) {
        const docObj = await urlToBuffer(doc.link);
        const hashedDoc = sha3_256(docObj);

        setShaInfoAgreeLegal("0x" + hashedDoc);
      } else {
        setShaInfoAgreeLegal(null);
      }
    } catch (error) {
      toast.error(common.failedToHash, { autoClose: 500 })
    }
  };

  useEffect(() => {
    setInvestorDeck(documentationData.find((doc) => doc?.type === "investorDeck"));
    setOtherDocsBusiness(documentationData.filter((doc) => doc?.type === "businessDocumentGeneric"));
    setInformativeAgree(documentationData.find((doc) => doc?.type === "informativeAgreement"));
    setOtherDocsLegal(documentationData.filter((doc) => doc?.type === "legalDocumentGeneric"));
  }, [documentationData]);

  useEffect(() => {
    handleGetShaDocBusiness(investorDeck || null);
  }, [investorDeck]);

  useEffect(() => {
    handleGetShaDocLegal(informativeAgree || null);
  }, [informativeAgree]);

  useEffect(() => {
    if (MAX_OPTIONAL_DOCUMENTS && otherDocsBusiness) {
      setMaxDocNumberBusiness(MAX_OPTIONAL_DOCUMENTS - otherDocsBusiness.length);
    }
  }, [MAX_OPTIONAL_DOCUMENTS, otherDocsBusiness]);

  useEffect(() => {
    if (MAX_OPTIONAL_DOCUMENTS && otherDocsLegal) {
      setMaxDocNumberLegal(MAX_OPTIONAL_DOCUMENTS - otherDocsLegal.length);
    }
  }, [MAX_OPTIONAL_DOCUMENTS, otherDocsLegal]);

  if (isLoading) {
    return (
      <Spinner
        wrapperStyle={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="documentation">
      <div className="documentation__column">
        <h2 className="documentation__title">{documentation.businessTitle}</h2>
        <div
          className="documentation__drop-area-container"
          style={{ paddingTop: "var(--dim-48)" }}
        >
          <span
            className="documentation__drop-area-component"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--dim-24)",
            }}
          >
            <DropArea
              maxSize={PDF?.sizeInByte}
              accept={PDF?.allowedExtensions}
              maxFiles={MAX_AGREEMENT_DOCUMENT}
              label={documentation.agreeLabelBusiness}
              areaMessage={documentation.agreeSizeAndFormat}
              fileName="Investor-Deck.pdf"
              lastFile={investorDeck}
              fileSetter={(file) => {
                handleAddDocument([file], "investorDeck");
              }}
              deleteFileFunction={(e) => {
                removeFile(e);
              }}
              withRecycleBinView
              onError={(errors) => {
                errors.forEach((error) => {
                  if (error === "too-many-files") {
                    toast.error(dropArea.errors.onlyOne);
                  } else if (error === "file-too-large") {
                    toast.error(dropArea.errors.tooBig);
                  }
                });
              }}
              hasGap={true}
              tooltipText={documentation.agreeTooltip}
            />
            {investorDeck && (
              <span
                style={{
                  fontSize: 12,
                  textAlign: "center"
                }}
              >
                {strings.formatString(documentation.sha3, {
                  hash: (
                    <div className="shaInfo-container">
                      <div className="shaInfo ellipsis" style={{ color: "var(--color-primary)", width: "100%" }}>
                        {shaInfoAgreeBusiness}
                      </div>

                      <Button
                        style={{ width: "36px", height: "32px" }}
                        onClick={() => copyToCliboard(shaInfoAgreeBusiness)}
                      >
                        <CopyIcon className="copy-icon" />
                      </Button>
                    </div>
                  ),
                })}
              </span>
            )}
          </span>
          {maxDocNumberBusiness !== 0 && (
            <div className="documentation__drop-area-component">
              <DropArea
                maxSize={PDF?.sizeInByte}
                accept={PDF?.allowedExtensions}
                maxFiles={maxDocNumberBusiness}
                label={documentation.otherDocsLabelBusiness}
                areaMessage={documentation.otherDocsSizeAndFormat}
                onError={(errors) => {
                  errors.forEach((error) => {
                    if (error === "too-many-files") {
                      toast.error(dropArea.errors.onlyOne);
                    } else if (error === "file-too-large") {
                      toast.error(dropArea.errors.tooBig);
                    }
                  });
                }}
                returnMultiple
                fileSetter={(file) => handleAddDocument(file, "businessDocumentGeneric")}
                tooltipText={documentation.otherDocsTooltip}
                hasGap={true}
                // isRenamingSetter
              />
            </div>
          )}
        </div>
        {!!otherDocsBusiness.length && otherDocsBusiness?.length > 0 && (
          <p style={{ textAlign: "center" }}>{documentation.alreadyUploadedBusiness}</p>
        )}
        <div className="documentation__uploaded-container">
          {otherDocsBusiness.map((doc) => (
            <DropArea
              key={doc?.id}
              accept={PDF?.allowedExtensions}
              lastFile={doc}
              withRecycleBinView
              deleteFileFunction={(e) => {
                removeFile(e);
              }}
              viewOnly
            />
          ))}
        </div>
      </div>
      <div className="documentation__column">
        <h2 className="documentation__title">{documentation.legalTitle}</h2>
        <div
          className="documentation__drop-area-container"
          style={{ paddingTop: "var(--dim-48)" }}
        >
          <span
            className="documentation__drop-area-component"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--dim-24)",
            }}
          >
            <DropArea
              maxSize={PDF?.sizeInByte}
              accept={PDF?.allowedExtensions}
              maxFiles={MAX_AGREEMENT_DOCUMENT}
              label={documentation.agreeLabelLegal}
              areaMessage={documentation.agreeSizeAndFormat}
              fileName="Investment-Agreement.pdf"
              lastFile={informativeAgree}
              fileSetter={(file) => {
                handleAddDocument([file], "informativeAgreement");
              }}
              deleteFileFunction={(e) => {
                removeFile(e);
              }}
              withRecycleBinView
              onError={(errors) => {
                errors.forEach((error) => {
                  if (error === "too-many-files") {
                    toast.error(dropArea.errors.onlyOne);
                  } else if (error === "file-too-large") {
                    toast.error(dropArea.errors.tooBig);
                  }
                });
              }}
              hasGap={true}
              tooltipText={documentation.agreeTooltip}
            />
            {informativeAgree && (
              <span
                style={{
                  fontSize: 12,
                  textAlign: "center"
                }}
              >
                {strings.formatString(documentation.sha3, {
                  hash: (
                    <div className="shaInfo-container">
                      <div className="shaInfo ellipsis" style={{ color: "var(--color-primary)", width: "100%" }}>
                        {shaInfoAgreeLegal}
                      </div>

                      <Button
                        className=""
                        style={{ width: "36px", height: "32px" }}
                        onClick={() => copyToCliboard(shaInfoAgreeLegal)}
                      >
                        <CopyIcon className="copy-icon" />
                      </Button>
                    </div>
                  ),
                })}
              </span>
            )}
          </span>
          {maxDocNumberLegal !== 0 && (
            <div className="documentation__drop-area-component">
              <DropArea
                maxSize={PDF?.sizeInByte}
                accept={PDF?.allowedExtensions}
                maxFiles={maxDocNumberLegal}
                label={documentation.otherDocsLabelLegal}
                areaMessage={documentation.otherDocsSizeAndFormat}
                onError={(errors) => {
                  errors.forEach((error) => {
                    if (error === "too-many-files") {
                      toast.error(dropArea.errors.onlyOne);
                    } else if (error === "file-too-large") {
                      toast.error(dropArea.errors.tooBig);
                    }
                  });
                }}
                returnMultiple
                fileSetter={(file) => handleAddDocument(file, "legalDocumentGeneric")}
                tooltipText={documentation.otherDocsTooltip}
                hasGap={true}
                // isRenamingSetter
              />
            </div>
          )}
        </div>
        {!!otherDocsLegal.length && otherDocsLegal?.length > 0 && (
          <p style={{ textAlign: "center" }}>{documentation.alreadyUploadedLegal}</p>
        )}
        <div className="documentation__uploaded-container">
          {otherDocsLegal.map((doc) => (
            <DropArea
              key={doc?.id}
              accept={PDF?.allowedExtensions}
              lastFile={doc}
              withRecycleBinView
              deleteFileFunction={(e) => {
                removeFile(e);
              }}
              viewOnly
            />
          ))}
        </div>
      </div>
      {/* <div
        className="documentation__drop-area-container"
        style={{ paddingTop: shaInfoAgree && "var(--dim-48)" }}
      >
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "var(--dim-24)",
          }}
        >
          <DropArea
            maxSize={PDF?.sizeInByte}
            accept={PDF?.allowedExtensions}
            maxFiles={MAX_AGREEMENT_DOCUMENT}
            label={documentation.agreeLabel}
            areaMessage={documentation.agreeSizeAndFormat}
            fileName="InvestmentAgreement.pdf"
            lastFile={informativeAgree}
            fileSetter={(file) => {
              handleAddDocument([file], "informativeAgreement");
            }}
            deleteFileFunction={(e) => {
              removeFile(e);
            }}
            withRecycleBinView
            onError={(errors) => {
              errors.forEach((error) => {
                if (error === "too-many-files") {
                  toast.error(dropArea.errors.onlyOne);
                } else if (error === "file-too-large") {
                  toast.error(dropArea.errors.tooBig);
                }
              });
            }}
            tooltipText={documentation.agreeTooltip}
          />
          {informativeAgree && (
            <span
              style={{
                fontSize: 12,
              }}
            >
              {strings.formatString(documentation.sha3, {
                hash: (
                  <span style={{ color: "var(--color-primary)" }}>
                    {shaInfoAgree}
                  </span>
                ),
              })}
            </span>
          )}
        </span>
        {maxDocNumber !== 0 && (
          <DropArea
            maxSize={PDF?.sizeInByte}
            accept={PDF?.allowedExtensions}
            maxFiles={maxDocNumber}
            label={documentation.otherDocsLabel}
            areaMessage={documentation.otherDocsSizeAndFormat}
            onError={(errors) => {
              errors.forEach((error) => {
                if (error === "too-many-files") {
                  toast.error(dropArea.errors.onlyOne);
                } else if (error === "file-too-large") {
                  toast.error(dropArea.errors.tooBig);
                }
              });
            }}
            returnMultiple
            fileSetter={handleAddDocument}
            tooltipText={documentation.otherDocsTooltip}
            // isRenamingSetter
          />
        )}
      </div>
      {!!otherDocs.length && otherDocs?.length > 0 && (
        <p>{documentation.alreadyUploaded}</p>
      )}
      <div className="documentation__uploaded-container">
        {otherDocs.map((doc) => (
          <DropArea
            key={doc?.id}
            accept={PDF?.allowedExtensions}
            lastFile={doc}
            withRecycleBinView
            deleteFileFunction={(e) => {
              removeFile(e);
            }}
            deletable={true}
            viewOnly
          />
        ))}
      </div> */}
    </div>
  );
};

export default Documentation;
